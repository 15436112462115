import { useFlag } from '@unleash/proxy-client-react';
import { useEffect } from 'react';
import { useAppDispatch } from '../../app/hooks';
import { update } from '../../redux/features/config/config.slice';

const useUnleashHooks = () => {
  const audioBackend = useFlag('audio_backend');
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(update({ FEATURE_FLAG_AUDIO_BACKEND: audioBackend }));
  }, [audioBackend]);
};

export default useUnleashHooks;
