import { useSelector } from 'react-redux';
import { useAppDispatch } from '../app/hooks';
import { selectOrderMessageData } from '../redux/features/sessionBoundary/sessionBoundary.selectors';
import { selectValidCartItems } from '../selectors/cart';
import { startSendOrder, populateOrderRequests } from '../reducers/orderSlice';
import { ISendOrderData } from '../reducers/orderSlice.props';
import { selectIsAIAutoMode } from '../redux/features/ai/ai.selector';
import { useEffect } from 'react';

const useExternalOrderHandler = () => {
  const dispatch = useAppDispatch();
  const orderMessageData = useSelector(selectOrderMessageData);
  const validCartItems = useSelector(selectValidCartItems);
  const isAIAutoMode = useSelector(selectIsAIAutoMode);
  const isOrderFromExternal = isAIAutoMode;

  useEffect(() => {
    if (
      isOrderFromExternal &&
      Object.values(orderMessageData).length &&
      Object.values(validCartItems).length
    ) {
      const validCartItemIds = Object.values(validCartItems).reduce(
        (acc: number[], { cartItemId }) => {
          if (cartItemId) acc.push(cartItemId);
          return acc;
        },
        []
      );

      dispatch(startSendOrder(validCartItemIds));

      const {
        request_id = '',
        final = false,
        coupons = [],
      } = orderMessageData as ISendOrderData;
      dispatch(
        populateOrderRequests({
          requestId: request_id,
          cartItemIds: validCartItemIds,
          isFinal: final,
          couponIds: Object.keys(coupons),
        })
      );
    }
  }, [orderMessageData, validCartItems]);
};

export default useExternalOrderHandler;
