import { PayloadAction, createSlice, isAnyOf } from '@reduxjs/toolkit';
import { fetchMenu } from '../../../reducers/menuSlice';

const initialState = {
  isLoading: false,
  isFetchingTaskStatus: false,
};

const loadingSlice = createSlice({
  name: 'loading',
  initialState,
  reducers: {
    startLoading: (
      state,
      { payload }: PayloadAction<{ isFetchingTaskStatus?: boolean }>
    ) => {
      state.isLoading = true;
      state.isFetchingTaskStatus = !!payload.isFetchingTaskStatus;
    },
    stopLoading: (state) => {
      state.isLoading = false;
      state.isFetchingTaskStatus = false;
    },
    updateFetchingTaskStatus: (state, { payload }) => {
      state.isFetchingTaskStatus = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      isAnyOf(fetchMenu.fulfilled, fetchMenu.rejected),
      (state) => {
        state.isLoading = false;
      }
    );
  },
});

export const { startLoading, stopLoading, updateFetchingTaskStatus } =
  loadingSlice.actions;
export default loadingSlice.reducer;
