export class AppContainer {
  #data: Record<string, any> = {};

  set<T>(key: string, value: T) {
    this.#data[key] = value;
  }

  get<T>(key: string): T | undefined {
    return this.#data[key];
  }
}

/** App container, can be used like a global scope */
const appContainer = new AppContainer();

export default appContainer;
