import { PayloadAction, createSlice, isAnyOf } from '@reduxjs/toolkit';
import { OUT_OF_SESSION } from '../../../constants';
import { EventTypes } from '../../../constants/event';
import { selectMenuVersion } from '../../../reducers/menuSlice';
import {
  CheckTransmissionMessage,
  IMessageReceived,
  messagingActions,
} from '../../../reducers/messagingSlice';
import { IOrderTransmissionMessage } from '../../../reducers/orderSlice.props';
import {
  selectRestaurant,
  selectStage,
} from '../../../reducers/restaurantSlice';
import {
  SessionEndReasons,
  SessionStartReasons,
  SessionStatus,
} from './sessionBoundary.constants';
import {
  checkSessionEnd,
  fillEndSessionValues,
  initialState,
} from './sessionBoundary.utils';

/* HITL session ends when:
1. Session logout button is clicked 
Or
2. Three conditions are satisfied: car_exit event detected, empty cart and no pending final order. The sequence of satisfying these conditions can be different.
    No pending final order means: 
    1) no check is ever created on the POS within the session 
    2) the check is created but the final order is submitted by clicking Finish Order button 
    3) the check is created but canceled by clicking Cancel Order button
    4) the check is created but canceled by asking for restaurant staff intervention or receiving restaurant staff intervention
*/

const sessionBoundarySlice = createSlice({
  name: 'sessionBoundary',
  initialState,
  reducers: {
    setFinalOrderSubmitted: (state, action: PayloadAction<boolean>) => {
      state.finalOrderSubmitted = action.payload;
      if (checkSessionEnd(state))
        fillEndSessionValues(state, SessionEndReasons.finalOrderSubmitted);
    },
    resetSessionBoundaryData: () => {
      return { ...initialState };
    },
    resetOrderMessageOrderId: (state) => {
      state.orderMessageOrderId = '';
    },
    setSessionStart: (
      state,
      action: PayloadAction<{
        sessionId: string;
        sessionStartReason: SessionStartReasons;
      }>
    ) => {
      // Session starts
      const { sessionId, sessionStartReason } = action.payload;
      state.sessionId = sessionId;
      state.sessionStatus = SessionStatus.start;
      state.sessionStartReason = sessionStartReason;
      state.sessionEndReason = '';
    },
    setCartEmpty: (state, action: PayloadAction<boolean>) => {
      state.cartEmpty = action.payload;
      if (checkSessionEnd(state))
        fillEndSessionValues(state, SessionEndReasons.cartEmpty); // Session ends: the final order is submitted/canceled after a car exits
    },
    endSession: (state, action: PayloadAction<SessionEndReasons>) => {
      fillEndSessionValues(state, action.payload);
    },
    updateBufferedSessionIds: (state, action: PayloadAction<string[]>) => {
      state.bufferedSessionIds = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      messagingActions.messageReceived,
      (state, action: PayloadAction<IMessageReceived>) => {
        const { messages = [] } = action.payload;
        messages.forEach((message) => {
          const { session_id: messageSessionId, event } = message;
          switch (event) {
            case EventTypes.carEnter:
              if (state.sessionId && state.sessionId !== messageSessionId) {
                state.bufferedSessionIds.push(messageSessionId);
              } else {
                // Session starts
                state.sessionId = messageSessionId;
                state.sessionStatus = SessionStatus.start;
                state.sessionStartReason = SessionStartReasons.carEnter;
                state.sessionEndReason = '';
              }
              break;
            case EventTypes.carExit:
              if (
                [state.sessionId, OUT_OF_SESSION].includes(messageSessionId)
              ) {
                state.carExit = true;
                if (checkSessionEnd(state)) {
                  // Session ends: a car exits with no pending submitted order
                  fillEndSessionValues(state, SessionEndReasons.carExit);
                }
              }
              break;
            case EventTypes.check:
              // TODO: need to add messageSessionId === state.sessionId after POSLink stops using the orderId from order event as the sessionId of check event
              const { check, final } = (message as CheckTransmissionMessage)
                .data;
              if (check && !state.checkCreated) state.checkCreated = true;

              if (state.checkCreated) state.finalOrderSubmitted = final;

              const noPendingFinalOrder =
                !state.checkCreated ||
                (state.checkCreated && state.finalOrderSubmitted);
              if (checkSessionEnd(state)) {
                // Session ends: the final order is submitted after a car exits
                fillEndSessionValues(
                  state,
                  SessionEndReasons.finalOrderSubmitted
                );
              } else if (noPendingFinalOrder) {
                state.checkCreated = false;
                state.finalOrderSubmitted = false;
                if (messageSessionId === state.orderMessageOrderId)
                  state.orderMessageOrderId = ''; // Order from other components is submitted
              }
              break;
            case EventTypes.order:
              if (messageSessionId === state.sessionId) {
                const orderMessageData = (message as IOrderTransmissionMessage)
                  .data || { session_id: '', final: false };
                state.orderMessageData = orderMessageData;
                const { session_id, final } = orderMessageData;
                if (final) {
                  state.finalOrderSubmitted = final; // Order is submitted by AI.
                } else {
                  state.orderMessageOrderId = session_id; // Order from other components. Only support single instance for now as AI does not support multiple orders per session.
                }
              }
              break;
            default:
              break;
          }
        });
      }
    );
    builder.addMatcher(
      isAnyOf(
        selectRestaurant.fulfilled,
        selectStage.fulfilled,
        selectMenuVersion.fulfilled
      ),
      () => initialState
    );
  },
});

const {
  setFinalOrderSubmitted,
  resetSessionBoundaryData,
  resetOrderMessageOrderId,
  setSessionStart,
  setCartEmpty,
  endSession,
  updateBufferedSessionIds,
} = sessionBoundarySlice.actions;
export {
  endSession,
  resetOrderMessageOrderId,
  resetSessionBoundaryData,
  setCartEmpty,
  setFinalOrderSubmitted,
  setSessionStart,
  updateBufferedSessionIds,
};
export default sessionBoundarySlice.reducer;
