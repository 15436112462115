import React from 'react';
import { Chip, Typography, AutocompleteRenderOptionState } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import {
  ParsedMenuItem,
  TopLevelMenuItem,
  isItem86edToday,
} from '../../utils/menu';
import { useAppSelector, useShallowSelector } from '../../app/hooks';
import { RootState } from '../../app/store';
import { getHightlight, getQuantityFromInput } from '../../utils/autocomplete';
import { getModSymbolMapping } from '../../selectors/menu';
import Colors from '../../utils/color';

const useStyles = makeStyles(() => ({
  modifier: {
    backgroundColor: '#ab51ff',
    color: 'white',
    borderRadius: '10px',
    fontSize: '.75rem',
    padding: '0 7px',
  },
  highlightText: {
    marginRight: '15px',
    color: (props: any) =>
      props.is86edToday ? Colors.red : props.isModifier && '#ab51ff',
    textDecoration: (props: any) => props.is86edToday && 'line-through',
  },
  eightySixed: {
    backgroundColor: `${Colors.red} !important`,
    height: '18px !important',
    marginLeft: '10px',
    '& .MuiChip-label': {
      padding: '0 7px',
      fontSize: '.75rem',
      color: 'white',
    },
  },
}));

interface IAutocompleteOption {
  htmlElemProps: React.HTMLAttributes<HTMLLIElement>;
  menuItem: ParsedMenuItem | TopLevelMenuItem;
  state: AutocompleteRenderOptionState;
  handleOutOfOrderSearch: boolean;
}
export const AutocompleteOption = ({
  htmlElemProps,
  menuItem,
  state,
  handleOutOfOrderSearch,
}: IAutocompleteOption) => {
  const isModifier = menuItem.category === 'modifier';
  const is86edToday = isItem86edToday(menuItem);
  const styleProps = { isModifier, is86edToday };
  const classes = useStyles(styleProps);
  const cart = useShallowSelector((state: RootState) => state.cart);
  const selectedItem =
    useAppSelector((state) => state.dialog.selectedItem) ||
    Object.values(cart.cartItems)[Object.values(cart.cartItems).length - 1];
  const modSymbolMapping = useShallowSelector(getModSymbolMapping);

  const { quantity, inputValue } = getQuantityFromInput(
    state.inputValue,
    modSymbolMapping
  );
  const highlight = getHightlight(
    handleOutOfOrderSearch && inputValue.split(' ').length > 1
      ? inputValue.split(' ')[1]
      : inputValue,
    menuItem,
    quantity
  );

  let modifierTag = '';
  if (isModifier && selectedItem) {
    const modifier = menuItem as ParsedMenuItem;
    modifierTag =
      modifier.parentModifierGroupDisplayName ||
      modifier.parentModifierGroupName ||
      selectedItem.name;
  }

  return (
    <li
      {...htmlElemProps}
      key={menuItem.id}
      style={{
        pointerEvents: 'none',
      }}
    >
      <div>
        <span
          dangerouslySetInnerHTML={{ __html: highlight }}
          className={classes.highlightText}
        />
        {isModifier ? (
          <span className={classes.modifier}>&gt; {modifierTag}</span>
        ) : (
          <Typography
            color={is86edToday ? Colors.red : undefined}
            fontSize=".75rem"
            component="span"
          >
            {menuItem.category}
          </Typography>
        )}
        {is86edToday && (
          <Chip label="86'd" size="small" className={classes.eightySixed} />
        )}
      </div>
    </li>
  );
};
