import { Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { FC, default as React, useCallback, useMemo } from 'react';
import useSound from 'use-sound';
import { useAppSelector } from '../../app/hooks';
import {
  selectTaskRouterNotificationSound,
  selectTaskRouterWaitingTime,
} from '../../redux/features/config/config.selector';
import {
  selectActiveTaskOfferedId,
  selectTasksById,
} from '../../redux/features/taskRouter/taskRouter.selector';
import notificationSound from '../../sounds/task_notify.mp3';
import logger from '../../utils/logger';
import TaskRouterAcceptance from '../TaskRouterAcceptance';

type TaskRouterAgentAvailableProps = {
  sendTaskMessage?: Function;
};

const useStyles = makeStyles(() => ({
  taskRouterAcceptanceWrapperClass: {
    height: '100%',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));

const TaskRouterAgentAvailable: FC<TaskRouterAgentAvailableProps> = ({
  sendTaskMessage,
}) => {
  const { taskRouterAcceptanceWrapperClass } = useStyles();
  const waitingTime = useAppSelector(selectTaskRouterWaitingTime);
  const [play, { stop }] = useSound(notificationSound, { interrupt: true });
  const canPlayNotificationSound = useAppSelector(
    selectTaskRouterNotificationSound
  );
  const tasksById = useAppSelector(selectTasksById);
  const taskOfferedId = useAppSelector(selectActiveTaskOfferedId);

  const stopNotificationSound = useCallback(() => {
    if (canPlayNotificationSound) stop();
  }, [canPlayNotificationSound, stop]);

  //timeLeft is a prop sent to TaskRouterAcceptance component and to be calculated only once when the taskOfferedId changes
  const timeLeft = useMemo(() => {
    logger.debug({
      message: `In TaskRouterAgentAvailable: new taskOfferedId is ${taskOfferedId}`,
      isTR: true,
    });
    if (taskOfferedId) {
      const elapsedTime =
        new Date().getTime() - tasksById[taskOfferedId].taskArrivedTime;
      logger.debug({
        message: `In TaskRouterAgentAvailable: timeLeft is ${
          waitingTime - elapsedTime
        }`,
        isTR: true,
      });
      return waitingTime - elapsedTime;
    }
    return 0;
  }, [taskOfferedId]);

  if (canPlayNotificationSound) {
    if (timeLeft > 0) {
      play();
    } else stop();
  }

  return (
    <div id="tr-agent-available">
      {taskOfferedId && timeLeft >= 0 ? (
        <div key={taskOfferedId} className={taskRouterAcceptanceWrapperClass}>
          <TaskRouterAcceptance
            sendTaskMessage={sendTaskMessage}
            stopNotificationSound={stopNotificationSound}
            taskId={taskOfferedId}
            duration={Math.ceil(timeLeft / 1000)}
          />
        </div>
      ) : (
        <>
          <Typography variant="h4" gutterBottom>
            Get ready
          </Typography>
          <Typography>New tasks will be offered to you shortly</Typography>
        </>
      )}
    </div>
  );
};

export default TaskRouterAgentAvailable;
