import { Typography } from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';
import { useSelector } from 'react-redux';
import { selectAvailableCategoryWithTimePeriod } from '../../selectors/menu';
import { ParsedCategory } from '../../utils/menu';
import theme from '../../utils/theme';

const TimeBasedMenuNotification = () => {
  const availableCategoryWithTimePeriod = useSelector(
    selectAvailableCategoryWithTimePeriod
  );

  if (!availableCategoryWithTimePeriod.length) return null;

  const getNotification = (category: ParsedCategory) => {
    const activeTimePeriod =
      category.activeTimePeriod && !category.activeTimePeriod.alwaysEnabled
        ? ` (${category.activeTimePeriod.start}-${category.activeTimePeriod.end} ${category.activeTimePeriod.day})`
        : '';
    return (
      <Typography
        key={category.id}
        variant="h6"
        sx={{ lineHeight: 1.2, fontWeight: 'normal' }}
      >
        {category.name + activeTimePeriod}
      </Typography>
    );
  };

  return (
    <Box
      sx={{
        backgroundColor: theme.palette.secondary.main,
        padding: `${theme.spacing(1)}`,
        maxHeight: `${theme.spacing(8)}`,
        overflowY: 'auto',
      }}
    >
      {availableCategoryWithTimePeriod.length &&
        availableCategoryWithTimePeriod.map((category) =>
          getNotification(category)
        )}
    </Box>
  );
};

export default TimeBasedMenuNotification;
