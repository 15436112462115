import { FormControl, FormLabel, RadioGroup, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';
import { CartModifierGroup } from '../../../utils/cart';
import { checkItemInTree, isItem86edToday } from '../../../utils/menu';
import { ModGroupLabel } from '../components/ModGroupLabel';
import RecursiveModGroup from '../components/RecursiveModGroup';
import SingularModChoice from '../components/SingularModChoice';
import { sortAndFilterMenuItems } from '../util/helper';
import { IModGroup } from './modGroup.props';
import { hasModGroupChildren } from './modGroup.utils';

const useStyles = makeStyles(({ spacing }: Theme) => ({
  modGroup: {
    margin: `0 !important`,
    '& legend': {
      width: '100%',
    },
    width: '100%',
  },
  singularSelectionModGroupClass: {
    marginBottom: (hasChildren) => (hasChildren ? spacing(1.25) : 0),
  },
}));

export default function SingularSelectionModGroup(props: IModGroup) {
  const { modGroup, onChange, cartItem, pathToItem } = props;
  let currentValue: string | null = null;
  let selectedModNumber = 0;
  const currentItem = checkItemInTree({
    cartItem,
    pathToItem,
  }) as CartModifierGroup;
  if (currentItem) {
    const selectedMod = currentItem?.selectedItems || [];
    currentValue = Object.values(selectedMod)[0]?.itemId || null;
    selectedModNumber = Object.values(selectedMod).length;
  }

  // sort and filter the modifiers before rendering
  const menuItems = sortAndFilterMenuItems(modGroup.menuItems);
  const key = [cartItem.id, modGroup.id].join('_');
  const classes = useStyles(hasModGroupChildren(props));

  return (
    <div key={key} className={classes.singularSelectionModGroupClass}>
      <FormControl component="fieldset" className={classes.modGroup}>
        <FormLabel component="legend">
          <ModGroupLabel
            modGroup={modGroup}
            selectedModNumber={selectedModNumber}
          />
        </FormLabel>
        <RadioGroup
          row
          aria-label={modGroup.name}
          name={modGroup.name}
          value={currentValue}
          onChange={onChange(modGroup)}
        >
          {menuItems.map((child) => {
            if (child.available || isItem86edToday(child)) {
              return (
                <SingularModChoice
                  key={key + child.id}
                  item={child}
                  cartItem={cartItem}
                  pathToItem={[pathToItem, child.id].join('__')}
                />
              );
            }
            return null;
          })}
        </RadioGroup>
      </FormControl>
      <RecursiveModGroup {...props} />
    </div>
  );
}
