export class Counter {
  #value = 0;

  constructor(initialValue = 0) {
    this.#value = initialValue;
  }

  /** current value of counter */
  get value() {
    return this.#value;
  }

  /** returns current value of counter then increments it */
  increment() {
    return this.#value++;
  }
}
