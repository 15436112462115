import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../../../app/store';
import { AIStatusColors } from './ai.constants';

const selectAI = (state: RootState) => state.ai;
const selectIsAIAutoMode = createSelector(selectAI, (ai) => ai.isAIAutoMode);
const selectIsAIEscalation = createSelector(
  selectAI,
  (ai) => ai.isAIEscalation
);
const selectAIStatus = createSelector(selectAI, (ai) => ai.aiStatus);

export { selectIsAIAutoMode, selectIsAIEscalation, selectAIStatus };
