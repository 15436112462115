import { Typography } from '@mui/material';
import React from 'react';

interface ErrorMessageProps {
  message: string | string[];
}

const messageTypography = (message: string) => {
  return <Typography color="error" variant="body1" children={message} />;
};

export function ErrorMessage({ message }: ErrorMessageProps) {
  if (Array.isArray(message) && message.length > 0) {
    return <React.Fragment>{message.map((msg) => messageTypography(msg))}</React.Fragment>;
  }
  if (typeof message === 'string' && message !== '') {
    return messageTypography(message);
  }
  return null;
}
