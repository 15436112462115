const FORCE_RELOAD_CHECK_TIME = 60_000;
const IDLE_TIME = 1000 * 60 * 60; // 60 MIN  IDEAL TIME OUT
const KEY = 'key';
const POS_ID = 'pos_id';
const POS_NAME = 'pos_name';
const DUMMY_ITEM = 'dummy_item';
const ID = 'id';
const MAX_CART_ITEM_QUANTITY = 19;
const RESET_HEALTH_STATUS_ONCLOSE_DELAY = 1000;
const TASKROUTER_WSLINK = 'TASK_CHANNEL';
const TASKROUTER_RECONNECT_ATTEMPTS = 5;
const TASKROUTER_RECONNECT_INTERVAL = 5000;
const TIMEOUT = 5000;
const ORDER_STATUS_ICON_RESET_INTERVAL = 3000;
const SHOW_AI_ESCALATION_INTERVAL = 5000;
const OUT_OF_SESSION = 'out of session';
const AUTH_TOKEN_RESPONSE_HEADER = 'x-presto-auth-token';
const AGENT_INTERCEPTION_CONFIRM_CONTENT =
  'AI is ON: Would you like to intercept/take over this order?';
const AUDIO_WS_PATH = '/audio';
const UNLEASH_POLL_INTERVAL = 15; // in seconds
const UNLEASH_APP_NAME = 'HITL';

export {
  AUTH_TOKEN_RESPONSE_HEADER,
  DUMMY_ITEM,
  FORCE_RELOAD_CHECK_TIME,
  ID,
  IDLE_TIME,
  KEY,
  MAX_CART_ITEM_QUANTITY,
  ORDER_STATUS_ICON_RESET_INTERVAL,
  OUT_OF_SESSION,
  POS_ID,
  POS_NAME,
  RESET_HEALTH_STATUS_ONCLOSE_DELAY,
  SHOW_AI_ESCALATION_INTERVAL,
  TASKROUTER_RECONNECT_ATTEMPTS,
  TASKROUTER_RECONNECT_INTERVAL,
  TASKROUTER_WSLINK,
  TIMEOUT,
  AGENT_INTERCEPTION_CONFIRM_CONTENT,
  AUDIO_WS_PATH,
  UNLEASH_POLL_INTERVAL,
  UNLEASH_APP_NAME,
};
