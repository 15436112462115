export enum OrderStatus {
  noOrder = 'NO_ORDER', // used by OrderStatusIcon
  completed = 'COMPLETED',
  pending = 'PENDING',
  sending = 'SENDING', // used by OrderProgressBar
  failed = 'FAILED',
} // combination of TransactionStatus and UI

export enum TransactionStatus {
  completed = 'COMPLETED',
  pending = 'PENDING',
  error = 'ERROR', // check event could return error as the status
}
