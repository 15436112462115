import { SessionEndReasons, SessionStatus } from './sessionBoundary.constants';
import { ISessionBoundaryState } from './sessionBoundary.props';

const initialState: ISessionBoundaryState = {
  checkCreated: false, // Indicate whether a check created on POS or not by checking the check event
  carExit: false, // Indicate whether the car exits the drive-through or not by detecting a car_exit event
  cartEmpty: true,
  bufferedSessionIds: [], // Session ids received while the current session in session boundary is not ended
  finalOrderSubmitted: false, // Only when checkCreated is true, this state will be used to check whether the check is finalized or not. The check can be seen as finalized by 1. clicking Cancel Order 2. clicking Finish Order 3. clicking Restaurant staff 4. receiving restaurant staff intervention event
  sessionId: '', // Current session id in session boundary
  sessionStatus: '', // Current session status
  sessionStartReason: '', // Trigger of starting a session
  sessionEndReason: '', // Trigger of ending a session
  orderMessageOrderId: '', // The inner session id of order event received from the event backend in the current session which comes from the AI.
  orderMessageData: {}, // The date field of order event received from the event backend in the current session which comes from the AI
};

const checkSessionEnd = (sessionBoundaryState: ISessionBoundaryState) => {
  const { checkCreated, finalOrderSubmitted, carExit, cartEmpty } =
    sessionBoundaryState;
  const noPendingFinalOrder =
    !checkCreated || (checkCreated && finalOrderSubmitted);
  return noPendingFinalOrder && carExit && cartEmpty;
};

const fillEndSessionValues = (
  state: ISessionBoundaryState,
  reason: SessionEndReasons
) => {
  state.sessionStatus = SessionStatus.end;
  state.sessionEndReason = reason;
  state.carExit = false;
  state.sessionId = '';
  state.finalOrderSubmitted = false;
  state.orderMessageOrderId = '';
  state.checkCreated = false;
  state.sessionStartReason = '';
  state.cartEmpty = true;
  state.orderMessageData = {};
};

export { initialState, checkSessionEnd, fillEndSessionValues };
