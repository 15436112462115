import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import HeadsetMicIcon from '@mui/icons-material/HeadsetMic';
import LogoutIcon from '@mui/icons-material/Logout';
import MicIcon from '@mui/icons-material/Mic';
import NetworkCheckIcon from '@mui/icons-material/NetworkCheck';
import { green, grey, red, yellow } from '@mui/material/colors';
import React, { FC, useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { ORDER_STATUS_ICON_RESET_INTERVAL } from '../../constants';
import { messagingActions } from '../../reducers/messagingSlice';
import { resetCurrentTransactionId } from '../../reducers/orderSlice';
import {
  OrderStatus,
  TransactionStatus,
} from '../../reducers/orderSlice.constants';
import { selectFeatureFlagTaskRouter } from '../../redux/features/config/config.selector';
import {
  SessionEndReasons,
  SessionStatus,
} from '../../redux/features/sessionBoundary/sessionBoundary.constants';
import { selectSessionStatus } from '../../redux/features/sessionBoundary/sessionBoundary.selectors';
import { endSession } from '../../redux/features/sessionBoundary/sessionBoundary.slice';
import { selectCurrentTask } from '../../redux/features/taskRouter/taskRouter.selector';
import { selectIsWebSocketConnected } from '../../selectors/message';
import {
  selectCurrentTransactionId,
  selectOrderError,
  selectTransactions,
} from '../../selectors/order';
import { Player } from '../Audio/Player';
import { VisualIndicator } from '../VisualIndicator';
import HealthStatusCheckIcon from '../VisualIndicator/HealthStatusCheckIcon';
import { Timer } from '../VisualIndicator/Timer';
import ConfirmDialog from '../common/ConfirmDialog';

const HeaderIndicators: FC = () => {
  const dispatch = useAppDispatch();
  const isCarPresent = useAppSelector((state) => state.messages.isCarPresent);
  const isNewSession = useAppSelector((state) => state.messages.isNewSession);
  const isConnected = useAppSelector(selectIsWebSocketConnected);
  const sessionStatus = useAppSelector(selectSessionStatus);
  const isStaffIntervention = useAppSelector(
    (state) => state.messages.isStaffIntervention
  );
  const [orderStatus, setOrderStatus] = useState(OrderStatus.noOrder);
  const sendStaffInterventionMetric = () => {
    //TODO
  };
  const isTTSOn = useAppSelector((state) => state.messages.isTTSOn);
  const currentTransactionId = useAppSelector(selectCurrentTransactionId);
  const transactions = useAppSelector(selectTransactions);
  const orderError = useAppSelector(selectOrderError);
  const transaction = currentTransactionId
    ? transactions[currentTransactionId]
    : null;

  useEffect(() => {
    if (orderError) {
      setOrderStatus(OrderStatus.failed);
    } else if (!transaction) {
      setOrderStatus(OrderStatus.noOrder);
    } else {
      if (
        transaction?.isFinal &&
        transaction.status === TransactionStatus.completed
      ) {
        setOrderStatus(OrderStatus.completed);
        setTimeout(() => {
          dispatch(resetCurrentTransactionId());
        }, ORDER_STATUS_ICON_RESET_INTERVAL);
      } else if (
        transaction.status === TransactionStatus.pending ||
        transaction.status === TransactionStatus.completed
      ) {
        setOrderStatus(OrderStatus.pending);
      } else if (transaction.status === TransactionStatus.error) {
        setOrderStatus(OrderStatus.failed);
      } else {
        setOrderStatus(OrderStatus.noOrder);
      }
    }
  }, [transaction, transaction?.status, orderError]);

  const getOrderStatusColor = (status: string) => {
    switch (status) {
      case OrderStatus.noOrder:
        return grey['500'];
      case OrderStatus.completed:
        return green['A400'];
      case OrderStatus.pending:
        return yellow['500'];
      case OrderStatus.failed:
        return red['700'];
      default:
        return '';
    }
  };

  useEffect(() => {
    //TTS request being sent when hitl session is started
    if (isConnected && sessionStatus === SessionStatus.start) {
      dispatch(messagingActions.sendTTSRequest({} as any));
    }
  }, [isConnected, sessionStatus, dispatch]);

  const [showEndSessionConfirmDialog, setShowEndSessionConfirmDialog] =
    useState(false);
  const handleEndSession = () => {
    setShowEndSessionConfirmDialog(false);
    dispatch(endSession(SessionEndReasons.killSwitchClicked));
  };
  const taskRouterFeatureFlag = useAppSelector(selectFeatureFlagTaskRouter);
  const currentTaskId = useAppSelector(selectCurrentTask);

  return (
    <>
      {taskRouterFeatureFlag && currentTaskId ? (
        <>
          <VisualIndicator
            onClick={() => setShowEndSessionConfirmDialog(true)}
            IconComponent={LogoutIcon}
            isActive={true}
          />
          {showEndSessionConfirmDialog && (
            <ConfirmDialog
              open={showEndSessionConfirmDialog}
              content="Do you want to exit the session?"
              onClose={() => setShowEndSessionConfirmDialog(false)}
              onConfirm={handleEndSession}
            />
          )}
        </>
      ) : null}
      <HealthStatusCheckIcon />
      <Player />
      <VisualIndicator IconComponent={MicIcon} isActive={isTTSOn} />
      <VisualIndicator
        IconComponent={NetworkCheckIcon}
        isActive={isConnected}
      />
      <VisualIndicator
        IconComponent={HeadsetMicIcon}
        isActive={isStaffIntervention}
        onClick={sendStaffInterventionMetric}
      />
      <VisualIndicator
        IconComponent={DirectionsCarIcon}
        isActive={isCarPresent || isNewSession}
      />
      <VisualIndicator
        IconComponent={AttachMoneyIcon}
        customizedColor={getOrderStatusColor(orderStatus)}
      />
      <Timer />
    </>
  );
};

export default HeaderIndicators;
