import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from '@mui/material';

interface Props {
  open: boolean;
  title?: string;
  content: string | React.ReactNode;
  onClose: () => void;
  onConfirm?: () => void;
  closeBtnText?: string;
  confirmBtnText?: string;
}

export default function ConfirmDialog({
  open,
  title,
  content,
  onClose,
  onConfirm,
  closeBtnText = 'Close',
  confirmBtnText = 'Confirm',
}: Props) {
  const handleClose = (_event: React.MouseEvent, reason: string) => {
    if (reason !== 'backdropClick') {
      onClose();
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.key === 'Enter') {
      if (onConfirm) {
        onConfirm();
      }
    }
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth="sm"
      fullWidth
      onKeyDown={handleKeyDown}
    >
      {title && <DialogTitle>{title}</DialogTitle>}
      <DialogContent>{content}</DialogContent>
      <DialogActions>
        <Button onClick={onClose} variant="outlined">
          {closeBtnText}
        </Button>
        {onConfirm && (
          <Button onClick={onConfirm} variant="contained">
            {confirmBtnText}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
}
