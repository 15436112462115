export const addQueryParamsToUrl = (url: string, params: { [key: string]: string }) => {
  if (Object.values(params).length > 0) {
    const queryParams: string[] = [];
    Object.entries(params).forEach(([key, value]: [string, string]) => {
      queryParams.push(`${key}=${value}`);
    });
    url = `${url}?${queryParams.join('&')}`;
  }
  return url;
};

export const chooseRandomString = (arr: Array<string>) => arr[~~(Math.random() * arr.length)];
