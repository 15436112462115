import { useEffect, useState } from 'react';
import logger from '../../utils/logger';

let controlKey = false;

interface OwnProps {
  onSpeechRecognition: (
    transcript: string,
    isFinal: boolean,
    ctrl: boolean
  ) => void;
}

export const SpeechToText = ({ onSpeechRecognition }: OwnProps) => {
  let recognition: SpeechRecognition;
  const [phrases, setPhrases] = useState<string[]>([]); // Not too sure if we will even need this

  useEffect(() => {
    if (window.webkitSpeechRecognition === undefined) {
      logger.error('Browser Does NOT Support Speech Recognition');
      return;
    }
    recognition = new window.webkitSpeechRecognition();

    if (window.webkitSpeechGrammarList) {
      // SpeechGrammarList is not currently available in Safari, and does not have any effect in any other browser.
      // Please refer to https://github.com/mdn/dom-examples/blob/main/web-speech-api/speech-color-changer/script.js.
      let gS =
        '#JSGF V1.0; grammar phrases; public <phrase> = ' + phrases.join('|');
      const speechRecognitionList = new window.webkitSpeechGrammarList();
      speechRecognitionList.addFromString(gS, 1); // https://wicg.github.io/speech-api/#speechreco-speechgrammar
      recognition.grammars = speechRecognitionList;
    } else {
      logger.info('Browser Does NOT Support Speech Grammar');
    }
    recognition.continuous = true;
    recognition.interimResults = true;
    recognition.onstart = () => {
      logger.debug('Speech recognition started..');
    };
    recognition.onresult = (event: SpeechRecognitionEvent) => {
      const transcript = event.results[0][0].transcript;
      const isFinal = event.results[0].isFinal;
      onSpeechRecognition(transcript, isFinal, controlKey);
    };
    recognition.onend = () => {
      logger.debug('Ended recognition');
    };
  }, []); // Run Once

  const onKeyDown = (e: KeyboardEvent) => {
    if (e.key === 'Control') {
      controlKey = true;
      try {
        recognition?.start();
      } catch (error) {
        recognition?.stop();
        logger.error('Failed starting speech recognition', error);
      }
    }
  };
  const onKeyUp = (e: KeyboardEvent) => {
    if (e.key === 'Control') {
      controlKey = false;
      recognition?.stop();
    }
  };

  useEffect(() => {
    document.addEventListener('keydown', onKeyDown);
    document.addEventListener('keyup', onKeyUp);

    return () => {
      // Cleanup
      document.removeEventListener('keydown', onKeyDown);
      document.removeEventListener('keyup', onKeyUp);
    };
  }, []);
  return null;
};
