import { CartItem } from './cart';
import { ErrorResponse } from './types';

export const getErrorMessages = (errorResponse: ErrorResponse) => {
  return errorResponse.message;
};

export const getOrderErrorMessage = (error: string, cartItem: CartItem) => {
  let result = 'Sorry, ';
  if (
    error.includes(cartItem.name) ||
    error == 'An Unkown Error Has Occurred'
  ) {
    result += cartItem.name;
  } else {
    Object.values(cartItem.childModifierGroups).forEach((childModGroup) => {
      Object.values(childModGroup.selectedItems).forEach((selectedItem) => {
        if (error.includes(selectedItem.name)) {
          result += selectedItem.name + ' ';
        }
      });
    });
    result += 'for ' + cartItem.name;
  }
  result += ' is not available right now. Would you like something else?';
  return result;
};
