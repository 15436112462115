import { Card, CardContent, styled, Typography } from '@mui/material';
import React from 'react';
import { v4 } from 'uuid';
import { LoyaltyCouponItem } from '../../reducers/cartSlice.props';
import Colors, { sequentialItemColors } from '../../utils/color';

interface Props {
  item: LoyaltyCouponItem;
}

const NameWrapper = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
}));

export default function CouponItemReview({ item }: Props) {
  const itemColor = sequentialItemColors[0];
  const { name, isApplied, modifiers } = item;

  return (
    <Card
      elevation={3}
      sx={{ marginTop: 2, cursor: 'pointer', borderRadius: 1.5 }}
    >
      <CardContent sx={{ padding: 2 }}>
        <NameWrapper>
          <Typography
            variant="subtitle2"
            sx={{
              lineHeight: '1.57',
              fontSize: '0.875rem',
              fontWeight: 'bold',
              color: itemColor,
            }}
          >
            {`* ${name}`}
          </Typography>

          <Typography variant="subtitle2" sx={{ paddingLeft: 2 }}>
            Coupon
          </Typography>
        </NameWrapper>

        {isApplied ? (
          <Typography variant="subtitle2">
            Coupon successfully applied
          </Typography>
        ) : (
          <>
            {modifiers.map((modifier) => (
              <Typography
                key={v4()}
                variant="subtitle2"
                sx={{ color: Colors.Thunderbird }}
              >
                {modifier}
              </Typography>
            ))}
          </>
        )}
      </CardContent>
    </Card>
  );
}
