import { Button, InputAdornment, IconButton, Link } from '@mui/material';
import { TextField } from 'mui-rff';
import { TextFieldProps } from 'mui-rff/src/TextField';
import React, { useState } from 'react';
import { Form } from 'react-final-form';
import { Visibility, VisibilityOff } from '@mui/icons-material';

export interface LoginFormData {
  email: string;
  password: string;
}

interface LoginFormProps {
  onSubmit: (data: LoginFormData) => void;
  inputClassName?: string;
  inputLabelClassName?: string;
  formErrors: any;
  passwordInputRef: React.RefObject<HTMLInputElement>;
}

export function LoginForm(props: LoginFormProps) {
  const [showPassword, setShowPassowrd] = useState(false);

  async function validate(values: LoginFormData) {
    if (!values.email || !values.password) {
      return { required: 'Email/Password is required' };
    }
  }

  const baseInputProps: Partial<TextFieldProps> = {
    required: true,
    color: 'primary',
    variant: 'filled',
    InputLabelProps: { className: props.inputLabelClassName },
    FormHelperTextProps: { className: props.inputLabelClassName },
    placeholder: 'Type here',
  };

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  return (
    <Form
      onSubmit={props.onSubmit}
      validate={validate}
      render={({ handleSubmit, values }) => (
        <form onSubmit={handleSubmit} noValidate>
          <TextField
            autoFocus={!props.formErrors}
            autoComplete="off"
            label="Email"
            type="email"
            name="email"
            helperText="Enter your email account"
            InputProps={{ className: props.inputClassName }}
            {...baseInputProps}
          />
          <Link
            tabIndex={-1}
            href="/forgot-password"
            style={{ float: 'right', color: '#2f9bf3' }}
          >
            {'Forgot Password?'}
          </Link>
          <TextField
            autoComplete="off"
            label="Password"
            name="password"
            helperText="Enter your password"
            type={showPassword ? 'text' : 'password'}
            autoFocus={false}
            {...baseInputProps}
            InputProps={{
              className: props.inputClassName,
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={() => setShowPassowrd(!showPassword)}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                    tabIndex={-1}
                  >
                    {showPassword ? (
                      <Visibility style={{ color: 'white' }} />
                    ) : (
                      <VisibilityOff style={{ color: 'white' }} />
                    )}
                  </IconButton>
                </InputAdornment>
              ),
              inputRef: props.passwordInputRef,
            }}
          />
          <Button
            disableElevation
            style={{ float: 'right' }}
            type="submit"
            color="secondary"
            variant="contained"
          >
            Log In
          </Button>
        </form>
      )}
    />
  );
}
