import { useAppSelector } from '../../app/hooks';
import { RestaurantStaffInterventionStatus } from '../../reducers/messagingSlice';
import {
  selectIsStaffIntervention,
  selectRestaurantStaffIntervention,
} from '../../selectors/message';

const useIntervention = () => {
  const restaurantStaffIntervention = useAppSelector(
    selectRestaurantStaffIntervention
  );
  const isStaffIntervention = useAppSelector(selectIsStaffIntervention);

  const isRestaurantStaffInterventionClicked =
    restaurantStaffIntervention !== RestaurantStaffInterventionStatus.initial;
  const isStaffInterventionHappening =
    isStaffIntervention || isRestaurantStaffInterventionClicked;

  return { isStaffInterventionHappening };
};

export default useIntervention;
