import { Button, Theme, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { FC, default as React } from 'react';
import { AgentStatuses } from '../../redux/features/taskRouter/taskRouter.constants';

const useStyles = makeStyles<Theme>(({ spacing }) => ({
  actionButtonClass: {
    marginTop: `${spacing(2)} !important`,
    textTransform: 'uppercase',
  },
}));

type TaskRouterAgentUnavailableProps = {
  handleAgentAvailability: (agentStatus: AgentStatuses) => void;
};

const TaskRouterAgentTraining: FC<TaskRouterAgentUnavailableProps> = ({
  handleAgentAvailability,
}) => {
  const { actionButtonClass } = useStyles();
  const onButtonClick = () => handleAgentAvailability(AgentStatuses.available);

  return (
    <div data-testid="tr-agent-unavailable">
      <Typography variant="h4" gutterBottom>
        You're currently in Training mode
      </Typography>
      <Typography>You can't take orders when in training mode.</Typography>
      <Typography>
        When you're ready, switch your status to Available to start taking
        orders.
      </Typography>
      <Button
        variant="contained"
        color="secondary"
        onClick={onButtonClick}
        className={actionButtonClass}
      >
        change status to available
      </Button>
    </div>
  );
};

export default TaskRouterAgentTraining;
