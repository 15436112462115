import { useCallback } from 'react';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import {
  AgentInterceptionType,
  messagingActions,
} from '../../reducers/messagingSlice';
import {
  selectFeatureFlagTaskRouter,
  selectTRAgentAutoAssignment,
} from '../../redux/features/config/config.selector';
import { TaskStatuses } from '../../redux/features/taskRouter/taskRouter.constants';
import { selectCurrentTask } from '../../redux/features/taskRouter/taskRouter.selector';
import { updateTaskStatus } from '../../redux/features/taskRouter/taskRouter.slice';

function useAgentInterception() {
  const dispatch = useAppDispatch();
  const taskRouterFeatureFlag = useAppSelector(selectFeatureFlagTaskRouter);
  const currentTaskId = useAppSelector(selectCurrentTask);
  const isTaskAssignmentMode = useAppSelector(selectTRAgentAutoAssignment);

  const sendAgentInterception = useCallback(
    (...args: Parameters<typeof messagingActions.sendAgentInterception>) => {
      dispatch(messagingActions.sendAgentInterception(...args));
      const isTTSSentInterception =
        args?.[0]?.data?.type === AgentInterceptionType.TTS_SENT;

      if (
        isTaskAssignmentMode &&
        taskRouterFeatureFlag &&
        currentTaskId &&
        !isTTSSentInterception
      ) {
        dispatch(
          updateTaskStatus({
            taskId: currentTaskId,
            event: TaskStatuses.agentInterception,
          })
        );
      }
    },
    [dispatch]
  );

  return { sendAgentInterception };
}

export default useAgentInterception;
