import { Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React, { useEffect, useRef } from 'react';
import { TextFrameTransmissionMessage } from '../../reducers/messagingSlice';
import Colors from '../../utils/color';
import { LOWER_CASE_O, OH } from '../../utils/constants';
import { MessageLeft, MessageRight } from './Message';

const useStyles = makeStyles((theme: Theme) => ({
  messageContainer: {
    overflowY: 'auto',
    flex: 1,
    padding: '6px',
    maxHeight: 'calc(100vh - 180px)',
    border: `${theme.spacing(0.125)} solid ${Colors.alto}`,
  },
}));

interface OwnProps {
  messageList: TextFrameTransmissionMessage[];
}

export function MessageContainer({ messageList }: OwnProps) {
  const classes = useStyles();
  const messagesBottomRef = useRef<HTMLDivElement>(null);
  const listInnerRef = useRef<HTMLDivElement>(null);

  const lastMessage =
    messageList.length > 0 ? messageList[messageList.length - 1] : undefined;

  const scrollToBottom = () => {
    if (
      messagesBottomRef &&
      messagesBottomRef.current &&
      listInnerRef &&
      listInnerRef.current
    ) {
      const { scrollTop, scrollHeight, clientHeight } = listInnerRef.current;
      if (scrollTop + clientHeight >= scrollHeight - 100) {
        messagesBottomRef.current.scrollIntoView?.();
      }
    }
  };

  useEffect(() => {
    scrollToBottom();
  }, [lastMessage]);

  const renderMessage = (
    message: TextFrameTransmissionMessage,
    scrollRef?: React.RefObject<HTMLDivElement>
  ) => {
    // if (message.data && message.agent_type == 'HITL') {
    //   return <MessageRight scrollRef={scrollRef} key={message.id} message={message.data.payload} />;
    // } else
    if (message.data && message.agent_type == 'ORDERBOARD') {
      if (message.data.status !== 'TTS') {
        return (
          <MessageLeft
            scrollRef={scrollRef}
            key={message.id}
            message={message.data.payload}
          />
        );
      }
      let payload = message.data.payload;
      const regularExpr = /\d+\sOh\s\d+/;
      if (regularExpr.exec(payload)) {
        payload = payload.replace(OH, LOWER_CASE_O); // Display 'Oh' in the total tts visually as 'O'
      }
      return (
        <MessageRight
          scrollRef={scrollRef}
          key={message.id}
          message={payload}
        />
      );
    }
    return <></>;
  };
  return (
    <div
      id="messageContainer"
      className={classes.messageContainer}
      ref={listInnerRef}
    >
      {messageList.map((message) => renderMessage(message))}
      <div ref={messagesBottomRef} />
    </div>
  );
}
