import { readEnvVariable } from '../utils';
import { addQueryParamsToUrl } from '../utils/common';
import { NewRelicLog } from '../utils/types';

const postLogToNewRelic = async (logMessage: NewRelicLog) => {
  const newRelicLogAPI = readEnvVariable('NEW_RELIC_LOG_API');
  const NR_LOG_API_LICENSE_KEY = readEnvVariable(
    'NEW_RELIC_LOG_API_LICENSE_KEY'
  );
  const url = addQueryParamsToUrl(newRelicLogAPI, {
    'Api-Key': NR_LOG_API_LICENSE_KEY,
  });

  try {
    await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify([logMessage]),
    });
  } catch (error) {
    console.error('Failed to send logs to New Relic.');
  }
};

export { postLogToNewRelic };
