export enum MimeType {
  png = 'image/png',
  jpeg = 'image/jpeg',
  plain = 'text/plain',
  json = 'text/json',
}

const extMap: { [key in MimeType]: string } = {
  [MimeType.png]: 'png',
  [MimeType.jpeg]: 'jpeg',
  [MimeType.plain]: 'txt',
  [MimeType.json]: 'json',
};

interface IImageFileOptions {
  /** file name without extension */
  name: string;
  mimeType: MimeType.jpeg | MimeType.png;
}

export const createImageFromCanvas = (
  canvas: HTMLCanvasElement,
  { name, mimeType }: IImageFileOptions
) => {
  const ext = extMap[mimeType];
  return new Promise((resolve, reject) => {
    canvas.toBlob((blob) => {
      if (!blob) {
        reject(new Error('Unable to create image'));
        return;
      }
      resolve(new File([blob], `${name}.${ext}`, { type: mimeType }));
    }, mimeType);
  });
};

interface IFileOptions {
  /** file name without extension */
  name: string;
  mimeType: MimeType.json | MimeType.plain;
}

export const createFileFromText = (
  content: string,
  { name, mimeType }: IFileOptions
) => {
  const ext = extMap[mimeType];
  const blob = new Blob([content], { type: mimeType });
  return new File([blob], `${name}.${ext}`, { type: mimeType });
};
