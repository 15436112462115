import makeStyles from '@mui/styles/makeStyles';
import { Theme } from '@mui/material';
import React from 'react';
import { useAppDispatch, useShallowSelector } from '../../app/hooks';
import {
  messagingActions,
  TextFrameTransmissionMessage,
  InfoTransmissionMessage,
} from '../../reducers/messagingSlice';
import IntentsSection from '../../components/IntentsSection';
import { MessageContainer } from '../../components/Chat/MessageContainer';
import Colors from '../../utils/color';
import { ISOStringToEpoch } from '../../utils/network';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    height: '100%',
    width: '100%',
    wordBreak: 'break-word',
    display: 'flex',
    flexDirection: 'column',
    border: `${theme.spacing(0.125)} solid ${Colors.alto}`,
  },
  chatContainer: {
    flex: 1,
  },
  transcriptContainer: {
    display: 'flex',
    maxHeight: '100%',
  },
}));

export const Messaging = () => {
  const classes = useStyles();

  const messagesHolder = useShallowSelector((state) => {
    return {
      isConnected: state.messages.isConnected,
      finalTextFrames: state.messages.finalTextFrames,
      inProgressHypothesisTextFrames:
        state.messages.inProgressHypothesisTextFrames,
      sentTextFrames: state.messages.sentTextFrames,
    };
  });
  const dispatch = useAppDispatch();
  const sendMessage = (message: string, metadata?: any) => {
    const payload: Partial<TextFrameTransmissionMessage> = {
      data: { payload: message, status: 'final', metadata },
    };
    dispatch(messagingActions.sendMessage(payload as any));
  };

  const sendMetric = (message: string) => {
    const payload: Partial<InfoTransmissionMessage> = {
      data: { type: 'METRIC', message: message },
    };
    dispatch(messagingActions.sendInfo(payload as any));
  };

  const messageList = [
    ...messagesHolder.finalTextFrames,
    ...messagesHolder.sentTextFrames,
  ].sort(
    (a, b) => ISOStringToEpoch(a.timestamp) - ISOStringToEpoch(b.timestamp)
  );
  if (messagesHolder.inProgressHypothesisTextFrames.length > 0) {
    const lastHypothesis =
      messagesHolder.inProgressHypothesisTextFrames[
        messagesHolder.inProgressHypothesisTextFrames.length - 1
      ];
    messageList.push(lastHypothesis);
  }

  return (
    <div className={classes.container}>
      <MessageContainer messageList={messageList} />
      <IntentsSection sendMetric={sendMetric} sendMessage={sendMessage} />
    </div>
  );
};
