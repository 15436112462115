import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../app/store';

const selectRestaurant = (state: RootState) => state.restaurant;
const selectActiveRestaurantDetails = createSelector(
  selectRestaurant,
  (state) => state.selectedRestaurant
);
const selectActiveRestaurantSettings = createSelector(
  selectActiveRestaurantDetails,
  (state) => state?.restaurantSettings
);

const selectActiveRestaurantCode = createSelector(
  selectRestaurant,
  (state) => state.selectedRestaurantCode
);
const selectRestaurantsByUserRole = createSelector(
  selectRestaurant,
  (restaurant) => restaurant.restaurantsByUserRole
);
const selectRestaurantsById = createSelector(
  selectRestaurant,
  (restaurant) => restaurant.restaurantsById
);

const selectRestaurantAccessLevels = (state: RootState) =>
  state.restaurant.restaurantAccessLevels;
const selectTimezone = (state: RootState) =>
  state.restaurant.selectedRestaurant?.hoursOfOperation?.timezone;
const selectCurrentUTCTime = (state: RootState) =>
  state.restaurant.selectedRestaurant?.hoursOfOperation?.currentUTCTime;
const selectedStageSelector = (state: RootState) =>
  state.restaurant.selectedStage;
const selectAIIndicator = (state: RootState) => state.restaurant.isAIEnabled;
const selectIsUnsupervisedAI = createSelector(
  selectActiveRestaurantSettings,
  (state) => state?.isUnsupervisedAi
);

export {
  selectAIIndicator,
  selectActiveRestaurantCode,
  selectCurrentUTCTime,
  selectIsUnsupervisedAI,
  selectRestaurant,
  selectRestaurantAccessLevels,
  selectRestaurantsById,
  selectRestaurantsByUserRole,
  selectTimezone,
  selectedStageSelector,
};
