// Seq-id is stored in this class and will be increment for every log
class SequenceIdHolder {
  #seqId: number;

  constructor() {
    this.#seqId = 0;
  }

  getSeqId() {
    return this.#seqId;
  }

  updateSeqId() {
    this.#seqId++;
  }

  incrementAndGetSeqId() {
    this.updateSeqId();
    return this.#seqId;
  }
}

const seqIdholder = new SequenceIdHolder();

export default seqIdholder;
