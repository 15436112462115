import { Theme, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { FC, MouseEvent, default as React, memo, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import Colors from '../../../src/utils/color';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { Routes } from '../../constants/enums';
import useEventListener from '../../hooks/useEventListener.hooks';
import { selectTaskRouterWaitingTime } from '../../redux/features/config/config.selector';
import {
  startLoading,
  stopLoading,
} from '../../redux/features/loading/loading.slice';
import { TaskStatuses } from '../../redux/features/taskRouter/taskRouter.constants';
import { selectTasksById } from '../../redux/features/taskRouter/taskRouter.selector';
import { updateTaskStatus } from '../../redux/features/taskRouter/taskRouter.slice';
import { selectRestaurantsById } from '../../selectors/restaurant';
import logger from '../../utils/logger';
import CountdownTimer from '../CountdownTimer';

const useStyles = makeStyles<Theme>(({ spacing }) => ({
  taskRouterAcceptanceClass: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: '100%',
  },
  timerWrapperClass: {
    display: 'flex',
    paddingTop: `${spacing(2)} !important`,
    paddingBottom: `${spacing(2)} !important`,
  },
}));

interface ITaskRouterAcceptanceProps {
  taskId: string;
  sendTaskMessage?: Function;
  stopNotificationSound: Function;
  duration?: number;
}

const TaskRouterAcceptance: FC<ITaskRouterAcceptanceProps> = ({
  taskId,
  sendTaskMessage,
  stopNotificationSound,
  duration,
}) => {
  const { taskRouterAcceptanceClass, timerWrapperClass } = useStyles();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const tasksById = useAppSelector(selectTasksById);
  const waitingTime = useAppSelector(selectTaskRouterWaitingTime);
  const restaurantsById = useAppSelector(selectRestaurantsById);
  const restaurantName =
    restaurantsById[tasksById[taskId]?.data.restaurant_code]?.restaurantName ||
    '';

  const handleTaskAccept = () => {
    stopNotificationSound();
    dispatch(startLoading({ isFetchingTaskStatus: true }));
    logger.debug({
      message: `In TaskRouterAcceptance agent clicked on accept: taskId is ${taskId}`,
      taskId,
      isTR: true,
    });
    dispatch(
      updateTaskStatus({
        taskId,
        event: TaskStatuses.accepted,
      })
    );
    //TODO: Verify this scenario - uncommented for now
    setTimeout(() => {
      if (location.pathname.indexOf(Routes.taskRouter) >= 0) {
        dispatch(stopLoading());
      }
    }, waitingTime);
  };

  const handleTimerComplete = () => {
    stopNotificationSound();
    logger.log({
      message:
        'Executing handleTimerComplete and sending task message with timeout',
      taskId,
      isTR: true,
    });
    sendTaskMessage?.({
      ...tasksById[taskId],
      event: TaskStatuses.timeout,
    });
    logger.log({
      message:
        'Executing handleTimerComplete and updating task status as timeout',
      taskId,
      isTR: true,
    });
    dispatch(
      updateTaskStatus({
        taskId,
        event: TaskStatuses.timeout,
      })
    );
  };

  const onKeyClick = (e: Event) => {
    if (e instanceof KeyboardEvent) {
      handleTaskAccept();
    }
  };

  useEventListener('keyup', onKeyClick);

  useEffect(() => {
    logger.debug({
      message: `In TaskRouterAcceptance component mounting: taskId is ${taskId} and timeLeft is ${duration}`,
      taskId,
      isTR: true,
    });
    sendTaskMessage?.({
      ...tasksById[taskId],
      event: TaskStatuses.offeredAck,
    });
  }, []);

  const handleClick = (e: MouseEvent) => {
    e.preventDefault();
    handleTaskAccept();
  };

  return (
    <div
      key={taskId}
      data-testid="tr-acceptance"
      className={taskRouterAcceptanceClass}
      onClick={handleClick}
    >
      <Typography variant="h4" gutterBottom>
        {restaurantName}
      </Typography>
      <Typography variant="h6" gutterBottom>
        New task available!
      </Typography>
      <div className={timerWrapperClass}>
        <CountdownTimer
          size={75}
          onComplete={handleTimerComplete}
          duration={duration}
          colors={[Colors.daintree, Colors.redRibbon, Colors.black]}
          colorsTime={[9, 5, 0]}
        />
      </div>
      <Typography className={timerWrapperClass}>
        Please press any key to accept or click anywhere on the Task Router
        screen
      </Typography>
    </div>
  );
};

export default memo(TaskRouterAcceptance);
