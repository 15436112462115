import { Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React, { memo, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useAppSelector, useShallowSelector } from '../../app/hooks';
import { CartItemReview } from '../../components/OrderReview/CartItemReview';
import LoyaltyCouponItemReview from '../../components/OrderReview/LoyaltyCouponItemReview';
import { sendOrder } from '../../reducers/orderSlice';
import { isValidCartSelector } from '../../utils/cart';
import { selectValidCartItems } from '../../selectors/cart';
import { setCartEmpty } from '../../redux/features/sessionBoundary/sessionBoundary.slice';
import {
  selectCurrentTransactionCoupons,
  selectIsPosmonReconnected,
} from '../../selectors/order';
import {
  addCouponToCart,
  deleteCoupon,
  setLoyaltyApplyStatus,
} from '../../reducers/cartSlice';
import CouponCodeReview from '../../components/OrderReview/CouponCodeReview';
import {
  messagingActions,
  InfoTransmissionMessage,
} from '../../reducers/messagingSlice';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    paddingBottom: '110px',
  },
}));

function OrderReview() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const {
    cartItems,
    cartItemsQuantity,
    loyaltyCouponItem,
    loyaltyApplyStatus,
    loyaltyCode,
    coupons,
  } = useShallowSelector((state) => state.cart);
  const cartValid = useSelector(isValidCartSelector);
  const validCartItems = useSelector(selectValidCartItems);
  const isPosmonReconnected = useAppSelector(selectIsPosmonReconnected);
  const couponsToArray = Object.values(coupons);

  useEffect(() => {
    if (Object.values(validCartItems).length > 0) {
      dispatch(sendOrder({ validCartItems }));
    }
  }, [cartItems, cartItemsQuantity, cartValid, validCartItems, coupons]);

  useEffect(() => {
    if (
      Object.values(validCartItems).length > 0 &&
      loyaltyCouponItem &&
      !loyaltyCouponItem.isApplied
    ) {
      dispatch(sendOrder({ validCartItems }));
    }
  }, [loyaltyCouponItem]);

  useEffect(() => {
    if (Object.values(validCartItems).length > 0 && isPosmonReconnected) {
      dispatch(sendOrder({ validCartItems }));
    }
  }, [isPosmonReconnected]);

  useEffect(() => {
    dispatch(setCartEmpty(!Object.values(cartItems).length));
  }, [cartItems]);

  const cartItemsState = useAppSelector(
    (state) => state.order.currentTransactionItems
  );

  const currentTransactionCoupons = useAppSelector(
    selectCurrentTransactionCoupons
  );

  const removeLoyalty = () => {
    dispatch(setLoyaltyApplyStatus(undefined));
  };

  const removeCoupon = (id: string) => {
    dispatch(deleteCoupon(id));
  };

  const cloneCoupon = (code: string) => {
    const payload: Partial<InfoTransmissionMessage> = {
      data: { message: 'Coupon ' + code + ' added', type: 'METRIC' },
    };
    dispatch(messagingActions.sendInfo(payload as any));
    dispatch(addCouponToCart(code));
  };

  return (
    <div id="orderReview" className={classes.container}>
      {/* Display loyalty coupon Item */}
      {loyaltyCouponItem && (
        <LoyaltyCouponItemReview item={loyaltyCouponItem} />
      )}

      {/* Display loyalty coupon */}
      {!loyaltyCouponItem && loyaltyApplyStatus && loyaltyCode && (
        <CouponCodeReview
          couponCode={loyaltyCode}
          loyaltyApplyStatus={loyaltyApplyStatus}
          handleLoyaltyRemove={removeLoyalty}
        />
      )}

      {/* Display coupon code */}
      {couponsToArray.length > 0 &&
        couponsToArray.map(({ id, code }) => (
          <CouponCodeReview
            key={id}
            couponCode={code}
            couponId={id}
            status={currentTransactionCoupons[id]}
            handleCouponRemove={removeCoupon}
            handleCouponDuplicate={cloneCoupon}
          />
        ))}

      {Object.values(cartItems).map((cartItem) => {
        return (
          <CartItemReview
            key={cartItem.cartItemId}
            status={cartItemsState[cartItem.cartItemId]}
            cartItem={cartItem}
            quantity={cartItemsQuantity[cartItem.cartItemId]}
          />
        );
      })}
    </div>
  );
}

export default memo(OrderReview);
