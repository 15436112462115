import { useCallback, useEffect, useRef } from 'react';

const useEventListener = (
  eventName: string,
  handler: (e: Event) => void,
  element = window
) => {
  const savedHandler = useRef<EventListener>();

  useEffect(() => {
    savedHandler.current = handler;
  }, [handler]);

  const handleEventListener = useCallback((event: Event) => {
    savedHandler.current?.(event);
  }, []);

  useEffect(
    () => {
      // Make sure element supports addEventListener
      // On
      const isSupported = element && element.addEventListener;
      if (!isSupported) return;

      // Create event listener that calls handler function stored in ref

      // Add event listener
      element.addEventListener(eventName, handleEventListener);

      // Remove event listener on cleanup
      return () => {
        element.removeEventListener(eventName, handleEventListener);
      };
    },
    [eventName, element] // Re-run if eventName or element changes
  );
};

export default useEventListener;
