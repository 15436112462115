import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import logger from '../../../utils/logger';
import {
  AgentStatuses,
  TaskStatuses,
  TaskStatusesInRestaurantPage,
} from './taskRouter.constants';
import {
  ITaskRouterSlice,
  ITaskToAdd,
  ITaskUpdation,
} from './taskRouter.props';

const initialState: ITaskRouterSlice = {
  agentStatus: AgentStatuses.available,
  wsConnectionMessage: '',
  tasksById: {},
  tasksOrder: [],
  currentTaskId: '',
  currentTaskRestaurantCode: '',
  currentTaskRestaurantName: '',
  taskAssignedSessionId: '',
  navigationViaTaskRouter: false,
  agentInSession: false,
};

const taskRouterSlice = createSlice({
  name: 'taskRouter',
  initialState,
  reducers: {
    resetTaskRouterData: () => {
      return { ...initialState };
    },
    setAgentStatus: (state, { payload }: PayloadAction<AgentStatuses>) => {
      state.agentStatus = payload;
    },
    setWSConnectionMessage: (state, action) => {
      state.wsConnectionMessage = action.payload;
    },
    addNewTask: (state, { payload }: PayloadAction<ITaskToAdd>) => {
      const {
        data: { task_id: taskId },
      } = payload;
      if (taskId) {
        state.tasksById[taskId] = {
          ...payload,
          taskArrivedTime: new Date().getTime(),
        };
        state.tasksOrder.push(taskId);
      }
    },
    updateTaskStatus: (state, { payload }: PayloadAction<ITaskUpdation>) => {
      const { taskId, event, sessionId = '' } = payload;
      logger.log({
        message: `updateTaskStatus called with ${event}`,
        taskId,
        isTR: true,
      });
      if ([TaskStatuses.rejected, TaskStatuses.timeout].includes(event)) {
        if (state.tasksById[taskId]) {
          delete state.tasksById[taskId];
          state.tasksOrder = state.tasksOrder.filter((item) => item !== taskId);
          if (taskId === state.currentTaskId) {
            logger.log({
              message: `Making currentTaskId as empty because the event received is ${event}`,
              taskId,
              isTR: true,
            });
            state.currentTaskId = '';
            state.currentTaskRestaurantCode = '';
            state.currentTaskRestaurantName = '';
          }
        }
        return;
      }
      if (event === TaskStatuses.assigned) {
        state.taskAssignedSessionId = sessionId;
        state.agentInSession = true;
      }
      if ([TaskStatuses.accepted, TaskStatuses.assigned].includes(event)) {
        logger.log({
          message: `Updating the currentTaskId with ${taskId} as the event received is ${event}`,
          taskId,
          isTR: true,
        });
        state.currentTaskId = taskId;
        state.currentTaskRestaurantCode =
          state.tasksById[taskId]?.data?.restaurant_code;
        state.currentTaskRestaurantName =
          state.tasksById[taskId]?.data?.restaurant_name;
      }
      if (state.tasksById[taskId]) {
        //If task status is invalid and status is being changed during the Restaurant specific page
        const updatedEvent =
          state.tasksById[taskId].event === TaskStatuses.invalid &&
          TaskStatusesInRestaurantPage.includes(event)
            ? TaskStatuses.invalidAck
            : event;
        state.tasksById[taskId].event = updatedEvent;
      }
    },
    setNavigationViaTaskRouter: (state, action) => {
      state.navigationViaTaskRouter = action.payload;
    },
  },
  extraReducers: (builder) => {},
});

export const {
  setAgentStatus,
  resetTaskRouterData,
  updateTaskStatus,
  setWSConnectionMessage,
  addNewTask,
  setNavigationViaTaskRouter,
} = taskRouterSlice.actions;
export default taskRouterSlice.reducer;
