import { AnyAction, Middleware } from 'redux';
import { v4 as uuidv4 } from 'uuid';
import { AgentTypes, EventTypes } from '../constants/event';
import {
  InfoTransmissionMessage,
  messagingActions,
  sendNetworkCallLogs,
} from '../reducers/messagingSlice';
import { checkHealthStatus } from '../redux/features/healthStatusCheck/healthStatusCheck.slice';
import { readEnvVariable } from '../utils';
import logger from '../utils/logger';
import { getWebsocketUrl, sleep } from '../utils/network';
import { RootStore } from './store';
import { Counter } from '../utils/counter';
import { agent } from '../utils/agent';
import { PerfTimer } from '../utils/timer';

const isMatchMessagingAction = (action: AnyAction) => {
  const {
    sendMessage,
    sendInfo,
    sendOrder,
    sendLoyalty,
    sendEndSession,
    sendTTSRequest,
    sendError,
    sendAgentInterception,
    sendStaffInterception,
    sendHITLSessionEnd,
    sendHITLSessionStart,
    sendHITLEventConnection,
  } = messagingActions;
  return (
    sendMessage.match(action) ||
    sendInfo.match(action) ||
    sendOrder.match(action) ||
    sendLoyalty.match(action) ||
    sendEndSession.match(action) ||
    sendTTSRequest.match(action) ||
    sendError.match(action) ||
    sendAgentInterception.match(action) ||
    sendStaffInterception.match(action) ||
    sendHITLSessionEnd.match(action) ||
    sendHITLSessionStart.match(action) ||
    sendHITLEventConnection.match(action)
  );
};

const socketMiddleware: Middleware = (store: RootStore) => {
  let eventSocket: WebSocket | undefined = undefined; // Websocket of event backend
  let audioSocket: WebSocket | undefined = undefined; // Websocket of audio backend

  let webSocketConnectionTimer = new PerfTimer({ autoStart: false });
  let audioWebSocketConnectionTimer = new PerfTimer({ autoStart: false });

  let audioWebSocketRetries = 1; // Count of event backend websocket retry
  let eventWebSocketRetries = 1; // Count of audio backend websocket retry

  let audioDetectedInAudioWS = false;

  return (next) => (action) => {
    const {
      restaurant: { selectedRestaurantCode, selectedStage },
      config: { WEBSOCKET_RETRY_MAX_BACKOFF },
      messages: { isConnected },
      user,
      config,
    } = store.getState();

    const isConnectionEstablished = eventSocket && isConnected;

    const reconnect = async ({
      isAudioWS,
      maxRetries = 999,
    }: {
      isAudioWS: boolean;
      maxRetries?: number;
    }) => {
      let retries = isAudioWS ? audioWebSocketRetries : eventWebSocketRetries;
      if (retries < maxRetries) {
        let multiple = 1 + Math.random(); // multiple is in the range 1+[0 to 1] = [1 to 2]
        let timeToWait = 2 ** retries * 1000 * multiple;
        await sleep(
          timeToWait > WEBSOCKET_RETRY_MAX_BACKOFF
            ? WEBSOCKET_RETRY_MAX_BACKOFF
            : timeToWait
        );

        const isWSEstablished = isAudioWS
          ? audioSocket && audioSocket.readyState !== audioSocket.CLOSED
          : eventSocket && eventSocket.readyState !== eventSocket.CLOSED;

        if (isWSEstablished) {
          return;
        }
        // reconnect if lost connection
        connect({ isAudioWS });
        logger.log({
          restaurantCode: selectedRestaurantCode,
          message: `In ${retries} retry to established connection to restaurant specific ${
            isAudioWS ? 'audio' : ''
          } websocket`,
        });
        if (isAudioWS) {
          audioWebSocketRetries += 1;
        } else {
          eventWebSocketRetries += 1;
        }
      } else {
        logger.log({
          restaurantCode: selectedRestaurantCode,
          message: `Reach ${retries} maximum retry to established connection to restaurant specific ${
            isAudioWS ? 'audio' : ''
          } websocket`,
        });
      }
    };

    const connect = ({ isAudioWS }: { isAudioWS: boolean }) => {
      // This really isn't the "right" way to do this, but it works.
      // TODO This probably needs to be a reducer of some kind, I couldn't work out how to do that.
      const username = user?.userProfile?.username;

      if (!selectedRestaurantCode || !username) {
        return;
      }

      agent.setAgentId(username);

      const webSockerUrl = getWebsocketUrl(
        config,
        selectedRestaurantCode,
        selectedStage,
        isAudioWS
      );

      if (isAudioWS) {
        audioWebSocketConnectionTimer.start();
      } else {
        webSocketConnectionTimer.start();
      }

      const socket = new WebSocket(webSockerUrl);

      logger.log({
        restaurantCode: selectedRestaurantCode,
        message: `Trying to establish connection to restaurant specific ${
          isAudioWS ? 'audio' : ''
        } websocket`,
      });

      socket.onopen = () => {
        logger.log({
          restaurantCode: selectedRestaurantCode,
          message: `Successfully established connection to restaurant specific ${
            isAudioWS ? 'audio' : ''
          } websocket`,
        });

        let duration;
        if (isAudioWS) {
          duration = audioWebSocketConnectionTimer.stop();
          store.dispatch(messagingActions.audioWSConnectionEstablished());
        } else {
          duration = webSocketConnectionTimer.stop();
          store.dispatch(messagingActions.connectionEstablished());
        }

        store.dispatch(
          sendNetworkCallLogs({
            url: webSockerUrl,
            duration,
            message: `restaurant specific ${
              isAudioWS ? 'audio' : ''
            } websocket connection established successfully`,
            via: 'websocket',
          })
        );

        const payload: Partial<InfoTransmissionMessage> = {
          data: {
            type: 'METRIC',
            message: `test ${isAudioWS ? 'audio' : ''} websocket signal`,
          },
        };
        store.dispatch(messagingActions.sendInfo(payload as any));
      };

      let messages: any[] = [];
      socket.onmessage = (event: any) => {
        const data = JSON.parse(event.data);
        if (data) {
          messages.push(data);
        }
      };

      setInterval(() => {
        if (messages.length) {
          const { taskRouter, sessionBoundary } = store.getState();
          const messagesWithoutAudio = messages.filter(
            (message) => message.event !== EventTypes.audio
          );

          if (isAudioWS) {
            audioDetectedInAudioWS = messages.find(
              (message) => message.event === EventTypes.audio
            );
          }

          if (messagesWithoutAudio.length) {
            logger.log({
              restaurantCode: selectedRestaurantCode,
              message: `Received these non-audio messages via ${
                isAudioWS ? 'audio' : ''
              } restaurant specific websocket`,
              moreInfo: JSON.stringify(messagesWithoutAudio),
            });
          }

          if (audioSocket && audioDetectedInAudioWS && !isAudioWS) {
            // Process non-audio event getting from event backend when audio event is detected in audio backend.
            logger.log({
              restaurantCode: selectedRestaurantCode,
              message: `Process these non-audio messages via ${
                isAudioWS ? 'audio' : ''
              } restaurant specific websocket`,
              moreInfo: JSON.stringify(messagesWithoutAudio),
            });
            store.dispatch(
              messagingActions.messageReceived({
                messages: messagesWithoutAudio,
                taskRouter,
                sessionBoundarySessionId: sessionBoundary.sessionId,
              })
            );
          } else {
            logger.log({
              restaurantCode: selectedRestaurantCode,
              message: `Process these messages via ${
                isAudioWS ? 'audio' : ''
              } restaurant specific websocket`,
              moreInfo: JSON.stringify(messagesWithoutAudio),
            });
            /*
            1. Process all events from audio backend when audioSocket is enabled and audio is detected
            2. Process all events from event backend when
              a) audioSocket not exist(not enabled or lost connection)
              b) audioSocket exist but no audio is detected
            */
            store.dispatch(
              messagingActions.messageReceived({
                messages,
                taskRouter,
                sessionBoundarySessionId: sessionBoundary.sessionId,
              })
            );
          }
        }
        messages = [];
      }, 100);

      socket.onclose = (event) => {
        logger.log({
          restaurantCode: selectedRestaurantCode,
          message: `Restaurant specific ${
            isAudioWS ? 'audio' : ''
          } websocket is closed unintentionally`,
          moreInfo: JSON.stringify(event, ['code', 'reason', 'wasClean']),
        });

        if (isAudioWS) {
          if (socket?.readyState === socket?.CLOSED)
            audioWebSocketConnectionTimer.stop();
          store.dispatch(messagingActions.audioWSConnectionLost());
          audioDetectedInAudioWS = false;
        } else {
          if (socket?.readyState === socket?.CLOSED)
            webSocketConnectionTimer.stop();
          store.dispatch(messagingActions.connectionLost());
        }

        reconnect({ isAudioWS });
      };

      store.dispatch(checkHealthStatus());
      return socket;
    };

    if (messagingActions.startConnecting.match(action)) {
      // Close the existing websocket connection before connecting new connection
      if (eventSocket) {
        eventSocket.close();
      }

      eventSocket = connect({ isAudioWS: false });
    }

    if (messagingActions.closeConnection.match(action)) {
      if (eventSocket) {
        eventSocket.onclose = () => {
          logger.log({
            restaurantCode: selectedRestaurantCode,
            message:
              'Successfully closed connection to restaurant specific websocket by close-connection action',
          });
        };
        eventSocket.close();
        eventSocket = undefined;
        eventWebSocketRetries = 1;
      }
    }

    if (messagingActions.startAudioWSConnecting.match(action)) {
      // Close the existing websocket connection before connecting new connection
      if (audioSocket) {
        audioSocket.close();
      }

      audioSocket = connect({ isAudioWS: true });
    }

    if (messagingActions.closeAudioWSConnection.match(action)) {
      if (audioSocket) {
        audioSocket.onclose = () => {
          logger.log({
            restaurantCode: selectedRestaurantCode,
            message:
              'Successfully closed connection to restaurant specific audio websocket by close-connection action',
          });
        };
        audioSocket.close();
        audioSocket = undefined;
        audioDetectedInAudioWS = false;
        audioWebSocketRetries = 1;
      }
    }

    if (isConnectionEstablished && isMatchMessagingAction(action)) {
      eventSocket?.send(JSON.stringify(action.payload));
    }

    next(action);
  };
};

export let infoSeqIdCounter = new Counter();

const messagingTransformMiddleware: Middleware = (store: RootStore) => {
  let textSeqIdCounter = 0;
  let endSessionSeqIdCounter = 0;
  let orderSeqIdCounter = 0;
  let loyaltySeqIdCounter = 0;
  let ttsRequestSeqIdCounter = 0;
  let errorSeqIdCounter = 0;
  let agentInterceptionSeqIdCounter = 0;
  let staffInterceptionSeqIdCounter = 0;
  let hitlSessionStartSeqIdCounter = 0;
  let hitlSessionEndSeqIdCounter = 0;

  let hitlEventConnectionSeqIdCounter = 0;

  return (next) => (action) => {
    if (isMatchMessagingAction(action)) {
      const { sessionBoundary, user, messages } = store.getState() || {};
      let payload = {
        ...action.payload,
        id: uuidv4(),
        session_id: sessionBoundary?.sessionId || messages?.currentSessionId,
        agent_type: AgentTypes.HITL,
        agent_id: agent.getAgentId(),
        timestamp: new Date().toISOString(),
        // I would prefer not to expose this, see PRV-2631
        metadata: { agent_name: user?.userProfile?.username },
        environment: readEnvVariable('DEPLOY_ENV'),
      };

      switch (action.type) {
        case messagingActions.sendMessage.type:
          payload.event = EventTypes.text;
          payload.seq = textSeqIdCounter++;
          break;
        case messagingActions.sendEndSession.type:
          payload.event = EventTypes.endSession;
          payload.seq = endSessionSeqIdCounter++;
          break;
        case messagingActions.sendInfo.type:
          payload.event = EventTypes.info;
          payload.seq = infoSeqIdCounter.increment();
          break;
        case messagingActions.sendOrder.type:
          payload.event = EventTypes.order;
          payload.seq = orderSeqIdCounter++;
          break;
        case messagingActions.sendAgentInterception.type:
          payload.event = EventTypes.agentInterception;
          payload.seq = agentInterceptionSeqIdCounter++;
          break;
        case messagingActions.sendStaffInterception.type:
          payload.event = EventTypes.staffIntervention;
          payload.seq = staffInterceptionSeqIdCounter++;
          break;
        case messagingActions.sendLoyalty.type:
          payload.event = EventTypes.loyalty;
          payload.seq = loyaltySeqIdCounter++;
          break;
        case messagingActions.sendTTSRequest.type:
          payload.event = EventTypes.TTSRequest;
          payload.seq = ttsRequestSeqIdCounter++;
          break;
        case messagingActions.sendError.type:
          payload.event = EventTypes.error;
          payload.seq = errorSeqIdCounter++;
          break;
        case messagingActions.sendHITLSessionStart.type:
          payload.event = EventTypes.hitlSessionStart;
          payload.seq = hitlSessionStartSeqIdCounter++;
          break;
        case messagingActions.sendHITLSessionEnd.type:
          payload.event = EventTypes.hitlSessionEnd;
          payload.seq = hitlSessionEndSeqIdCounter++;
          break;
        case messagingActions.sendHITLEventConnection.type:
          payload.event = EventTypes.hitlEventConnection;
          payload.seq = hitlEventConnectionSeqIdCounter++;
          break;
        default:
          break;
      }
      if (!('data' in payload)) {
        payload.data = {}; // Sending empty data field to be consistent with the high level schema for all other events
      }
      action.payload = payload;
      logger.log({
        restaurantCode: store.getState().restaurant?.selectedRestaurantCode,
        message: `Sending the message with event ${payload.event} via restaurant specific websocket`,
        moreInfo: JSON.stringify(payload),
      });
    }

    next(action);
  };
};

export { messagingTransformMiddleware, socketMiddleware };
