import React, { useEffect } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useAppSelector } from '../app/hooks';
import Body from '../components/Body';
import { selectCurrentTask } from '../redux/features/taskRouter/taskRouter.selector';
import logger from '../utils/logger';

const RestaurantPage = () => {
  const { restaurantId } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const currentTaskId = useAppSelector(selectCurrentTask);

  useEffect(() => {
    if (!currentTaskId) {
      // if agent is not navigating to this page via task-router --> Navigate to unauthorized page
      logger.error({
        restaurantCode: restaurantId,
        message: `Navigating to unauthorized page as currentTaskId is empty`,
      });
      navigate(location.state?.from || '/' + 'unauthorized');
    }
  }, [currentTaskId]);

  return (
    <div data-testid="restaurant-page">
      <Body />
    </div>
  );
};

export default RestaurantPage;
