import { Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React, { useEffect, useRef } from 'react';
import { useAppSelector, useShallowSelector } from '../../app/hooks';
import OrderTotalContainer from '../../features/order-review/OrderTotalContainer';
import Colors from '../../utils/color';
import { OrderActions } from '../OrderReview/OrderActions';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '100%',
    position: 'relative',
    border: `${theme.spacing(0.125)} solid ${Colors.alto}`,
    minWidth: '300px',
    maxWidth: '360px',
  },
  subContainer: {
    position: 'relative',
    flex: 1,
    maxHeight: 'calc(100vh - 164px)',
    overflowY: 'auto',
    overflowX: 'hidden',
    margin: '10px',
  },
}));

export default function RightBar({ children }: { children: React.ReactNode }) {
  const classes = useStyles();
  const containerRef = useRef<HTMLDivElement>(null);

  const selectedItemCartId = useAppSelector((state) => state.dialog.selectedItemCartId);
  const { cartItems } = useShallowSelector((state) => state.cart);
  const cartItemIds = Object.keys(cartItems).map((id) => parseInt(id));

  useEffect(() => {
    if (cartItemIds.length && selectedItemCartId === cartItemIds[cartItemIds.length - 1]) {
      const height = containerRef.current?.scrollHeight;
      containerRef.current?.scrollTo({ top: height });
    }
  }, [cartItemIds, selectedItemCartId]);

  return (
    <div id="rightBar" className={classes.container}>
      <div id="cartContainer" className={classes.subContainer} ref={containerRef}>
        {children}
      </div>
      <OrderTotalContainer />
      <OrderActions />
    </div>
  );
}
