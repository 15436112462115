import { print } from 'graphql';
import { GraphQLClient } from 'graphql-request';
import { GraphQLError } from 'graphql-request/dist/types';
import * as Dom from 'graphql-request/dist/types.dom';
import gql from 'graphql-tag';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** The javascript `Date` as string. Type represents date and time as the ISO Date string. */
  DateTime: any;
  /** The `JSONObject` scalar type represents JSON objects as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSONObject: any;
  /** The `Upload` scalar type represents a file upload. */
  Upload: any;
};

export type AttachRestaurantToRestaurantGroup = {
  restaurantCode: Scalars['String'];
  restaurantGroupIds: Array<Scalars['Float']>;
};

export type Availability = {
  __typename?: 'Availability';
  alwaysEnabled: Scalars['Boolean'];
  day: Scalars['Float'];
  hours: Maybe<Array<Array<Scalars['Float']>>>;
};

export type AvailabilityInput = {
  alwaysEnabled: Scalars['Boolean'];
  day: Scalars['Float'];
  hours: InputMaybe<Array<Array<Scalars['Float']>>>;
};

export type Category = {
  __typename?: 'Category';
  description: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  menuItems: Array<Scalars['Float']>;
  name: Scalars['String'];
  ownSortOrder: Maybe<Scalars['Float']>;
  sortOrder: Array<SortOrder>;
  timePeriods: Array<Scalars['Float']>;
};

export type ChangePaswordInput = {
  email: Scalars['String'];
  newPassword: Scalars['String'];
  password: Scalars['String'];
};

export type CreateRestaurantGroupInput = {
  groupName: Scalars['String'];
  restaurantCodes: Array<Scalars['String']>;
};

export type CreateRestaurantGroupRoleInput = {
  permission: RolePermissions;
  restaurantGroupId: Scalars['Float'];
};

export type CreateRestaurantInput = {
  accountType: RestaurantAccountType;
  primaryRestaurantCode: InputMaybe<Scalars['String']>;
  restaurantCode: Scalars['String'];
  restaurantName: Scalars['String'];
};

export type CreateUserAdminInput = {
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  password: Scalars['String'];
  phoneNumber: InputMaybe<Scalars['String']>;
  restaurantGroupRoles: InputMaybe<Array<UserRestaurantGroupRoleInput>>;
  username: Scalars['String'];
};

export type CreateUserInput = {
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  password: Scalars['String'];
  phoneNumber: InputMaybe<Scalars['String']>;
  username: Scalars['String'];
};

export enum Currency {
  Aed = 'AED',
  Afa = 'AFA',
  All = 'ALL',
  Amd = 'AMD',
  Ang = 'ANG',
  Aor = 'AOR',
  Ars = 'ARS',
  Aud = 'AUD',
  Awg = 'AWG',
  Azn = 'AZN',
  Bbd = 'BBD',
  Bdt = 'BDT',
  Bgn = 'BGN',
  Bhd = 'BHD',
  Bif = 'BIF',
  Bmd = 'BMD',
  Bnd = 'BND',
  Bob = 'BOB',
  Brl = 'BRL',
  Bsd = 'BSD',
  Btn = 'BTN',
  Bwp = 'BWP',
  Byn = 'BYN',
  Bzd = 'BZD',
  Cad = 'CAD',
  Cdf = 'CDF',
  Chf = 'CHF',
  Clp = 'CLP',
  Cny = 'CNY',
  Cop = 'COP',
  Crc = 'CRC',
  Cup = 'CUP',
  Cve = 'CVE',
  Czk = 'CZK',
  Djf = 'DJF',
  Dkk = 'DKK',
  Dop = 'DOP',
  Dzd = 'DZD',
  Eek = 'EEK',
  Egp = 'EGP',
  Ern = 'ERN',
  Etb = 'ETB',
  Eur = 'EUR',
  Fjd = 'FJD',
  Fkp = 'FKP',
  Gbp = 'GBP',
  Gel = 'GEL',
  Ghs = 'GHS',
  Gip = 'GIP',
  Gmd = 'GMD',
  Gnf = 'GNF',
  Gtq = 'GTQ',
  Gyd = 'GYD',
  Hkd = 'HKD',
  Hnl = 'HNL',
  Hrk = 'HRK',
  Htg = 'HTG',
  Huf = 'HUF',
  Idr = 'IDR',
  Ils = 'ILS',
  Inr = 'INR',
  Iqd = 'IQD',
  Irr = 'IRR',
  Isk = 'ISK',
  Jmd = 'JMD',
  Jod = 'JOD',
  Jpy = 'JPY',
  Kes = 'KES',
  Kgs = 'KGS',
  Khr = 'KHR',
  Kmf = 'KMF',
  Kpw = 'KPW',
  Krw = 'KRW',
  Kwd = 'KWD',
  Kyd = 'KYD',
  Kzt = 'KZT',
  Lak = 'LAK',
  Lbp = 'LBP',
  Lkr = 'LKR',
  Lrd = 'LRD',
  Lsl = 'LSL',
  Ltl = 'LTL',
  Lvl = 'LVL',
  Lyd = 'LYD',
  Mad = 'MAD',
  Mdl = 'MDL',
  Mga = 'MGA',
  Mkd = 'MKD',
  Mmk = 'MMK',
  Mnt = 'MNT',
  Mop = 'MOP',
  Mro = 'MRO',
  Mur = 'MUR',
  Mvr = 'MVR',
  Mwk = 'MWK',
  Mxn = 'MXN',
  Myr = 'MYR',
  Mzn = 'MZN',
  Nad = 'NAD',
  Ngn = 'NGN',
  Nio = 'NIO',
  Nok = 'NOK',
  Npr = 'NPR',
  Nzd = 'NZD',
  Omr = 'OMR',
  Pab = 'PAB',
  Pen = 'PEN',
  Pgk = 'PGK',
  Php = 'PHP',
  Pkr = 'PKR',
  Pln = 'PLN',
  Pyg = 'PYG',
  Qar = 'QAR',
  Ron = 'RON',
  Rsd = 'RSD',
  Rub = 'RUB',
  Rwf = 'RWF',
  Sar = 'SAR',
  Sbd = 'SBD',
  Scr = 'SCR',
  Sdg = 'SDG',
  Sek = 'SEK',
  Sgd = 'SGD',
  Shp = 'SHP',
  Sll = 'SLL',
  Sos = 'SOS',
  Srd = 'SRD',
  Std = 'STD',
  Svc = 'SVC',
  Syp = 'SYP',
  Szl = 'SZL',
  Thb = 'THB',
  Tjs = 'TJS',
  Tmt = 'TMT',
  Tnd = 'TND',
  Top = 'TOP',
  Try = 'TRY',
  Ttd = 'TTD',
  Twd = 'TWD',
  Tzs = 'TZS',
  Uah = 'UAH',
  Ugx = 'UGX',
  Usd = 'USD',
  Uyu = 'UYU',
  Uzs = 'UZS',
  Vef = 'VEF',
  Vnd = 'VND',
  Vuv = 'VUV',
  Wst = 'WST',
  Xaf = 'XAF',
  Xag = 'XAG',
  Xau = 'XAU',
  Xcd = 'XCD',
  Xdr = 'XDR',
  Xfo = 'XFO',
  Xfu = 'XFU',
  Xof = 'XOF',
  Xpd = 'XPD',
  Xpf = 'XPF',
  Xpt = 'XPT',
  Yer = 'YER',
  Zar = 'ZAR',
  Zmk = 'ZMK',
  Zwl = 'ZWL',
}

export type EmailInput = {
  email: Scalars['String'];
};

export type HoursOfOperation = {
  __typename?: 'HoursOfOperation';
  availability: Maybe<Array<Availability>>;
  currentUTCTime: Scalars['String'];
  id: Scalars['ID'];
  timezone: Scalars['String'];
};

export type HoursOfOperationInput = {
  availability: InputMaybe<Array<AvailabilityInput>>;
  timezone: InputMaybe<Scalars['String']>;
};

export type LoginInput = {
  email: Scalars['String'];
  password: Scalars['String'];
};

export type MfLoginInput = {
  otp: Scalars['String'];
  source: Scalars['String'];
  token: Scalars['String'];
};

export type MfUserProfile = {
  __typename?: 'MFUserProfile';
  authorizationToken: Maybe<Scalars['String']>;
  disabled: Scalars['Boolean'];
  email: Scalars['String'];
  firstName: Scalars['String'];
  id: Scalars['ID'];
  lastLogin: Maybe<Scalars['DateTime']>;
  lastName: Scalars['String'];
  loginAttempts: Scalars['Float'];
  mfaEnabled: Scalars['Boolean'];
  password: Scalars['String'];
  phoneNumber: Maybe<Scalars['String']>;
  qrCodeURL: Maybe<Scalars['String']>;
  resetCode: Maybe<Scalars['String']>;
  restaurantGroupRoles: Array<RestaurantGroupRole>;
  username: Scalars['String'];
};

export type MenuItem = {
  __typename?: 'MenuItem';
  available: Scalars['Boolean'];
  availableLimitedQuantity: Maybe<Scalars['Float']>;
  customRequestAvailable: Scalars['Boolean'];
  description: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  imageUrl: Maybe<Scalars['String']>;
  isModifierOnly: Scalars['Boolean'];
  menuItemSettings: Array<Scalars['Float']>;
  menuOverrides: Array<Scalars['Float']>;
  modifierGroups: Array<Scalars['Float']>;
  name: Scalars['String'];
  parentCategoryIds?: Array<Scalars['Float']>;
  posProperties: Array<Scalars['Float']>;
  price: Scalars['Float'];
  sortOrder: Array<SortOrder>;
  tax: Maybe<Scalars['Float']>;
  unavailableUntil: Maybe<Scalars['DateTime']>;
};

export type MenuItemSetting = {
  __typename?: 'MenuItemSetting';
  id: Scalars['ID'];
  key: Scalars['String'];
  menuItemId: Scalars['Float'];
  value: Scalars['String'];
};

export enum MenuItemSettingKey {
  CellNumber = 'CELL_NUMBER',
  IsAlcoholicItem = 'IS_ALCOHOLIC_ITEM',
  IsDeliveryEnabled = 'IS_DELIVERY_ENABLED',
  IsDineInEnabled = 'IS_DINE_IN_ENABLED',
  IsRefillEnabled = 'IS_REFILL_ENABLED',
  IsToGoEnabled = 'IS_TO_GO_ENABLED',
  PosId = 'POS_ID',
  ScreenNumber = 'SCREEN_NUMBER',
}

export type MenuOverride = {
  __typename?: 'MenuOverride';
  availableOverride: Scalars['Boolean'];
  id: Scalars['ID'];
  modalityType: Maybe<ModalityType>;
  objectPrimaryKey: Scalars['String'];
  overrideKey: OverrideKey;
  overrideType: OverrideType;
  overrideValue: Scalars['String'];
  secondaryId: Maybe<Scalars['String']>;
  secondaryType: Maybe<SecondaryType>;
};

export type MenuOverrideInput = {
  modalityType: InputMaybe<ModalityType>;
  objectPrimaryKey: Scalars['String'];
  overrideKey: OverrideKey;
  overrideValue: InputMaybe<Scalars['String']>;
  secondaryId: InputMaybe<Scalars['String']>;
  secondaryType: InputMaybe<SecondaryType>;
};

export enum ModalityType {
  Delivery = 'DELIVERY',
  Dinein = 'DINEIN',
  Togo = 'TOGO',
}

export type ModifierGroup = {
  __typename?: 'ModifierGroup';
  defaultSelectedItemIds: Maybe<Array<Scalars['Float']>>;
  description: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  maximumSelections: Scalars['Float'];
  menuItems: Array<Scalars['Float']>;
  minimumSelections: Scalars['Float'];
  name: Scalars['String'];
  sortOrder: Array<SortOrder>;
};

export type Mutation = {
  __typename?: 'Mutation';
  attachMenuItemCategory: Category;
  attachMenuItemToModGroup: ModifierGroup;
  attachModGroupToMenuItem: MenuItem;
  attachRestaurantGroupRoleToUser: UserProfile;
  attachRestaurantToRestaurantGroup: Restaurant;
  attachTimePeriodCategory: Category;
  changePassword: Scalars['Boolean'];
  ckeMenuUpload: Scalars['String'];
  clearCache: Scalars['String'];
  completePasswordReset: UserProfile;
  createCategory: Category;
  createHoursOfOperation: Restaurant;
  createMenuItem: MenuItem;
  createMenuItemSetting: MenuItemSetting;
  createMenuOverride: MenuOverride;
  createModifierGroup: ModifierGroup;
  createNewUser: UserProfile;
  createPosProperties: PosProperties;
  createRestaurant: Restaurant;
  createRestaurantGroup: RestaurantGroup;
  createRestaurantGroupRole: RestaurantGroupRole;
  createTimePeriod: TimePeriod;
  createUserAdmin: UserProfile;
  deleteCategory: Scalars['Boolean'];
  deleteMenuItem: Scalars['Boolean'];
  deleteMenuItemSetting: Scalars['Boolean'];
  deleteMenuOverride: Scalars['Boolean'];
  deleteModifierGroup: Scalars['Boolean'];
  deleteRestaurantGroup: Scalars['Boolean'];
  deleteTimePeriod: Scalars['Boolean'];
  duplicateCategory: Category;
  duplicateMenuItem: MenuItem;
  duplicateModifierGroup: ModifierGroup;
  pagerDutyUpload: Scalars['String'];
  removeModGroupFromMenuItem: Scalars['Boolean'];
  removeTimePeriodCategory: Scalars['Boolean'];
  resetRestaurantMenu: Scalars['Boolean'];
  startPasswordReset: Scalars['Boolean'];
  updateCategoriesForMenu: Array<Category>;
  updateCategory: Category;
  updateHoursOfOperation: Restaurant;
  updateItemAvailability: MenuItem;
  updateMenuItem: MenuItem;
  updateMenuItemAvailableQuantity: MenuItem;
  updateMenuItemSetting: MenuItemSetting;
  updateMenuItemsAvailableQuantities: Array<MenuItem>;
  updateMenuOverride: MenuOverride;
  updateModifierGroup: ModifierGroup;
  updatePrimaryRestaurant: Restaurant;
  updateRestaurant: Restaurant;
  updateRestaurantBranding: Restaurant;
  updateRestaurantContactInfo: Restaurant;
  updateRestaurantGroup: RestaurantGroup;
  updateRestaurantSettings: Restaurant;
  updateTimePeriod: TimePeriod;
  updateUser: UserProfile;
  updateUserAdmin: UserProfile;
  uploadImage: Scalars['String'];
  uploadMenuItemImage: MenuItem;
};

export type MutationAttachMenuItemCategoryArgs = {
  categoryId: Scalars['Float'];
  menuItemId: Scalars['Float'];
  restaurantCode: Scalars['String'];
  sortOrder: InputMaybe<Scalars['Float']>;
};

export type MutationAttachMenuItemToModGroupArgs = {
  menuItemId: Scalars['Float'];
  modifierGroupId: Scalars['Float'];
  restaurantCode: Scalars['String'];
  sortOrder: InputMaybe<Scalars['Float']>;
};

export type MutationAttachModGroupToMenuItemArgs = {
  menuItemId: Scalars['Float'];
  modifierGroupId: Scalars['Float'];
  restaurantCode: Scalars['String'];
  sortOrder: InputMaybe<Scalars['Float']>;
};

export type MutationAttachRestaurantGroupRoleToUserArgs = {
  restaurantGroupRoleId: Scalars['Float'];
  userId: Scalars['Float'];
};

export type MutationAttachRestaurantToRestaurantGroupArgs = {
  data: AttachRestaurantToRestaurantGroup;
};

export type MutationAttachTimePeriodCategoryArgs = {
  categoryId: Scalars['Float'];
  restaurantCode: Scalars['String'];
  timePeriodId: Scalars['Float'];
};

export type MutationChangePasswordArgs = {
  data: ChangePaswordInput;
};

export type MutationCkeMenuUploadArgs = {
  file: Scalars['Upload'];
  restaurantCode: Scalars['String'];
};

export type MutationClearCacheArgs = {
  restaurantCode: Scalars['String'];
};

export type MutationCompletePasswordResetArgs = {
  data: PasswordResetInput;
};

export type MutationCreateCategoryArgs = {
  data: NewCategoryInput;
};

export type MutationCreateHoursOfOperationArgs = {
  data: HoursOfOperationInput;
  restaurantCode: Scalars['String'];
};

export type MutationCreateMenuItemArgs = {
  data: NewMenuItemInput;
};

export type MutationCreateMenuItemSettingArgs = {
  data: NewMenuItemSettingInput;
  restaurantCode: Scalars['String'];
};

export type MutationCreateMenuOverrideArgs = {
  data: NewMenuOverrideInput;
};

export type MutationCreateModifierGroupArgs = {
  data: NewModifierGroupInput;
};

export type MutationCreateNewUserArgs = {
  data: CreateUserInput;
};

export type MutationCreatePosPropertiesArgs = {
  data: NewPosPropertiesInput;
};

export type MutationCreateRestaurantArgs = {
  data: CreateRestaurantInput;
};

export type MutationCreateRestaurantGroupArgs = {
  data: CreateRestaurantGroupInput;
};

export type MutationCreateRestaurantGroupRoleArgs = {
  data: CreateRestaurantGroupRoleInput;
};

export type MutationCreateTimePeriodArgs = {
  data: NewTimePeriodInput;
};

export type MutationCreateUserAdminArgs = {
  data: CreateUserAdminInput;
};

export type MutationDeleteCategoryArgs = {
  categoryId: Scalars['Float'];
  rebuildCache: InputMaybe<Scalars['Boolean']>;
  restaurantCode: Scalars['String'];
};

export type MutationDeleteMenuItemArgs = {
  itemId: Scalars['Float'];
  rebuildCache: InputMaybe<Scalars['Boolean']>;
  restaurantCode: Scalars['String'];
};

export type MutationDeleteMenuItemSettingArgs = {
  menuItemSettingId: Scalars['Float'];
  restaurantCode: Scalars['String'];
};

export type MutationDeleteMenuOverrideArgs = {
  menuOverrideId: Scalars['Float'];
  restaurantCode: Scalars['String'];
};

export type MutationDeleteModifierGroupArgs = {
  modifierGroupId: Scalars['Float'];
  rebuildCache: InputMaybe<Scalars['Boolean']>;
  restaurantCode: Scalars['String'];
};

export type MutationDeleteRestaurantGroupArgs = {
  id: Scalars['Float'];
};

export type MutationDeleteTimePeriodArgs = {
  rebuildCache: Scalars['Boolean'];
  restaurantCode: Scalars['String'];
  timePeriodId: Scalars['Float'];
};

export type MutationDuplicateCategoryArgs = {
  rebuildCache: InputMaybe<Scalars['Boolean']>;
  restaurantCode: Scalars['String'];
  sourceCategoryId: Scalars['Float'];
  version: InputMaybe<Scalars['Float']>;
};

export type MutationDuplicateMenuItemArgs = {
  rebuildCache: InputMaybe<Scalars['Boolean']>;
  restaurantCode: Scalars['String'];
  sourceMenuItemId: Scalars['Float'];
  version: InputMaybe<Scalars['Float']>;
};

export type MutationDuplicateModifierGroupArgs = {
  rebuildCache: InputMaybe<Scalars['Boolean']>;
  restaurantCode: Scalars['String'];
  sourceModifierGroupId: Scalars['Float'];
  version: InputMaybe<Scalars['Float']>;
};

export type MutationPagerDutyUploadArgs = {
  file: Scalars['Upload'];
  fileName?: InputMaybe<Scalars['String']>;
};

export type MutationRemoveModGroupFromMenuItemArgs = {
  menuItemId: Scalars['Float'];
  modifierGroupId: Scalars['Float'];
  restaurantCode: Scalars['String'];
};

export type MutationRemoveTimePeriodCategoryArgs = {
  categoryId: Scalars['Float'];
  restaurantCode: Scalars['String'];
  timePeriodId: Scalars['Float'];
};

export type MutationResetRestaurantMenuArgs = {
  restaurantCode: Scalars['String'];
};

export type MutationStartPasswordResetArgs = {
  data: EmailInput;
};

export type MutationUpdateCategoriesForMenuArgs = {
  data: UpdateCategoriesSortOrderInput;
  restaurantCode: Scalars['String'];
};

export type MutationUpdateCategoryArgs = {
  categoryId: Scalars['Float'];
  data: UpdateCategoryInput;
  restaurantCode: Scalars['String'];
};

export type MutationUpdateHoursOfOperationArgs = {
  data: HoursOfOperationInput;
  restaurantCode: Scalars['String'];
};

export type MutationUpdateItemAvailabilityArgs = {
  data: UpdateMenuItemAvailabilityInput;
  restaurantCode: Scalars['String'];
};

export type MutationUpdateMenuItemArgs = {
  data: UpdateMenuItemInput;
  menuItemId: Scalars['Float'];
  restaurantCode: Scalars['String'];
  version: InputMaybe<Scalars['Float']>;
};

export type MutationUpdateMenuItemAvailableQuantityArgs = {
  menuItemId: Scalars['Float'];
  newAvailableQuantity: Scalars['Float'];
  restaurantCode: Scalars['String'];
};

export type MutationUpdateMenuItemSettingArgs = {
  data: UpdateMenuItemSettingInput;
  menuItemSettingId: Scalars['Float'];
  restaurantCode: Scalars['String'];
};

export type MutationUpdateMenuItemsAvailableQuantitiesArgs = {
  data: Array<UpdateMenuItemQuantityInput>;
  restaurantCode: Scalars['String'];
};

export type MutationUpdateMenuOverrideArgs = {
  data: UpdateMenuOverrideInput;
  restaurantCode: Scalars['String'];
};

export type MutationUpdateModifierGroupArgs = {
  data: UpdateModifierGroupInput;
  modifierGroupId: Scalars['Float'];
  restaurantCode: Scalars['String'];
};

export type MutationUpdatePrimaryRestaurantArgs = {
  primaryRestaurantCode: Scalars['String'];
  restaurantCode: Scalars['String'];
};

export type MutationUpdateRestaurantArgs = {
  restaurantCode: Scalars['String'];
  restaurantName: Scalars['String'];
};

export type MutationUpdateRestaurantBrandingArgs = {
  data: RestaurantBrandingInput;
  restaurantCode: Scalars['String'];
};

export type MutationUpdateRestaurantContactInfoArgs = {
  data: RestaurantContactInfoInput;
  restaurantCode: Scalars['String'];
};

export type MutationUpdateRestaurantGroupArgs = {
  data: UpdateRestaurantGroupInput;
};

export type MutationUpdateRestaurantSettingsArgs = {
  data: RestaurantSettingsInput;
  restaurantCode: Scalars['String'];
};

export type MutationUpdateTimePeriodArgs = {
  data: UpdateTimePeriodInput;
  restaurantCode: Scalars['String'];
  timePeriodId: Scalars['Float'];
};

export type MutationUpdateUserArgs = {
  data: UpdateUserInput;
};

export type MutationUpdateUserAdminArgs = {
  data: UpdateUserAdminInput;
};

export type MutationUploadImageArgs = {
  file: Scalars['Upload'];
  restaurantCode: Scalars['String'];
};

export type MutationUploadMenuItemImageArgs = {
  file: Scalars['Upload'];
  menuItemId: Scalars['Float'];
  restaurantCode: Scalars['String'];
};

export type NewCategoryInput = {
  childMenuItemIds: Array<Scalars['Float']>;
  description: InputMaybe<Scalars['String']>;
  menuItemOverrides: Array<MenuOverrideInput>;
  menuItemsSortOrder: Array<SortOrderInput>;
  name: Scalars['String'];
  rebuildCache: InputMaybe<Scalars['Boolean']>;
  restaurantCode: Scalars['String'];
  timePeriodIds: Array<Scalars['Float']>;
};

export type NewMenuItemInput = {
  available: InputMaybe<Scalars['Boolean']>;
  availableLimitedQuantity: InputMaybe<Scalars['Float']>;
  description: InputMaybe<Scalars['String']>;
  imageUrl: InputMaybe<Scalars['String']>;
  isModifierOnly: Scalars['Boolean'];
  menuItemSettings: InputMaybe<Array<NewMenuItemSettingForMenuItemInput>>;
  menuOverrides: InputMaybe<Array<MenuOverrideInput>>;
  modifierGroupIds: Array<Scalars['Float']>;
  modifierGroupsSortOrder: Array<SortOrderInput>;
  name: Scalars['String'];
  parentCategoryIds: Array<Scalars['Float']>;
  posProperties: InputMaybe<Array<NewPosPropertiesForMenuItemInput>>;
  price: Scalars['Float'];
  rebuildCache: InputMaybe<Scalars['Boolean']>;
  restaurantCode: Scalars['String'];
  tax: InputMaybe<Scalars['Float']>;
  unavailableUntil: InputMaybe<Scalars['DateTime']>;
};

export type NewMenuItemSettingForMenuItemInput = {
  key: MenuItemSettingKey;
  value: Scalars['String'];
};

export type NewMenuItemSettingInput = {
  key: MenuItemSettingKey;
  menuItemId: Scalars['Float'];
  rebuildCache: InputMaybe<Scalars['Boolean']>;
  restaurantCode: Scalars['String'];
  value: Scalars['String'];
};

export type NewMenuOverrideInput = {
  availableOverride: InputMaybe<Scalars['Boolean']>;
  modalityType: InputMaybe<ModalityType>;
  objectPrimaryKey: Scalars['String'];
  overrideKey: OverrideKey;
  overrideType: OverrideType;
  overrideValue: Scalars['String'];
  rebuildCache: InputMaybe<Scalars['Boolean']>;
  restaurantCode: Scalars['String'];
  secondaryId: InputMaybe<Scalars['String']>;
  secondaryType: InputMaybe<SecondaryType>;
};

export type NewModifierGroupInput = {
  childMenuItemIds: Array<Scalars['Float']>;
  defaultSelectedItemIds: InputMaybe<Array<Scalars['Float']>>;
  description: InputMaybe<Scalars['String']>;
  maximumSelections: Scalars['Float'];
  menuItemOverrides: Array<MenuOverrideInput>;
  menuItemsSortOrder: Array<SortOrderInput>;
  minimumSelections: Scalars['Float'];
  name: Scalars['String'];
  rebuildCache: InputMaybe<Scalars['Boolean']>;
  restaurantCode: Scalars['String'];
};

export type NewPosPropertiesForMenuItemInput = {
  key: Scalars['String'];
  value: Scalars['String'];
};

export type NewPosPropertiesInput = {
  key: Scalars['String'];
  objectPrimaryKey: Scalars['String'];
  propertyType: PropertyType;
  rebuildCache: InputMaybe<Scalars['Boolean']>;
  restaurantCode: Scalars['String'];
  value: Scalars['String'];
};

export type NewTimePeriodInput = {
  availability: Array<AvailabilityInput>;
  description: InputMaybe<Scalars['String']>;
  /** End date of time period. If null then time period is active from start date until the end of time. */
  endDate: InputMaybe<Scalars['DateTime']>;
  rebuildCache: InputMaybe<Scalars['Boolean']>;
  restaurantCode: Scalars['String'];
  /** Start date of time period. If null then time period is active from beginning of time until the end date. */
  startDate: InputMaybe<Scalars['DateTime']>;
};

export enum OverrideKey {
  CellNumber = 'CELL_NUMBER',
  PosId = 'POS_ID',
  Price = 'PRICE',
  ScreenNumber = 'SCREEN_NUMBER',
  Tax = 'TAX',
}

export enum OverrideType {
  MenuItem = 'MENU_ITEM',
  Modifier = 'MODIFIER',
}

export type PasswordResetInput = {
  confirmPassword: Scalars['String'];
  email: Scalars['String'];
  password: Scalars['String'];
  resetCode: Scalars['String'];
};

export type PosProperties = {
  __typename?: 'PosProperties';
  id: Scalars['ID'];
  key: Scalars['String'];
  objectPrimaryKey: Scalars['String'];
  propertyType: PropertyType;
  value: Scalars['String'];
};

export enum PropertyType {
  Category = 'CATEGORY',
  MenuItem = 'MENU_ITEM',
  ModifierGroup = 'MODIFIER_GROUP',
  Restaurant = 'RESTAURANT',
  TimePeriod = 'TIME_PERIOD',
}

export type Query = {
  __typename?: 'Query';
  categories: Array<Category>;
  logOut: Scalars['String'];
  login: UserProfile;
  menuItemSettings: Array<MenuItemSetting>;
  menuItems: Array<MenuItem>;
  menuOverrides: Array<MenuOverride>;
  mfLogin: UserProfile;
  modifierGroups: Array<ModifierGroup>;
  posProperties: Array<PosProperties>;
  posPropertiesByKeyValues: Array<PosProperties>;
  posPropertyConflicts: Scalars['JSONObject'];
  posPropertyMaps: Array<Scalars['JSONObject']>;
  primaryRestaurant: Restaurant;
  primaryRestaurants: Array<Restaurant>;
  queryCKEMenu: Scalars['String'];
  restaurant: Restaurant;
  restaurantGroups: Array<RestaurantGroup>;
  restaurantGroupsWithUsers: Array<RestaurantGroupWithUsers>;
  restaurants: Array<Restaurant>;
  restaurantsByUserRole: Array<UserRestaurantsRole>;
  roles: Array<Role>;
  timePeriodForCategories: Array<TimePeriodForCategory>;
  timePeriods: Array<TimePeriod>;
  userByEmail: UserProfile;
  users: Array<UserProfile>;
  verifyGAuthToken: MfUserProfile;
};

export type QueryCategoriesArgs = {
  categoryIds: InputMaybe<Array<Scalars['Float']>>;
  restaurantCode: Scalars['String'];
  version: InputMaybe<Scalars['Float']>;
};

export type QueryLoginArgs = {
  data: LoginInput;
};

export type QueryMenuItemSettingsArgs = {
  itemIds: InputMaybe<Array<Scalars['Float']>>;
  restaurantCode: Scalars['String'];
  version: InputMaybe<Scalars['Float']>;
};

export type QueryMenuItemsArgs = {
  itemIds: InputMaybe<Array<Scalars['Float']>>;
  restaurantCode: Scalars['String'];
  version: InputMaybe<Scalars['Float']>;
};

export type QueryMenuOverridesArgs = {
  overrideIds: InputMaybe<Array<Scalars['Float']>>;
  overrideType: InputMaybe<Scalars['String']>;
  overrideTypeKeys: InputMaybe<Array<Scalars['Float']>>;
  restaurantCode: Scalars['String'];
  version: InputMaybe<Scalars['Float']>;
};

export type QueryMfLoginArgs = {
  data: MfLoginInput;
};

export type QueryModifierGroupsArgs = {
  modifierGroupIds: InputMaybe<Array<Scalars['Float']>>;
  restaurantCode: Scalars['String'];
  version: InputMaybe<Scalars['Float']>;
};

export type QueryPosPropertiesArgs = {
  objectPrimaryKey: InputMaybe<Scalars['String']>;
  propertyType: InputMaybe<Scalars['String']>;
  propertyTypeKeys: InputMaybe<Array<Scalars['Float']>>;
  restaurantCode: Scalars['String'];
  version: InputMaybe<Scalars['Float']>;
};

export type QueryPosPropertiesByKeyValuesArgs = {
  key: InputMaybe<Scalars['String']>;
  restaurantCode: Scalars['String'];
  valueKeys: InputMaybe<Array<Scalars['String']>>;
  version: InputMaybe<Scalars['Float']>;
};

export type QueryPosPropertyConflictsArgs = {
  restaurantCode: Scalars['String'];
};

export type QueryPosPropertyMapsArgs = {
  restaurantCode: Scalars['String'];
};

export type QueryPrimaryRestaurantArgs = {
  restaurantCode: Scalars['String'];
};

export type QueryQueryCkeMenuArgs = {
  restaurantCode: Scalars['String'];
};

export type QueryRestaurantArgs = {
  restaurantCode: Scalars['String'];
};

export type QueryTimePeriodForCategoriesArgs = {
  restaurantCode: Scalars['String'];
  version: InputMaybe<Scalars['Float']>;
};

export type QueryTimePeriodsArgs = {
  restaurantCode: Scalars['String'];
  timePeriodIds: InputMaybe<Array<Scalars['Float']>>;
  version: InputMaybe<Scalars['Float']>;
};

export type QueryUserByEmailArgs = {
  data: EmailInput;
};

export type QueryVerifyGAuthTokenArgs = {
  source: Scalars['String'];
  token: Scalars['String'];
};

export type Restaurant = {
  __typename?: 'Restaurant';
  accountType: RestaurantAccountType;
  hoursOfOperation: Maybe<HoursOfOperation>;
  id: Scalars['ID'];
  primaryRestaurantCode: Maybe<Scalars['String']>;
  restaurantBranding: Maybe<RestaurantBranding>;
  restaurantCode: Scalars['String'];
  restaurantContactInfo: Maybe<RestaurantContactInfo>;
  restaurantName: Scalars['String'];
  restaurantSettings: Maybe<RestaurantSettings>;
};

export enum RestaurantAccountType {
  Demo = 'DEMO',
  Live = 'LIVE',
}

export type RestaurantBranding = {
  __typename?: 'RestaurantBranding';
  backgroundImageUrl: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  logoUrl: Maybe<Scalars['String']>;
  primaryColor: Scalars['String'];
  secondaryColor: Scalars['String'];
  timezone: Scalars['String'];
};

export type RestaurantBrandingInput = {
  backgroundImageUrl: InputMaybe<Scalars['String']>;
  logoUrl: InputMaybe<Scalars['String']>;
  primaryColor: InputMaybe<Scalars['String']>;
  secondaryColor: InputMaybe<Scalars['String']>;
  timezone: InputMaybe<Scalars['String']>;
};

export type RestaurantContactInfo = {
  __typename?: 'RestaurantContactInfo';
  address: Maybe<Scalars['String']>;
  city: Maybe<Scalars['String']>;
  country: Maybe<Scalars['String']>;
  email: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  phoneNumber: Maybe<Scalars['String']>;
  state: Maybe<Scalars['String']>;
  website: Maybe<Scalars['String']>;
  zipCode: Maybe<Scalars['String']>;
};

export type RestaurantContactInfoInput = {
  address: InputMaybe<Scalars['String']>;
  city: InputMaybe<Scalars['String']>;
  country: InputMaybe<Scalars['String']>;
  email: InputMaybe<Scalars['String']>;
  phoneNumber: InputMaybe<Scalars['String']>;
  state: InputMaybe<Scalars['String']>;
  website: InputMaybe<Scalars['String']>;
  zipCode: InputMaybe<Scalars['String']>;
};

export type RestaurantGroup = {
  __typename?: 'RestaurantGroup';
  groupName: Scalars['String'];
  id: Scalars['ID'];
  restaurants: Array<Restaurant>;
};

export type RestaurantGroupRole = {
  __typename?: 'RestaurantGroupRole';
  id: Scalars['ID'];
  restaurantGroup: RestaurantGroup;
  role: Role;
};

export type RestaurantGroupWithUsers = {
  __typename?: 'RestaurantGroupWithUsers';
  attachedUsers: Array<UserRoleAttachment>;
  groupName: Scalars['String'];
  restaurants: Array<Restaurant>;
};

export type RestaurantSettings = {
  __typename?: 'RestaurantSettings';
  autoGratuityDisclaimerText: Maybe<Scalars['String']>;
  autoGratuityInPercentage: Maybe<Scalars['Float']>;
  currency: Currency;
  defaultTipPercentages: Array<Scalars['Float']>;
  deliveryModalityTaxRate: Scalars['Float'];
  dineInModalityTaxRate: Scalars['Float'];
  disablePortalMenu: Scalars['Boolean'];
  id: Scalars['ID'];
  isAlertingEnabled: Scalars['Boolean'];
  isAvailableForOrdering: Scalars['Boolean'];
  isPayOnlyEnabled: Scalars['Boolean'];
  isTabEnabled: Scalars['Boolean'];
  isTaxEnabled: Scalars['Boolean'];
  isTipEnabled: Scalars['Boolean'];
  skipAdvancedPayments: Scalars['Boolean'];
  smsVerificationText: Maybe<Scalars['String']>;
  tabSize: Scalars['Float'];
  toGoDefaultSMSText: Maybe<Scalars['String']>;
  toGoModalityTaxRate: Scalars['Float'];
  toGoOrderConfirmationMessage: Maybe<Scalars['String']>;
  toGoPickupMinutes: Maybe<Scalars['Float']>;
};

export type RestaurantSettingsInput = {
  autoGratuityDisclaimerText: InputMaybe<Scalars['String']>;
  autoGratuityInPercentage: InputMaybe<Scalars['Float']>;
  currency: InputMaybe<Currency>;
  defaultTipPercentages: InputMaybe<Array<Scalars['Float']>>;
  deliveryModalityTaxRate: InputMaybe<Scalars['Float']>;
  dineInModalityTaxRate: InputMaybe<Scalars['Float']>;
  isAlertingEnabled: InputMaybe<Scalars['Boolean']>;
  isAvailableForOrdering: InputMaybe<Scalars['Boolean']>;
  isPayOnlyEnabled: InputMaybe<Scalars['Boolean']>;
  isTabEnabled: InputMaybe<Scalars['Boolean']>;
  isTaxEnabled: InputMaybe<Scalars['Boolean']>;
  isTipEnabled: InputMaybe<Scalars['Boolean']>;
  skipAdvancedPayments: InputMaybe<Scalars['Boolean']>;
  tabSize: InputMaybe<Scalars['Float']>;
  toGoDefaultSMSText: InputMaybe<Scalars['String']>;
  toGoModalityTaxRate: InputMaybe<Scalars['Float']>;
  toGoOrderConfirmationMessage: InputMaybe<Scalars['String']>;
  toGoPickupMinutes: InputMaybe<Scalars['Float']>;
};

export type Role = {
  __typename?: 'Role';
  description: Scalars['String'];
  id: Scalars['ID'];
  permission: RolePermissions;
};

export enum RolePermissions {
  Hitl = 'HITL',
  Limited = 'LIMITED',
  PrestoAdmin = 'PrestoAdmin',
  PrestoCustomerSupport = 'PrestoCustomerSupport',
  RestaurantManager = 'RestaurantManager',
  RestaurantStaff = 'RestaurantStaff',
}

export enum SecondaryType {
  Category = 'CATEGORY',
  ModifierGroup = 'MODIFIER_GROUP',
}

export type SortOrder = {
  __typename?: 'SortOrder';
  id: Scalars['Float'];
  sortOrder: Maybe<Scalars['Float']>;
};

export type SortOrderInput = {
  id: Scalars['Float'];
  sortOrder: Scalars['Float'];
};

export type TimePeriod = {
  __typename?: 'TimePeriod';
  availability: Array<Availability>;
  description: Maybe<Scalars['String']>;
  endDate: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  startDate: Maybe<Scalars['DateTime']>;
  timePeriodCategoryMappings: Array<Scalars['Float']>;
};

export type TimePeriodForCategory = {
  __typename?: 'TimePeriodForCategory';
  categoryRowId: Scalars['Float'];
  id: Scalars['ID'];
  sortOrder: Scalars['Float'];
  timePeriodRowId: Scalars['Float'];
};

export type UpdateCategoriesSortOrderInput = {
  categoriesSortOrder: Array<SortOrderInput>;
  rebuildCache: InputMaybe<Scalars['Boolean']>;
  restaurantCode: Scalars['String'];
};

export type UpdateCategoryInput = {
  childMenuItemIds: Array<Scalars['Float']>;
  description: InputMaybe<Scalars['String']>;
  menuItemOverrides: Array<MenuOverrideInput>;
  menuItemsSortOrder: Array<SortOrderInput>;
  name: Scalars['String'];
  rebuildCache: InputMaybe<Scalars['Boolean']>;
  restaurantCode: Scalars['String'];
  timePeriodIds: Array<Scalars['Float']>;
};

export type UpdateMenuItemAvailabilityInput = {
  available: Scalars['Boolean'];
  availableLimitedQuantity: InputMaybe<Scalars['Float']>;
  id: Scalars['Float'];
  rebuildCache: InputMaybe<Scalars['Boolean']>;
  restaurantCode: Scalars['String'];
  unavailableUntil: InputMaybe<Scalars['DateTime']>;
};

export type UpdateMenuItemInput = {
  available: InputMaybe<Scalars['Boolean']>;
  availableLimitedQuantity: InputMaybe<Scalars['Float']>;
  description: InputMaybe<Scalars['String']>;
  id: Scalars['Float'];
  imageUrl: InputMaybe<Scalars['String']>;
  isModifierOnly: Scalars['Boolean'];
  menuItemSettings: InputMaybe<Array<NewMenuItemSettingForMenuItemInput>>;
  menuOverrides: InputMaybe<Array<MenuOverrideInput>>;
  modifierGroupIds: Array<Scalars['Float']>;
  modifierGroupsSortOrder: Array<SortOrderInput>;
  name: Scalars['String'];
  parentCategoryIds: Array<Scalars['Float']>;
  posProperties: InputMaybe<Array<NewPosPropertiesForMenuItemInput>>;
  price: Scalars['Float'];
  rebuildCache: InputMaybe<Scalars['Boolean']>;
  restaurantCode: Scalars['String'];
  tax: InputMaybe<Scalars['Float']>;
  unavailableUntil: InputMaybe<Scalars['DateTime']>;
};

export type UpdateMenuItemQuantityInput = {
  menuItemId: Scalars['Float'];
  quantity: Scalars['Float'];
  type: Scalars['String'];
};

export type UpdateMenuItemSettingInput = {
  id: Scalars['Float'];
  rebuildCache: InputMaybe<Scalars['Boolean']>;
  restaurantCode: Scalars['String'];
  value: Scalars['String'];
};

export type UpdateMenuOverrideInput = {
  availableOverride: InputMaybe<Scalars['Boolean']>;
  modalityType: InputMaybe<ModalityType>;
  objectPrimaryKey: Scalars['String'];
  overrideKey: OverrideKey;
  overrideType: OverrideType;
  overrideValue: InputMaybe<Scalars['String']>;
  rebuildCache: InputMaybe<Scalars['Boolean']>;
  restaurantCode: Scalars['String'];
  secondaryId: InputMaybe<Scalars['String']>;
  secondaryType: InputMaybe<SecondaryType>;
};

export type UpdateModifierGroupInput = {
  childMenuItemIds: Array<Scalars['Float']>;
  defaultSelectedItemIds: InputMaybe<Array<Scalars['Float']>>;
  description: InputMaybe<Scalars['String']>;
  maximumSelections: InputMaybe<Scalars['Float']>;
  menuItemOverrides: Array<MenuOverrideInput>;
  menuItemsSortOrder: Array<SortOrderInput>;
  minimumSelections: InputMaybe<Scalars['Float']>;
  name: Scalars['String'];
  rebuildCache: InputMaybe<Scalars['Boolean']>;
  restaurantCode: Scalars['String'];
};

export type UpdateRestaurantGroupInput = {
  groupName: Scalars['String'];
  id: Scalars['Float'];
  restaurantCodes: Array<Scalars['String']>;
};

export type UpdateTimePeriodInput = {
  availability: Array<AvailabilityInput>;
  description: InputMaybe<Scalars['String']>;
  /** End date of time period. If null then time period is active from start date until the end of time. */
  endDate: InputMaybe<Scalars['DateTime']>;
  rebuildCache: InputMaybe<Scalars['Boolean']>;
  restaurantCode: Scalars['String'];
  /** Start date of time period. If null then time period is active from beginning of time until the end date. */
  startDate: InputMaybe<Scalars['DateTime']>;
};

export type UpdateUserAdminInput = {
  email: Scalars['String'];
  firstName: Scalars['String'];
  id: Scalars['Float'];
  lastName: Scalars['String'];
  phoneNumber: InputMaybe<Scalars['String']>;
  restaurantGroupRoles: InputMaybe<Array<UserRestaurantGroupRoleInput>>;
};

export type UpdateUserInput = {
  email: Scalars['String'];
  firstName: Scalars['String'];
  id: Scalars['Float'];
  lastName: Scalars['String'];
  phoneNumber: InputMaybe<Scalars['String']>;
};

export type UserProfile = {
  __typename?: 'UserProfile';
  authorizationToken: Maybe<Scalars['String']>;
  disabled: Scalars['Boolean'];
  email: Scalars['String'];
  firstName: Scalars['String'];
  id: Scalars['ID'];
  lastLogin: Maybe<Scalars['DateTime']>;
  lastName: Scalars['String'];
  loginAttempts: Scalars['Float'];
  mfaEnabled: Scalars['Boolean'];
  password: Scalars['String'];
  phoneNumber: Maybe<Scalars['String']>;
  resetCode: Maybe<Scalars['String']>;
  restaurantGroupRoles: Array<RestaurantGroupRole>;
  username: Scalars['String'];
};

export type UserRestaurantGroupRoleInput = {
  groupId: Scalars['Float'];
  permission: RolePermissions;
};

export type UserRestaurantsRole = {
  __typename?: 'UserRestaurantsRole';
  restaurants: Array<Restaurant>;
  role: RolePermissions;
};

export type UserRoleAttachment = {
  __typename?: 'UserRoleAttachment';
  permission: RolePermissions;
  user: UserProfile;
};

export type RestaurantsByUserRoleQueryQueryVariables = Exact<{
  [key: string]: never;
}>;

export type RestaurantsByUserRoleQueryQuery = {
  __typename?: 'Query';
  restaurantsByUserRole: Array<{
    __typename?: 'UserRestaurantsRole';
    role: RolePermissions;
    restaurants: Array<{
      __typename?: 'Restaurant';
      restaurantName: string;
      restaurantCode: string;
    }>;
  }>;
};

export type PagerDutyUploadMutationVariables = Exact<{
  file: Scalars['Upload'];
  fileName: InputMaybe<Scalars['String']>;
}>;

export type PagerDutyUploadMutation = {
  __typename?: 'Mutation';
  pagerDutyUpload: string;
};

export type MenuItemsQueryQueryVariables = Exact<{
  restaurantCode: Scalars['String'];
  version: InputMaybe<Scalars['Float']>;
}>;

export type MenuItemsQueryQuery = {
  __typename?: 'Query';
  menuItems: Array<{
    __typename?: 'MenuItem';
    id: string;
    name: string;
    price: number;
    tax: number | null;
    description: string | null;
    imageUrl: string | null;
    available: boolean;
    unavailableUntil: any | null;
    isModifierOnly: boolean;
    customRequestAvailable: boolean;
    modifierGroups: Array<number>;
    menuOverrides: Array<number>;
    menuItemSettings: Array<number>;
    availableLimitedQuantity: number | null;
    posProperties: Array<number>;
    sortOrder: Array<{
      __typename?: 'SortOrder';
      id: number;
      sortOrder: number | null;
    }>;
  }>;
  menuOverrides: Array<{
    __typename?: 'MenuOverride';
    id: string;
    overrideType: OverrideType;
    objectPrimaryKey: string;
    overrideKey: OverrideKey;
    overrideValue: string;
    secondaryType: SecondaryType | null;
    secondaryId: string | null;
    modalityType: ModalityType | null;
    availableOverride: boolean;
  }>;
  menuItemSettings: Array<{
    __typename?: 'MenuItemSetting';
    id: string;
    key: string;
    value: string;
    menuItemId: number;
  }>;
  posProperties: Array<{
    __typename?: 'PosProperties';
    id: string;
    key: string;
    value: string;
    objectPrimaryKey: string;
    propertyType: PropertyType;
  }>;
};

export type CategoryAndTimePeriodQueryQueryVariables = Exact<{
  restaurantCode: Scalars['String'];
  version: InputMaybe<Scalars['Float']>;
}>;

export type CategoryAndTimePeriodQueryQuery = {
  __typename?: 'Query';
  categories: Array<{
    __typename?: 'Category';
    id: string;
    name: string;
    description: string | null;
    menuItems: Array<number>;
    timePeriods: Array<number>;
    ownSortOrder: number | null;
    sortOrder: Array<{
      __typename?: 'SortOrder';
      id: number;
      sortOrder: number | null;
    }>;
  }>;
  timePeriods: Array<{
    __typename?: 'TimePeriod';
    id: string;
    description: string | null;
    timePeriodCategoryMappings: Array<number>;
    availability: Array<{
      __typename?: 'Availability';
      day: number;
      hours: Array<Array<number>> | null;
      alwaysEnabled: boolean;
    }>;
  }>;
};

export type ModifierGroupsQueryQueryVariables = Exact<{
  restaurantCode: Scalars['String'];
  version: InputMaybe<Scalars['Float']>;
}>;

export type ModifierGroupsQueryQuery = {
  __typename?: 'Query';
  modifierGroups: Array<{
    __typename?: 'ModifierGroup';
    id: string;
    name: string;
    description: string | null;
    minimumSelections: number;
    maximumSelections: number;
    defaultSelectedItemIds: Array<number> | null;
    menuItems: Array<number>;
    sortOrder: Array<{
      __typename?: 'SortOrder';
      id: number;
      sortOrder: number | null;
    }>;
  }>;
};

export type RestaurantInfoQueryVariables = Exact<{
  restaurantCode: Scalars['String'];
}>;

export type RestaurantInfoQuery = {
  __typename?: 'Query';
  restaurant: {
    __typename?: 'Restaurant';
    restaurantName: string;
    restaurantCode: string;
    accountType: RestaurantAccountType;
    restaurantSettings: {
      __typename?: 'RestaurantSettings';
      dineInModalityTaxRate: number;
      deliveryModalityTaxRate: number;
      toGoModalityTaxRate: number;
      toGoOrderConfirmationMessage: string | null;
      currency: Currency;
    } | null;
    restaurantContactInfo: {
      __typename?: 'RestaurantContactInfo';
      address: string | null;
      city: string | null;
      state: string | null;
      zipCode: string | null;
      country: string | null;
      phoneNumber: string | null;
      website: string | null;
      email: string | null;
    } | null;
    restaurantBranding: {
      __typename?: 'RestaurantBranding';
      logoUrl: string | null;
      backgroundImageUrl: string | null;
    } | null;
    hoursOfOperation: {
      __typename?: 'HoursOfOperation';
      id: string;
      timezone: string;
      availability: Array<{
        __typename?: 'Availability';
        day: number;
        hours: Array<Array<number>> | null;
        alwaysEnabled: boolean;
      }> | null;
    } | null;
  };
};

export type GroupIdMappingQueryVariables = Exact<{
  restaurantCode: Scalars['String'];
}>;

export type GroupIdMappingQuery = {
  __typename?: 'Query';
  queryCKEMenu: string;
};

export const RestaurantsByUserRoleQueryDocument = gql`
  query restaurantsByUserRoleQuery {
    restaurantsByUserRole {
      role
      restaurants {
        restaurantName
        restaurantCode
        restaurantBranding {
          logoUrl
        }
      }
    }
  }
`;

export const PagerDutyUploadDocument = gql`
  mutation pagerDutyUpload($file: Upload!, $fileName: String) {
    pagerDutyUpload(file: $file, fileName: $fileName)
  }
`;
export const MenuItemsQueryDocument = gql`
  query menuItemsQuery($restaurantCode: String!, $version: Float) {
    menuItems(restaurantCode: $restaurantCode, version: $version) {
      id
      name
      price
      tax
      description
      imageUrl
      available
      unavailableUntil
      isModifierOnly
      customRequestAvailable
      modifierGroups
      menuOverrides
      menuItemSettings
      sortOrder {
        id
        sortOrder
      }
      availableLimitedQuantity
      posProperties
    }
    menuOverrides(restaurantCode: $restaurantCode, version: $version) {
      id
      overrideType
      objectPrimaryKey
      overrideKey
      overrideValue
      secondaryType
      secondaryId
      overrideKey
      modalityType
      overrideValue
      availableOverride
    }
    menuItemSettings(restaurantCode: $restaurantCode, version: $version) {
      id
      key
      value
      menuItemId
    }
    posProperties(restaurantCode: $restaurantCode, version: $version) {
      id
      key
      value
      objectPrimaryKey
      propertyType
    }
  }
`;
export const CategoryAndTimePeriodQueryDocument = gql`
  query categoryAndTimePeriodQuery($restaurantCode: String!, $version: Float) {
    categories(restaurantCode: $restaurantCode, version: $version) {
      id
      name
      description
      menuItems
      timePeriods
      ownSortOrder
      sortOrder {
        id
        sortOrder
      }
    }
    timePeriods(restaurantCode: $restaurantCode, version: $version) {
      id
      description
      availability {
        day
        hours
        alwaysEnabled
      }
      timePeriodCategoryMappings
    }
  }
`;
export const ModifierGroupsQueryDocument = gql`
  query modifierGroupsQuery($restaurantCode: String!, $version: Float) {
    modifierGroups(restaurantCode: $restaurantCode, version: $version) {
      id
      name
      description
      minimumSelections
      maximumSelections
      defaultSelectedItemIds
      menuItems
      sortOrder {
        id
        sortOrder
      }
    }
  }
`;
export const RestaurantInfoDocument = gql`
  query restaurantInfo($restaurantCode: String!) {
    restaurant(restaurantCode: $restaurantCode) {
      restaurantName
      restaurantCode
      accountType
      restaurantSettings {
        dineInModalityTaxRate
        deliveryModalityTaxRate
        toGoModalityTaxRate
        toGoOrderConfirmationMessage
        currency
      }
      restaurantContactInfo {
        address
        city
        state
        zipCode
        country
        phoneNumber
        website
        email
      }
      restaurantBranding {
        logoUrl
        backgroundImageUrl
      }
      hoursOfOperation {
        id
        timezone
        availability {
          day
          hours
          alwaysEnabled
        }
      }
    }
  }
`;
export const GroupIdMappingDocument = gql`
  query groupIdMapping($restaurantCode: String!) {
    queryCKEMenu(restaurantCode: $restaurantCode)
  }
`;

export type SdkFunctionWrapper = <T>(
  action: (requestHeaders?: Record<string, string>) => Promise<T>,
  operationName: string
) => Promise<T>;

const defaultWrapper: SdkFunctionWrapper = (action, _operationName) => action();
const RestaurantsByUserRoleQueryDocumentString = print(
  RestaurantsByUserRoleQueryDocument
);
const PagerDutyUploadDocumentString = print(PagerDutyUploadDocument);
const MenuItemsQueryDocumentString = print(MenuItemsQueryDocument);
const CategoryAndTimePeriodQueryDocumentString = print(
  CategoryAndTimePeriodQueryDocument
);
const ModifierGroupsQueryDocumentString = print(ModifierGroupsQueryDocument);
const RestaurantInfoDocumentString = print(RestaurantInfoDocument);
const GroupIdMappingDocumentString = print(GroupIdMappingDocument);
export function getSdk(
  client: GraphQLClient,
  withWrapper: SdkFunctionWrapper = defaultWrapper
) {
  return {
    restaurantsByUserRoleQuery(
      variables?: RestaurantsByUserRoleQueryQueryVariables,
      requestHeaders?: Dom.RequestInit['headers']
    ): Promise<{
      data?: RestaurantsByUserRoleQueryQuery | undefined;
      extensions?: any;
      headers: Dom.Headers;
      status: number;
      errors?: GraphQLError[] | undefined;
    }> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.rawRequest<RestaurantsByUserRoleQueryQuery>(
            RestaurantsByUserRoleQueryDocumentString,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders }
          ),
        'restaurantsByUserRoleQuery'
      );
    },
    pagerDutyUpload(
      variables: PagerDutyUploadMutationVariables,
      requestHeaders?: Dom.RequestInit['headers']
    ): Promise<{
      data?: PagerDutyUploadMutation | undefined;
      extensions?: any;
      headers: Dom.Headers;
      status: number;
      errors?: GraphQLError[] | undefined;
    }> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.rawRequest<PagerDutyUploadMutation>(
            PagerDutyUploadDocumentString,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders }
          ),
        'pagerDutyUpload'
      );
    },
    menuItemsQuery(
      variables: MenuItemsQueryQueryVariables,
      requestHeaders?: Dom.RequestInit['headers']
    ): Promise<{
      data?: MenuItemsQueryQuery | undefined;
      extensions?: any;
      headers: Dom.Headers;
      status: number;
      errors?: GraphQLError[] | undefined;
    }> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.rawRequest<MenuItemsQueryQuery>(
            MenuItemsQueryDocumentString,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders }
          ),
        'menuItemsQuery'
      );
    },
    categoryAndTimePeriodQuery(
      variables: CategoryAndTimePeriodQueryQueryVariables,
      requestHeaders?: Dom.RequestInit['headers']
    ): Promise<{
      data?: CategoryAndTimePeriodQueryQuery | undefined;
      extensions?: any;
      headers: Dom.Headers;
      status: number;
      errors?: GraphQLError[] | undefined;
    }> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.rawRequest<CategoryAndTimePeriodQueryQuery>(
            CategoryAndTimePeriodQueryDocumentString,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders }
          ),
        'categoryAndTimePeriodQuery'
      );
    },
    modifierGroupsQuery(
      variables: ModifierGroupsQueryQueryVariables,
      requestHeaders?: Dom.RequestInit['headers']
    ): Promise<{
      data?: ModifierGroupsQueryQuery | undefined;
      extensions?: any;
      headers: Dom.Headers;
      status: number;
      errors?: GraphQLError[] | undefined;
    }> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.rawRequest<ModifierGroupsQueryQuery>(
            ModifierGroupsQueryDocumentString,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders }
          ),
        'modifierGroupsQuery'
      );
    },
    restaurantInfo(
      variables: RestaurantInfoQueryVariables,
      requestHeaders?: Dom.RequestInit['headers']
    ): Promise<{
      data?: RestaurantInfoQuery | undefined;
      extensions?: any;
      headers: Dom.Headers;
      status: number;
      errors?: GraphQLError[] | undefined;
    }> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.rawRequest<RestaurantInfoQuery>(
            RestaurantInfoDocumentString,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders }
          ),
        'restaurantInfo'
      );
    },
    groupIdMapping(
      variables: GroupIdMappingQueryVariables,
      requestHeaders?: Dom.RequestInit['headers']
    ): Promise<{
      data?: GroupIdMappingQuery | undefined;
      extensions?: any;
      headers: Dom.Headers;
      status: number;
      errors?: GraphQLError[] | undefined;
    }> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.rawRequest<GroupIdMappingQuery>(
            GroupIdMappingDocumentString,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders }
          ),
        'groupIdMapping'
      );
    },
  };
}
export type Sdk = ReturnType<typeof getSdk>;
