import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { v4 as uuidv4 } from 'uuid';
import { AUTH_TOKEN_RESPONSE_HEADER } from '../constants';
import { GroupIdMappingQuery } from '../generated-interfaces/graphql';
import { resetAI } from '../redux/features/ai/ai.slice';
import { UserDetails } from '../types';
import { readEnvVariable } from '../utils';
import { get, post } from '../utils/api';
import {
  GROUP_ID_MAPPING_DEFAULT_RESTAURANT,
  SOURCE,
} from '../utils/constants';
import inMemoryGroupIdMapping from '../utils/inMemoryGroupIdMapping';
import {
  getAuthToken,
  getSessionID,
  saveSessionID,
  saveUserState,
} from '../utils/local-storage';
import logger from '../utils/logger';
import { getGraphQLClient, setAuthToken } from '../utils/network';
import { ERROR_ACTIONS } from './errorSlice';
import {
  resetHypothesisFrame,
  setIsAIActive,
  sendNetworkCallLogs,
} from './messagingSlice';
import { restaurantsByUserRole } from './restaurantSlice';
import { PerfTimer } from '../utils/timer';

export interface UserState {
  userProfile: UserDetails | null;
  isLoggedIn: boolean;
  token: string | null;
  didAttemptAuthCheck: boolean;
}

export const initialUserState: UserState = {
  userProfile: null,
  isLoggedIn: false,
  token: null,
  didAttemptAuthCheck: false,
};

export const login = createAsyncThunk(
  'user/login',
  async (
    { email, password }: { email: string; password: string },
    { dispatch, getState, rejectWithValue }
  ) => {
    const {
      config: { AUTH_URL },
    } = getState();
    try {
      const response = await post({
        url: `${AUTH_URL}/login`,
        data: {
          email: email.trim(),
          password,
        },
      });
      const { data, headers = {} } = response || {};

      setAuthToken(headers[AUTH_TOKEN_RESPONSE_HEADER]);

      dispatch(restaurantsByUserRole());
      dispatch(ERROR_ACTIONS.clearErrors());
      dispatch(createUserSession(data));
      dispatch(setIsAIActive(false));
      dispatch(resetHypothesisFrame());
      dispatch(resetAI());
      return data;
    } catch (error: any) {
      logger.error({
        message: 'GraphQL error: Failed to login',
        error: error.response.errors,
      });
      return rejectWithValue(error.response.errors);
    }
  }
);

export const initialAuthCheck = createAsyncThunk(
  'user/initialAuthCheck',
  async (val: boolean, { dispatch, getState, rejectWithValue }) => {
    const {
      config: { AUTH_URL },
    } = getState();
    const authToken = getAuthToken();
    if (!authToken) {
      logger.error('No Auth Token Found');
      return rejectWithValue(new Error('No Auth Token Found'));
    }
    setAuthToken(authToken);
    try {
      const response = await get({
        url: `${AUTH_URL}/login/status`,
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      });
      const { data, headers = {} } = response || {};
      setAuthToken(headers[AUTH_TOKEN_RESPONSE_HEADER]);
      dispatch(restaurantsByUserRole());
      dispatch(fetchGroupIdMapping(GROUP_ID_MAPPING_DEFAULT_RESTAURANT));
      return data;
    } catch (error) {
      logger.error({
        message: 'GraphQL error: Failed to perform initialAuthCheck',
        error,
      });
      return rejectWithValue(error);
    }
  }
);

/**
 * This is called every 30 minutes so that new JWT token is received and in the apiCallWrapper the new JWT value is stored
 */
export const refreshAuthToken = createAsyncThunk(
  'user/refreshAuthToken',
  async (_, { getState, rejectWithValue }) => {
    const {
      config: { AUTH_URL },
      restaurant: { selectedRestaurantCode },
    } = getState();
    const authToken = getAuthToken();
    if (!authToken) {
      logger.debug('No Auth Token Found');
      return rejectWithValue(new Error('No Auth Token Found'));
    }
    let url = `${AUTH_URL}/login/status?`;
    if (selectedRestaurantCode) {
      url += `restaurant_code=${selectedRestaurantCode}`;
    }
    try {
      const response = await get({
        url,
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      });

      const { headers = {} } = response || {};

      setAuthToken(headers[AUTH_TOKEN_RESPONSE_HEADER]);

      // return authStatus;
    } catch (error) {
      logger.error({
        message: 'Failed to get new auth token',
        error,
      });
      return rejectWithValue(error);
    }
  }
);

export const createUserSession = createAsyncThunk(
  'user/createUserSession',
  async (
    {
      username,
      email,
      firstName,
      lastName,
    }: { username: string; email: string; firstName: string; lastName: string },
    { dispatch, rejectWithValue }
  ) => {
    const authToken = getAuthToken();
    if (!authToken) {
      throw rejectWithValue('No API Token Found');
    }

    const sessionid = uuidv4();
    const request = {
      user_session_id: sessionid,
      source_module: SOURCE,
      email_id: email,
      first_name: firstName,
      last_name: lastName,
      user_name: username,
      unique_user_id: username,
      force_reload: 0,
    };
    const requestTimer = new PerfTimer();
    const url = readEnvVariable('FORCE_LOGOUT_API');
    try {
      await (
        await fetch(url, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: authToken,
          },
          body: JSON.stringify(request),
        })
      ).json();

      dispatch(
        sendNetworkCallLogs({
          url,
          duration: requestTimer.stop(),
          message: 'api request succeeded',
          via: 'fetch',
        })
      );

      return sessionid;
    } catch (error) {
      logger.error({
        message: 'HTTP error: Failed to create new user session',
        error,
      });

      dispatch(
        sendNetworkCallLogs({
          url,
          duration: requestTimer.stop(),
          message: 'api request failed',
          via: 'fetch',
        })
      );

      return rejectWithValue(error);
    }
  }
);

export const deleteUserSession = createAsyncThunk(
  'user/deleteUserSession',
  async (_, { dispatch, rejectWithValue }) => {
    const authToken = getAuthToken();
    if (!authToken) {
      throw rejectWithValue('No API Token Found');
    }

    const sessionid = getSessionID();
    const requestTimer = new PerfTimer();
    const url = `${readEnvVariable('FORCE_LOGOUT_API')}/${sessionid}`;
    try {
      await (
        await fetch(url, {
          method: 'DELETE',
          headers: {
            'Content-Type': 'application/json',
            Authorization: authToken,
          },
        })
      ).json();

      dispatch(
        sendNetworkCallLogs({
          url,
          duration: requestTimer.stop(),
          message: 'api request succeeded',
          via: 'fetch',
        })
      );

      return sessionid;
    } catch (error) {
      logger.error({
        message: 'HTTP error: Failed to delete user session',
        error,
      });

      dispatch(
        sendNetworkCallLogs({
          url,
          duration: requestTimer.stop(),
          message: 'api request failed',
          via: 'fetch',
        })
      );

      return rejectWithValue(error);
    }
  }
);

export const updateUserSession = createAsyncThunk(
  'user/updateUserSession',
  async (restaurantCode: string, { dispatch, getState, rejectWithValue }) => {
    const authToken = getAuthToken();
    if (!authToken) {
      throw rejectWithValue('No API Token Found');
    }

    const sessionid = getSessionID();
    const request = {
      user_session_id: sessionid,
      source_module: SOURCE,
      restaurant_code: restaurantCode,
      unique_user_id: getState().user.userProfile?.username,
      force_reload: 0,
    };
    const requestTimer = new PerfTimer();
    const url = `${readEnvVariable('FORCE_LOGOUT_API')}/${sessionid}`;
    try {
      await (
        await fetch(url, {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
            Authorization: authToken,
          },
          body: JSON.stringify(request),
        })
      ).json();

      dispatch(
        sendNetworkCallLogs({
          url,
          duration: requestTimer.stop(),
          message: 'api request succeeded',
          via: 'fetch',
        })
      );

      return sessionid;
    } catch (error) {
      logger.error({
        message: 'HTTP error: Failed to update user session',
        error,
      });

      dispatch(
        sendNetworkCallLogs({
          url,
          duration: requestTimer.stop(),
          message: 'api request failed',
          via: 'fetch',
        })
      );

      return rejectWithValue(error);
    }
  }
);

export const getUserSession = createAsyncThunk(
  'user/getUserSession',
  async (restaurantCode, { dispatch, rejectWithValue }) => {
    const authToken = getAuthToken();
    if (!authToken) {
      throw rejectWithValue('No API Token Found');
    }
    const requestTimer = new PerfTimer();
    const url = readEnvVariable('FORCE_LOGOUT_API');
    try {
      const result = await (
        await fetch(
          `${url}?source=HITL&restaurant_code=${restaurantCode}&active=1`,
          {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              Authorization: authToken,
            },
          }
        )
      ).json();

      dispatch(
        sendNetworkCallLogs({
          url,
          duration: requestTimer.stop(),
          message: 'api request succeeded',
          via: 'fetch',
        })
      );

      return result;
    } catch (error) {
      logger.error({
        message: 'HTTP error: Failed to get user session',
        error,
      });

      dispatch(
        sendNetworkCallLogs({
          url,
          duration: requestTimer.stop(),
          message: 'api request failed',
          via: 'fetch',
        })
      );

      return rejectWithValue(error);
    }
  }
);

export const forceLogOutUserSession = createAsyncThunk(
  'user/forceLogOutUserSession',
  async (
    {
      restaurantCode,
      activeSessions,
    }: { restaurantCode: string; activeSessions: any[] },
    { dispatch, rejectWithValue }
  ) => {
    const authToken = getAuthToken();
    if (!authToken) {
      throw rejectWithValue('No API Token Found');
    }
    const url = readEnvVariable('FORCE_LOGOUT_API');
    const requestTimer = new PerfTimer({ autoStart: false });
    try {
      for (let session of activeSessions) {
        const request = {
          user_session_id: session.user_session_id,
          force_reload: 1,
          source_module: SOURCE,
          restaurant_code: restaurantCode,
          unique_user_id: session.unique_user_id,
        };
        requestTimer.start();
        const result = await (
          await fetch(url, {
            method: 'PUT',
            headers: {
              'Content-Type': 'application/json',
              Authorization: authToken,
            },
            body: JSON.stringify(request),
          })
        ).json();

        logger.error({
          message: `Doing force logout for user session: ${session.user_session_id}`,
          moreInfo: result,
        });

        dispatch(
          sendNetworkCallLogs({
            url,
            duration: requestTimer.stop(),
            message: 'api request succeeded',
            via: 'fetch',
          })
        );
      }
      return activeSessions;
    } catch (error) {
      logger.error({
        message: 'HTTP error: Failed to do force logout of user session',
        error,
      });

      if (requestTimer.startedAt) {
        dispatch(
          sendNetworkCallLogs({
            url,
            duration: requestTimer.stop(),
            message: 'api request failed',
            via: 'fetch',
          })
        );
      }

      return rejectWithValue(error);
    }
  }
);

export const updateHeartBeatSession = createAsyncThunk(
  'user/updateHeartBeatSession',
  async (restaurantCode: string, { dispatch, getState, rejectWithValue }) => {
    const authToken = getAuthToken();
    if (!authToken) {
      throw rejectWithValue('No API Token Found');
    }

    const sessionid = getSessionID();
    const request = {
      user_session_id: sessionid,
      source_module: SOURCE,
      restaurant_code: restaurantCode,
      unique_user_id: getState().user.userProfile?.username,
      active: 1,
    };
    const requestTimer = new PerfTimer();
    const url = `${readEnvVariable('FORCE_LOGOUT_API')}/${sessionid}`;
    try {
      await (
        await fetch(url, {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
            Authorization: authToken,
          },
          body: JSON.stringify(request),
        })
      ).json();

      dispatch(
        sendNetworkCallLogs({
          url,
          duration: requestTimer.stop(),
          message: 'api request succeeded',
          via: 'fetch',
        })
      );

      return sessionid;
    } catch (error) {
      logger.error({
        message: 'HTTP error: Failed to update heartbeat user session',
        error,
      });

      dispatch(
        sendNetworkCallLogs({
          url,
          duration: requestTimer.stop(),
          message: 'api request failed',
          via: 'fetch',
        })
      );

      return rejectWithValue(error);
    }
  }
);

export const fetchGroupIdMapping = createAsyncThunk(
  'user/fetchGroupIdMapping',
  async (restaurantCode: string, { dispatch, rejectWithValue, getState }) => {
    const {
      config: { PRP_API },
    } = getState();
    const sdk = getGraphQLClient(PRP_API);
    const requestTimer = new PerfTimer();
    try {
      const data =
        ((await sdk.groupIdMapping({
          // hard-coding the restaurant code as we have only one restaurant with this requirement.
          restaurantCode,
        })) as any as GroupIdMappingQuery) || {};
      if (data) {
        const { queryCKEMenu = '{}' } = data;
        inMemoryGroupIdMapping.setGroupIdMappingList(
          restaurantCode,
          queryCKEMenu ? JSON.parse(queryCKEMenu) : {}
        );
      }

      dispatch(
        sendNetworkCallLogs({
          url: PRP_API,
          graphQLOperation: 'groupIdMapping',
          duration: requestTimer.stop(),
          message: 'api request succeeded',
          via: 'graphql_sdk',
        })
      );
    } catch (error: any) {
      logger.error({
        message: 'GraphQL error: Failed to get group id mapping',
        error: error?.response?.errors,
      });

      dispatch(
        sendNetworkCallLogs({
          url: PRP_API,
          graphQLOperation: 'groupIdMapping',
          duration: requestTimer.stop(),
          message: 'api request failed',
          via: 'graphql_sdk',
        })
      );

      return rejectWithValue(error?.response?.errors);
    }
  }
);

const userSlice = createSlice({
  name: 'user',
  initialState: initialUserState,
  reducers: {
    logout: (state) => {
      state.userProfile = null;
      state.isLoggedIn = false;
      state.token = null;
      setAuthToken(null);
      saveUserState(state);
      logger.clearLogs();
    },
    authCheckSuccess: (state, action) => {
      if (state.userProfile) {
        state.token = action.payload.authorizationToken;
        state.userProfile.authorizationToken =
          action.payload.authorizationToken;
        saveUserState(state);
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(login.fulfilled, (state, action) => {
      state.userProfile = {
        ...action.payload,
        id: action.payload.id + '',
      };
      state.isLoggedIn = true;
      state.didAttemptAuthCheck = true;
      saveUserState(state);
      logger.clearLogs();
    });
    builder.addCase(initialAuthCheck.fulfilled, (state, action) => {
      state.userProfile = {
        ...action.payload,
        id: action.payload.id + '',
      };
      state.token = action.payload.authorizationToken;
      state.isLoggedIn = true;
      state.didAttemptAuthCheck = true;
      saveUserState(state);
    });
    builder.addCase(initialAuthCheck.rejected, (state, action) => {
      state.isLoggedIn = false;
      state.didAttemptAuthCheck = true;
    });
    builder.addCase(createUserSession.fulfilled, (state, action) => {
      saveSessionID(action.payload);
    });
  },
});

export const userActions = userSlice.actions;

export default userSlice.reducer;
