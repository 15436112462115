import { styled, Theme, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';
import { Image } from '../components/Layout/Image';
import prestoLogo from '../images/presto_logo_white.png';
import Colors from '../utils/color';

const useStyles = makeStyles<Theme>(({ spacing }) => ({
  forgotPasswordPageClass: {
    height: '100vh',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    color: Colors.white,
    backgroundColor: Colors.daintree,
  },
  prestoLogoClass: {
    marginBottom: spacing(4),
    '@media (max-height: 600px)': {
      marginBottom: 0,
    },
  },
}));

const StyledLink = styled('a')(() => ({
  color: '#0672CB',
  textDecoration: 'none',
}));

const ForgotPasswordPage = () => {
  const { forgotPasswordPageClass, prestoLogoClass } = useStyles();

  return (
    <div data-testid="forgot-password-page" className={forgotPasswordPageClass}>
      <Image className={prestoLogoClass} url={prestoLogo} alt="Presto Logo" />
      <Typography variant="h4" gutterBottom>
        Something went wrong.
      </Typography>
      <Typography>
        {'Please contact '}
        <StyledLink href="mailto:support@presto.com">support</StyledLink>
      </Typography>
    </div>
  );
};

export default ForgotPasswordPage;
