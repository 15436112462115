import { Box } from '@mui/material';
import React, { memo } from 'react';
import { CartItem, CartModifierGroup } from '../../../utils/cart';
import {
  checkItemInTree,
  ParsedMenuItem,
  ParsedModifierGroup,
  sortModGroups,
} from '../../../utils/menu';
import ModGroup from '../menu/ModGroup';

interface IRecursiveModGroup {
  cartItem: CartItem;
  modGroup: ParsedModifierGroup;
  onChange: (
    modGroup: ParsedModifierGroup
  ) => (event: React.ChangeEvent<HTMLInputElement>) => void;
  selectMenuItemModifier: (
    menuItem: ParsedMenuItem,
    modGroup: ParsedModifierGroup,
    pathToItem: string,
    selected: boolean
  ) => void;
  pathToItem: string;
}

const RecursiveModGroup = (props: IRecursiveModGroup) => {
  const { modGroup, cartItem, pathToItem } = props;

  const currentItem =
    (checkItemInTree({ cartItem, pathToItem }) as CartModifierGroup) || {};

  if (!currentItem?.selectedItems) return null;

  const renderedModGroupIds: Record<string, boolean> = {};
  // Singular selection, so the value is the first key in `selectedItems`
  return (
    <Box>
      {Object.values(currentItem?.selectedItems).map(({ id, itemId }) => {
        const modGroupChildren = sortModGroups(modGroup.menuItems[itemId]);
        const filteredModGroups = Object.values(modGroupChildren).filter(
          (mod) => !renderedModGroupIds[mod.name]
        );
        const key = [id, modGroup.id, 'sub_choices'].join('_');
        const updatedPathToItem = [pathToItem, id].join('__');
        if (filteredModGroups.length) {
          return (
            <Box key={key}>
              {filteredModGroups.map((modGroup) => {
                renderedModGroupIds[modGroup.name] = true;
                const modGroupProps = {
                  ...props,
                  modGroup,
                  pathToItem: [updatedPathToItem, modGroup.id].join('__'),
                };
                return <ModGroup key={modGroup.id} {...modGroupProps} />;
              })}
            </Box>
          );
        }

        return <></>;
      })}
    </Box>
  );
};

export default memo(RecursiveModGroup);
