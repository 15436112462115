import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';
import useApp from '../../hooks/useApp.hooks';
import MainContent from '../MainContent';
import RestaurantStaffDialog from '../RestaurantStaff';
import useActivityTracking from '../../hooks/useActivityTracking.hooks';
import useInformativeAlert from '../../hooks/useInformativeAlert.hooks';

const useStyles = makeStyles<Theme>(({ spacing }) => ({
  bodyClass: {
    height: `calc(100vh - ${spacing(8)})`,
  },
}));

const Body = () => {
  const { bodyClass } = useStyles();
  useApp();
  useActivityTracking();
  useInformativeAlert();

  return (
    <div data-testid="body" className={bodyClass}>
      <MainContent />
      <RestaurantStaffDialog />
    </div>
  );
};

export default Body;
