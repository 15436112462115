import { useEffect } from 'react';
import { useIdleTimer } from 'react-idle-timer';
import { useAppSelector } from '../app/hooks';
import useLogout from '../components/Login/useLogout';
import { IDLE_TIME } from '../constants';
import { selectIsLoggedIn } from '../selectors/user';

const IDEAL_TIME_OUT_MESSAGE = 'Session Timeout, Please re login';

const useAppIdleTimer = () => {
  const { logout } = useLogout();
  const isLoggedIn = useAppSelector(selectIsLoggedIn);
  const onIdle = () => logout(IDEAL_TIME_OUT_MESSAGE);
  const { start, pause } = useIdleTimer({
    timeout: IDLE_TIME,
    onIdle,
    startManually: true,
  });

  useEffect(() => {
    if (isLoggedIn) start();
    else pause();
  }, [isLoggedIn]);
};

export default useAppIdleTimer;
