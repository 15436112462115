import { Typography } from '@mui/material';
import { FC, default as React, useEffect } from 'react';
import useSound from 'use-sound';
import { useAppSelector } from '../../app/hooks';
import { selectTaskRouterNotificationSound } from '../../redux/features/config/config.selector';
import { selectAssignedTaskId } from '../../redux/features/taskRouter/taskRouter.selector';
import taskAssignmentSound from '../../sounds/task_assign.mp3';

const TaskRouterAgentAssignment: FC = () => {
  const [play] = useSound(taskAssignmentSound, { interrupt: true });
  const canPlayNotificationSound = useAppSelector(
    selectTaskRouterNotificationSound
  );
  const taskAssignedId = useAppSelector(selectAssignedTaskId);

  useEffect(() => {
    if (canPlayNotificationSound && taskAssignedId) {
      play();
    }
  }, [taskAssignedId, canPlayNotificationSound, play]);

  return (
    <div data-testid="tr-agent-assignment">
      <Typography variant="h4" gutterBottom>
        Get ready
      </Typography>
      <Typography>New tasks will be offered to you shortly</Typography>
    </div>
  );
};

export default TaskRouterAgentAssignment;
