import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../../../app/store';

const selectSessionBoundary = (state: RootState) => state.sessionBoundary;
const selectSessionStatus = createSelector(
  selectSessionBoundary,
  (state) => state.sessionStatus
);
const selectSessionStartReason = createSelector(
  selectSessionBoundary,
  (state) => state.sessionStartReason
);
const selectSessionEndReason = createSelector(
  selectSessionBoundary,
  (state) => state.sessionEndReason
);
const selectBufferedSessionIds = createSelector(
  selectSessionBoundary,
  (state) => state.bufferedSessionIds
);

const selectSessionId = createSelector(
  selectSessionBoundary,
  (state) => state.sessionId
);

const selectFinalOrderSubmitted = createSelector(
  selectSessionBoundary,
  (state) => state.finalOrderSubmitted
);

const selectOrderMessageData = createSelector(
  selectSessionBoundary,
  (state) => state.orderMessageData
);

export {
  selectBufferedSessionIds,
  selectSessionEndReason,
  selectSessionId,
  selectSessionStartReason,
  selectSessionStatus,
  selectFinalOrderSubmitted,
  selectOrderMessageData
};
