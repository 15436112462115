import { Button, Grid, Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  useAppDispatch,
  useAppSelector,
  useShallowSelector,
} from '../../app/hooks';
import useAgentInterception from '../../hooks/agentInterception/useAgentInterception.hooks';
import { dialogActions } from '../../reducers/dialogSlice';
import {
  AgentInterceptionType,
  RestaurantStaffInterventionStatus,
  StaffInterceptionType,
  messagingActions,
} from '../../reducers/messagingSlice';
import { orderActions } from '../../reducers/orderSlice';
import { OrderStatus } from '../../reducers/orderSlice.constants';
import { selectCartItems, selectCartItemsQuantity } from '../../selectors/cart';
import { selectRestaurantStaffIntervention } from '../../selectors/message';
import {
  selectCurrentTransactionCoupons,
  selectOrderValues,
} from '../../selectors/order';
import { getMessageWithTotal } from '../../utils/TTSMessage';
import { activeCartSelector, getCartItemProperties } from '../../utils/cart';
import Colors from '../../utils/color';
import {
  INTENT_CARDS,
  IntentCard,
  VISIBILITY_FACTOR,
  BRANDS_REQUIRE_LONG_MESSAGE_WITH_TOTAL,
} from '../../utils/constants';
import { restaurantInfoSelector } from '../../utils/restaurants';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    background: Colors.daintree,
    padding: '10px',
  },
  card: {
    color: Colors.daintree,
    height: '48px',
    textAlign: 'center',
    textTransform: 'uppercase',
    fontWeight: 'bold !important',
    fontSize: '12px !important',
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    lineHeight: `${theme.spacing(2.25)} !important`,
  },
}));

interface OwnProps {
  sendMessage: (message: string, metadata?: any) => void;
  sendMetric: (message: string) => void;
}

export default function IntentsSection(props: OwnProps) {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const cartActive = useSelector(activeCartSelector);
  const currentTransactionId = useAppSelector(
    (state) => state.order.currentTransactionId
  );
  const currentTransactionCoupons = useAppSelector(
    selectCurrentTransactionCoupons
  );
  const currentTransactionCouponStatus = Object.values(
    currentTransactionCoupons
  );
  const allCouponApplied = useMemo(() => {
    return currentTransactionCouponStatus.every(
      (status) => status === OrderStatus.completed
    );
  }, [currentTransactionCouponStatus]);

  const transactions = useAppSelector((state) => state.order.transactions);
  const ITEM_BY_ITEM = useAppSelector((state) => state.config.ITEM_BY_ITEM);
  const completeClickCount = useAppSelector(
    (state) => state.order.completeClickCount
  );
  const { total } = useShallowSelector(selectOrderValues);
  const restaurantStaffIntervention = useSelector(
    selectRestaurantStaffIntervention
  );

  const selectedRestaurant = useSelector(restaurantInfoSelector);
  const [downTime, setDownTime] = useState(0);
  const numOfIntents = INTENT_CARDS.length;
  const intentsStatus = useAppSelector((state) => state.dialog.intentsStatus);
  const DISABLE_INTENT_INTERVAL = useAppSelector(
    (state) => state.config.DISABLE_INTENT_INTERVAL
  );

  const cartItemsQuantity = useAppSelector(selectCartItemsQuantity);
  const cartItems = useAppSelector(selectCartItems);
  const { sendAgentInterception } = useAgentInterception();

  useEffect(() => {
    dispatch(
      dialogActions.initIntentsStatus(new Array(numOfIntents).fill(true))
    );
  }, []);

  const onIntentDown = () => {
    setDownTime(new Date().valueOf());
  };

  const onIntentUp = (intent: IntentCard, idx: number) => {
    const timePressed = Math.floor((new Date().valueOf() - downTime) / 100);
    setDownTime(0);
    onIntentsClick(
      intent.title,
      intent.messages,
      timePressed,
      intent.intent,
      idx
    );
  };

  const setIntentsStatus = (idx: number, status: boolean) => {
    dispatch(dialogActions.setIntentsStatus({ idx, status }));
  };

  const sendAgentInterventionEvent = () => {
    sendAgentInterception({
      data: {
        type: AgentInterceptionType.TTS_SENT,
      },
    });
  };

  const onIntentsClick = (
    title: string,
    messages: string[],
    cTimer: number,
    intent: string,
    idx: number
  ) => {
    setIntentsStatus(idx, false);
    setTimeout(() => {
      setIntentsStatus(idx, true);
    }, DISABLE_INTENT_INTERVAL);

    sendAgentInterventionEvent();
    props.sendMetric(title + ' intent clicked');

    if (
      title === 'Restaurant Staff' &&
      selectedRestaurant &&
      restaurantStaffIntervention === RestaurantStaffInterventionStatus.initial
    ) {
      dispatch(
        messagingActions.setRestaurantStaffIntervention(
          RestaurantStaffInterventionStatus.open
        )
      );
      dispatch(
        messagingActions.sendStaffInterception({
          data: {
            type: StaffInterceptionType.AGENT_REQUESTED_STAFF,
          },
        })
      );
    }

    if (title === 'Read Back') {
      // Send the order items as metadata for dt-bridge to always have the latest cart state even if it's not completed or canceled
      const orderItems = Object.values(cartItems).map((item) => {
        let groupId: string[] = [];

        const cartItem = getCartItemProperties(
          item,
          groupId,
          [],
          selectedRestaurant?.restaurantCode || '',
          cartItemsQuantity
        );
        return cartItem;
      });

      props.sendMessage('', { intent, timeDelta: cTimer, orderItems });
      return;
    }

    if (title === 'Complete') {
      dispatch(orderActions.increaseCompleteClickCount());
    }

    let message = messages[cTimer] || messages[messages.length - 1];

    if (['Total', 'Complete'].includes(title)) {
      let shouldPrintLongTotalMessage = false;
      const restaurantCode = selectedRestaurant?.restaurantCode || '';
      if (restaurantCode) {
        for (
          let i = 0;
          i < BRANDS_REQUIRE_LONG_MESSAGE_WITH_TOTAL.length;
          i++
        ) {
          const brandKeyword = BRANDS_REQUIRE_LONG_MESSAGE_WITH_TOTAL[i];
          if (restaurantCode.includes(brandKeyword)) {
            shouldPrintLongTotalMessage = true;
            break;
          }
        }
      }

      message = getMessageWithTotal({
        message,
        ITEM_BY_ITEM,
        currentTransactionId,
        transactions,
        completeClickCount,
        total,
        allCouponApplied,
        title,
        shouldPrintLongTotalMessage,
      });
    }
    message = message.replaceAll(
      '{RESTAURANT_NAME}',
      selectedRestaurant?.restaurantName || ''
    );
    props.sendMessage(message, { intent, timeDelta: cTimer });
  };

  const intents = INTENT_CARDS.filter((intent) => {
    if (intent.visibility !== undefined) {
      switch (intent.visibility) {
        case VISIBILITY_FACTOR.CART_ACTIVE:
          return cartActive;
        case VISIBILITY_FACTOR.CART_EMPTY:
          return !cartActive;
        default:
          return true;
      }
    } else {
      return true;
    }
  });

  return (
    <div id="intentsSection" className={classes.container}>
      <Grid container spacing={1}>
        {intents.map((intentCard, idx) => (
          <Grid key={idx} item xs={intentCard.cardSize}>
            <Button
              key={`intent-${idx}`}
              onMouseDown={() => onIntentDown()}
              onMouseUp={() => onIntentUp(intentCard, idx)}
              className={classes.card}
              style={{
                backgroundColor: !intentsStatus[idx]
                  ? Colors.alto2
                  : intentCard.color,
                color: intentCard.textColor,
              }}
              disabled={!intentsStatus[idx]}
            >
              {intentCard.title}
            </Button>
          </Grid>
        ))}
      </Grid>
    </div>
  );
}
