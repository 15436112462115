enum SessionStatus {
  start = 'start',
  end = 'end',
}
enum SessionStartReasons {
  carEnter = 'car_enter',
  taskAssigned = 'task_assigned',
}

enum SessionEndReasons {
  cancelOrder = 'cancel_order', // 1.cancel order button is clicked 2. restaurant staff intervention
  finalOrderSubmitted = 'final_order_submitted', // 1. finish order button is clicked 2. check event with final flag true
  carExit = 'car_exit',
  cartEmpty = 'cart_empty',
  killSwitchClicked = 'kill_switch_clicked',
  noAgentActivity = 'no_agent_activity',
}

export { SessionEndReasons, SessionStartReasons, SessionStatus };
