import { GenericObjectType } from '../types';

const findObjectInObjectByKeyValue = (
  obj: GenericObjectType,
  keyName: string,
  keyValue: string
) => {
  return Object.values(obj).find(
    (innerObj: GenericObjectType) => innerObj[keyName] === keyValue
  );
};

export const readEnvVariable = (key: string) =>
  process.env[`REACT_APP_${key}`] || '';

export { findObjectInObjectByKeyValue };

export const snakeToCamelCase = (str: string): string =>
  str.replace(/[^a-zA-Z0-9]+(.)/g, (m: string, letter: string): string =>
    letter.toUpperCase()
  );
