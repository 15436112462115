import { Button } from '@mui/material';
import React, { FC, useCallback } from 'react';
import { useAppSelector } from '../../app/hooks';
import { selectFeatureFlagTaskRouter } from '../../redux/features/config/config.selector';
import { AgentEvents } from '../../redux/features/taskRouter/taskRouter.constants';
import { removeTaskRouterInfoFromLS } from '../../utils/local-storage';
import ConfirmDialog from '../common/ConfirmDialog';
import useLogout from './useLogout';

interface ILogoutProps {
  sendAgentEvent?: Function;
}

const Logout: FC<ILogoutProps> = ({ sendAgentEvent }) => {
  const [showConfirmModal, setShowConfirmModal] = React.useState(false);
  const { logout } = useLogout();
  const isTaskRouterOn = useAppSelector(selectFeatureFlagTaskRouter);

  const handleLogout = useCallback(() => {
    if (isTaskRouterOn) {
      sendAgentEvent?.(AgentEvents.logout);
      removeTaskRouterInfoFromLS();
    }
    logout();
  }, []);

  return (
    <>
      {showConfirmModal && (
        <ConfirmDialog
          content="Do you want to log out from HITL?"
          open={showConfirmModal}
          onConfirm={handleLogout}
          onClose={() => setShowConfirmModal(false)}
        />
      )}
      <div>
        <Button
          variant="contained"
          color="secondary"
          onClick={() => {
            setShowConfirmModal(true);
          }}
        >
          Log out
        </Button>
      </div>
    </>
  );
};

export default Logout;
