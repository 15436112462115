enum TransitionTypes {
  slideOut = 'slide-out',
  fadeOut = 'fade-out',
}

enum Symbols {
  plus = '+',
  minus = '-',
  star = '*',
  caret = '^',
}

enum MenuStages {
  PLAYGROUND = 'PLAYGROUND',
  PRELIVE = 'PRELIVE',
  LIVE = 'LIVE',
}

enum MenuStagesLabel {
  PLAYGROUND = 'Draft',
  LIVE = 'Live',
  PRELIVE = '',
}

enum NodeEnv {
  production = 'PRODUCTION',
  staging = 'STAGING',
  development = 'DEVELOPMENT',
  sandbox = 'SANDBOX',
  test = 'TEST',
}

enum Routes {
  taskRouter = 'task-router',
}

enum LogLevel {
  log = 'log',
  debug = 'debug',
  info = 'info',
  warn = 'warn',
  error = 'error',
}

enum ArrowKeys {
  arrowUp = 'ArrowUp',
  arrowLeft = 'ArrowLeft',
  arrowRight = 'ArrowRight',
  arrowDown = 'ArrowDown',
}

export {
  LogLevel,
  MenuStages,
  MenuStagesLabel,
  NodeEnv,
  Routes,
  Symbols,
  TransitionTypes,
  ArrowKeys,
};
