import React from 'react';
import { createBrowserRouter } from 'react-router-dom';
import App from '../App';
import Root from '../Root';
import { Routes } from '../constants/enums';
import ErrorPage from './ErrorPage';
import ForgotPasswordPage from './ForgotPasswordPage';
import LandingPage from './LandingPage';
import LoginPage from './LoginPage';
import ProtectedRoute from './ProtectedRoute';
import RestaurantPage from './RestaurantPage';
import TaskRouterPage from './TaskRouterPage';
import UnauthorizedPage from './UnauthorizedPage';

const router = createBrowserRouter([
  {
    path: '/',
    element: <Root />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: '/',
        element: (
          <ProtectedRoute>
            <App />
          </ProtectedRoute>
        ),
        children: [
          {
            path: '/',
            element: <LandingPage />,
          },
          {
            path: Routes.taskRouter,
            element: <TaskRouterPage />,
          },
          {
            path: ':restaurantId',
            element: <RestaurantPage />,
          },
        ],
      },
      {
        path: 'login',
        element: <LoginPage />,
      },
      {
        path: 'forgot-password',
        element: <ForgotPasswordPage />,
      },
      {
        path: 'unauthorized',
        element: <UnauthorizedPage />,
      },
    ],
  },
]);

export default router;
