import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useAppSelector } from '../../app/hooks';
import {
  AGENT_INTERCEPTION_CONFIRM_CONTENT,
  MAX_CART_ITEM_QUANTITY,
} from '../../constants';
import useUnsupervisedAI from '../../hooks/unsupervisedAI/useUnsupervisedAI.hooks';
import { clearCart } from '../../reducers/cartSlice';
import { addHypothesisItemToCart } from '../../reducers/menuSlice';
import { AIStatusColors } from '../../redux/features/ai/ai.constants';
import {
  selectAIStatus,
  selectIsAIAutoMode,
  selectIsAIEscalation,
} from '../../redux/features/ai/ai.selector';
import { selectFinalOrderSubmitted } from '../../redux/features/sessionBoundary/sessionBoundary.selectors';
import { selectHypothesisFrame } from '../../selectors/message';
import Colors from '../../utils/color';
import ConfirmDialog from '../common/ConfirmDialog';

interface IAIStatusBarStyleProps {
  isAIAutoMode: boolean;
  isAIEscalation: boolean;
  aiStatusColor: AIStatusColors;
}

interface IAgentInterceptionConfirmDialog {
  open: boolean;
  confirm: boolean;
}

const getStatusBarColor = (aiStatusColor: AIStatusColors) => {
  switch (aiStatusColor) {
    case AIStatusColors.green:
      return Colors.emerald;
    case AIStatusColors.yellow:
      return Colors.gold;
    case AIStatusColors.red:
      return Colors.bittersweet;
    default:
      return Colors.emerald;
  }
};

const useStyles = makeStyles<Theme, IAIStatusBarStyleProps>(({ spacing }) => ({
  aiStatusBarClass: {
    width: '100%',
    height: '48px',
    backgroundColor: ({ isAIEscalation, aiStatusColor }) =>
      isAIEscalation ? Colors.red : getStatusBarColor(aiStatusColor),
    display: 'flex',
    alignItems: 'center',
    padding: spacing(1),
    overflow: `auto hidden`,
  },
  emptyBarClass: {
    paddingLeft: spacing(3),
    fontSize: '0.75rem',
  },
  boldFontClass: {
    fontWeight: 'bold',
  },
  appOverlayClass: {
    position: 'fixed',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    display: 'block',
    background: `none repeat scroll 0 0 rgba(255,255,255,0.5)`,
    'z-index': '999999',
    height: '100%',
    width: '100%',
  },
  appOverlayMessageClass: {
    fontSize: '1.25rem',
    margin: '0 auto',
    width: 'fit-content',
    position: 'relative',
    'background-color': '#0288d1',
    display: 'flex',
    padding: '6px 16px',
    color: '#fff',
    'border-radius': '4px',
    top: '20%',
  },
}));

const AIStatusBar: FC = (props) => {
  const isAIAutoMode = useSelector(selectIsAIAutoMode);
  const isAIEscalation = useSelector(selectIsAIEscalation);
  const aiStatus = useSelector(selectAIStatus);
  const {
    aiStatusBarClass,
    emptyBarClass,
    boldFontClass,
    appOverlayClass,
    appOverlayMessageClass,
  } = useStyles({
    isAIAutoMode,
    isAIEscalation,
    aiStatusColor: aiStatus.status,
  });
  const dispatch = useDispatch();
  const hypothesisFrame = useSelector(selectHypothesisFrame);
  const confirmAgentInterception =
    isAIAutoMode && aiStatus.status === AIStatusColors.green && !isAIEscalation;
  const [agentInterceptionConfirmDialog, setAgentInterceptionConfirmDialog] =
    useState<IAgentInterceptionConfirmDialog>({ open: false, confirm: false });
  const { showUnsupervisedAIOverlay } = useUnsupervisedAI();

  const isFinalOrderSubmitted = useAppSelector(selectFinalOrderSubmitted);

  useEffect(() => {
    if (
      !showUnsupervisedAIOverlay &&
      confirmAgentInterception &&
      !agentInterceptionConfirmDialog.confirm
    ) {
      const handleKeyboardMouseEvent = () => {
        setAgentInterceptionConfirmDialog((prevState) => ({
          ...prevState,
          open: true,
        }));
      };
      document.addEventListener('keydown', handleKeyboardMouseEvent, true);
      document.addEventListener('mousedown', handleKeyboardMouseEvent, true);
      return () => {
        document.removeEventListener('keydown', handleKeyboardMouseEvent, true);
        document.removeEventListener(
          'mousedown',
          handleKeyboardMouseEvent,
          true
        );
      };
    }
  }, [
    showUnsupervisedAIOverlay,
    confirmAgentInterception,
    agentInterceptionConfirmDialog,
  ]);

  useEffect(() => {
    if (!isFinalOrderSubmitted) {
      hypothesisFrame?.data?.hypotheses?.forEach((hypothesis) => {
        const orderItems = hypothesis.order_items;
        if (isAIAutoMode) dispatch(clearCart()); // Clear the cart and build a new one to avoid the comparsion between cart items and order items when hypothesis evolves(update quantity or modifiers)

        orderItems.forEach((orderItem) => {
          const { quantity } = orderItem;
          const transformedQuantity = Math.floor(quantity); //Added for temporary purpose only as quantity will never be in decimals
          if (isAIAutoMode) {
            dispatch(
              addHypothesisItemToCart({
                ...orderItem,
                quantity: Math.min(transformedQuantity, MAX_CART_ITEM_QUANTITY),
              })
            );
          }
        });
      });
    }
  }, [hypothesisFrame, isFinalOrderSubmitted]);

  return (
    <div data-testid="ai-status-bar" className={aiStatusBarClass}>
      {showUnsupervisedAIOverlay && (
        <div data-testid="app-overlay" className={appOverlayClass}>
          <div className={appOverlayMessageClass}>
            This is view-only mode while AI is currently processing order.
            <br></br>
            Please observe and wait till AI turns yellow/red to take over.
          </div>
        </div>
      )}
      <div className={emptyBarClass}>
        <span className={boldFontClass}>AUTO AI: </span>
        {isAIEscalation
          ? 'ALERT, Agent take over session now. AI cannot continue with order.'
          : aiStatus.message}
      </div>
      <ConfirmDialog
        open={agentInterceptionConfirmDialog.open}
        content={AGENT_INTERCEPTION_CONFIRM_CONTENT}
        onClose={() =>
          setAgentInterceptionConfirmDialog((prevState) => ({
            ...prevState,
            open: false,
          }))
        }
        onConfirm={() =>
          setAgentInterceptionConfirmDialog({ open: false, confirm: true })
        }
        closeBtnText={'No'}
        confirmBtnText={'Yes'}
      />
    </div>
  );
};

export default AIStatusBar;
