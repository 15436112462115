import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../../../app/store';

const selectConfig = (state: RootState) => state.config;
const selectNodeEnv = createSelector(selectConfig, (state) => state.NODE_ENV);
const selectFeatureFlagTaskRouter = createSelector(
  selectConfig,
  (state) => state.FEATUREFLAG_TASKROUTER
);
const selectTaskRouterWaitingTime = createSelector(
  selectConfig,
  (state) => state.TASKROUTER_WAITING_TIME
);
const selectFeatureFlagMenuVersion = createSelector(
  selectConfig,
  (state) => state.FEATURE_FLAG_MENU_VERSION
);
const selectTaskRouterNotificationSound = createSelector(
  selectConfig,
  (state) => state.TASKROUTER_NOTIFICATION_SOUND
);
const selectPagerdutyIntegrationKey = createSelector(
  selectConfig,
  (state) => state.PAGERDUTY_INTEGRATION_KEY
);
const selectSessionBufferAPI = createSelector(
  selectConfig,
  (state) => state.SESSION_BUFFER_API
);
const selectSessionBufferAPIAuthentication = createSelector(
  selectConfig,
  (state) => state.SESSION_BUFFER_API_AUTHENTICATION
);
const selectAuthURL = createSelector(selectConfig, (state) => state.AUTH_URL);
const selectTRAgentAutoAssignment = createSelector(
  selectConfig,
  (state) => state.TASK_ROUTER_AGENT_AUTO_ASSIGNMENT
);
const selectDisableIntentInterval = createSelector(
  selectConfig,
  (state) => state.DISABLE_INTENT_INTERVAL
);

const selectEventAPI = createSelector(selectConfig, (state) => state.EVENT_API);

const selectEventAPIAuth = createSelector(
  selectConfig,
  (state) => state.EVENT_API_BASIC_AUTH_PARAM
);

const selectFeatureFlagAudioWebsocket = createSelector(
  selectConfig,
  (state) => state.FEATURE_FLAG_AUDIO_BACKEND
);

export {
  selectAuthURL,
  selectConfig,
  selectFeatureFlagMenuVersion,
  selectFeatureFlagTaskRouter,
  selectNodeEnv,
  selectPagerdutyIntegrationKey,
  selectSessionBufferAPI,
  selectSessionBufferAPIAuthentication,
  selectTRAgentAutoAssignment,
  selectTaskRouterNotificationSound,
  selectTaskRouterWaitingTime,
  selectEventAPI,
  selectEventAPIAuth,
  selectDisableIntentInterval,
  selectFeatureFlagAudioWebsocket,
};
