const SLICE_NAME = 'aiSlice';

enum AIStatusColors {
  green = 'green',
  yellow = 'yellow',
  red = 'red',
}

const DEFAULT_STATUS_MESSAGE = 'OK, session is running smoothly';

export { SLICE_NAME, AIStatusColors, DEFAULT_STATUS_MESSAGE };
