import { v4 as uuidv4, v5 as uuidv5 } from 'uuid';

export class Agent {
  #id: string = uuidv4();

  getAgentId() {
    return this.#id;
  }

  setAgentId(username: string) {
    this.#id = uuidv5(username, uuidv5('presto.com', uuidv5.URL));
  }
}

export const agent = new Agent();
