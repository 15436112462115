import { IHealthStatusCheckState } from './healthStatusCheck.props';

const initialState: IHealthStatusCheckState = {
  generatedTime: '',
  healthStatusCheckContent: { heading: '', body: [] },
  checkStatus: null,
  isHealthy: false,
};

export { initialState };
