import { readEnvVariable } from './index';
import { UNLEASH_APP_NAME, UNLEASH_POLL_INTERVAL } from '../constants';

const unleashConfig = {
  url: readEnvVariable('UNLEASH_API_URL'), // Unleash API URL or the Unleash proxy's URL (https://<proxy-url>/proxy)
  clientKey: readEnvVariable('UNLEASH_API_TOKEN'), // A client-side API token OR one of your proxy's designated client keys (previously known as proxy secrets)
  refreshInterval: UNLEASH_POLL_INTERVAL, // How often (in seconds) the client should poll the proxy for updates
  appName: UNLEASH_APP_NAME, // The name of your application. It's only used for identifying your application
};

export default unleashConfig;
