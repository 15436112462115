import { useEffect } from 'react';
import { useAlert } from 'react-alert';
import { useAppSelector } from '../app/hooks';

/* Selectors */
import { selectSessionEndReason } from '../redux/features/sessionBoundary/sessionBoundary.selectors';
import { selectTaskStatusChanged } from '../redux/features/taskRouter/taskRouter.selector';
import { selectConfig } from '../redux/features/config/config.selector';
import { selectIsStaffIntervenedBeforeCarExit } from '../selectors/message';

/* Constants */
import AUTO_NAVIGATION_ALERT from '../constants/autoNavigationAlert';
import { SessionEndReasons } from '../redux/features/sessionBoundary/sessionBoundary.constants';
import { TaskStatuses } from '../redux/features/taskRouter/taskRouter.constants';

const useInformativeAlert = () => {
  const alert = useAlert();
  const config = useAppSelector(selectConfig);
  const sessionEnd = useAppSelector(selectSessionEndReason);
  const taskStatusChanged = useAppSelector(selectTaskStatusChanged);
  const isStaffIntervenedBeforeCarExit = useAppSelector(
    selectIsStaffIntervenedBeforeCarExit
  );

  useEffect(() => {
    if (taskStatusChanged) {
      const { event } = taskStatusChanged;
      /* Successful order alert */
      const successfulEndReasons: Array<SessionEndReasons> = [
        SessionEndReasons.carExit,
        SessionEndReasons.killSwitchClicked,
        SessionEndReasons.cartEmpty,
      ];
      if (
        event === TaskStatuses.completed &&
        successfulEndReasons.includes(sessionEnd as SessionEndReasons) &&
        !isStaffIntervenedBeforeCarExit
      ) {
        alert.show(AUTO_NAVIGATION_ALERT.SUCCESSFUL_ORDER.message, {
          type: AUTO_NAVIGATION_ALERT.SUCCESSFUL_ORDER.type,
          timeout: config.ALERT_TIMEOUT,
        });
      }

      /* Unsuccessful order alert for kill switch clicked without completing task */
      /* Unsuccessful order alert for staff intervention */
      const unsuccessfulEndReasons: Array<SessionEndReasons> = [
        SessionEndReasons.carExit,
        SessionEndReasons.cartEmpty,
      ];
      if (
        (event !== TaskStatuses.completed &&
          sessionEnd === SessionEndReasons.killSwitchClicked) ||
        (event === TaskStatuses.completed &&
          sessionEnd === SessionEndReasons.killSwitchClicked &&
          isStaffIntervenedBeforeCarExit) ||
        (event === TaskStatuses.loaded &&
          sessionEnd === SessionEndReasons.cartEmpty) ||
        (isStaffIntervenedBeforeCarExit &&
          sessionEnd === SessionEndReasons.carExit) ||
        (event === TaskStatuses.canceled &&
          unsuccessfulEndReasons.includes(sessionEnd as SessionEndReasons)) ||
        (event === TaskStatuses.agentInterception &&
          unsuccessfulEndReasons.includes(sessionEnd as SessionEndReasons))
      ) {
        alert.show(AUTO_NAVIGATION_ALERT.UNSUCCESSFUL_ORDER.message, {
          type: AUTO_NAVIGATION_ALERT.UNSUCCESSFUL_ORDER.type,
          timeout: config.ALERT_TIMEOUT,
        });
      }
    }
  }, [taskStatusChanged, sessionEnd]);

  useEffect(() => {
    /* Ghost car alert for invalid task */
    if (
      (taskStatusChanged?.event === TaskStatuses.loaded &&
        sessionEnd === SessionEndReasons.carExit) ||
      taskStatusChanged?.event === TaskStatuses.invalidAck ||
      (taskStatusChanged?.event === TaskStatuses.completed &&
        sessionEnd === SessionEndReasons.cartEmpty)
    ) {
      alert.show(AUTO_NAVIGATION_ALERT.GHOST_CAR.message, {
        type: AUTO_NAVIGATION_ALERT.GHOST_CAR.type,
        timeout: config.ALERT_TIMEOUT,
      });
    }
  }, [taskStatusChanged]);
};

export default useInformativeAlert;
