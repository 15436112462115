import moment from 'moment-timezone';
import { v4 as uuidv4 } from 'uuid';
import { readEnvVariable } from '.';
import packageJSON from '../../package.json';
import { LogLevel } from '../constants/enums';
import { postLogToNewRelic } from '../services';
import { SOURCE } from './constants';
import {
  getLoggedInUserEmail,
  getSessionID,
  getTaskRouterAgentId,
} from './local-storage';
import seqIdSingletonInstance from './loggerSequenceId';

let logs: string[] = [];

const serialize = (arg: any) => {
  switch (typeof arg) {
    case 'string':
      return arg;
    case 'boolean':
    case 'number':
      return arg.toString();
    default:
      return stringifyWithDepth(arg, 2, 0);
  }
};

// Courtesy of ChatGPT
/*
 * I need a function in typescript like JSON.stringify
 * but that only goes down to a depth of 2 and includes public properties.
 */
const stringifyWithDepth = (
  obj: any,
  depth: number = 2,
  currentDepth: number = 0
): string => {
  if (depth === currentDepth && typeof obj === 'object' && obj !== null) {
    return '{...}';
  } else if (typeof obj === 'object' && obj !== null) {
    const objCopy: any = Array.isArray(obj) ? [] : {};
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        objCopy[key] = stringifyWithDepth(obj[key], depth, currentDepth + 1);
      }
    }
    return JSON.stringify(objCopy);
  } else {
    return JSON.stringify(obj);
  }
};

const loggerFn =
  (level: LogLevel) =>
  (...args: any[]) => {
    const isLogAnObject = typeof args[0] !== 'string';

    //(1) Pushing logs for pagerduty ticket
    const logMessage = (
      isLogAnObject
        ? Object.entries(args[0]).map(([key, value]) => `${key}: ${value}`)
        : args
    )
      .map(serialize)
      .join(' ');
    const timestamp = moment()
      .tz('America/Los_Angeles')
      .format('yyyy-MM-DD hh:mm:ss z');
    const processedLog = `${timestamp} - ${level} - ${logMessage}`;
    logs.push(processedLog);

    //(2) Post the logs to newrelic
    const newRelicMessagePayload = {
      loglevel: level,
      module: SOURCE,
      applicationUrl: window.location.href,
      applicationVersion: packageJSON.version,
      environment: readEnvVariable('DEPLOY_ENV'),
      browser: window.navigator.userAgent,
      message: stringifyWithDepth({
        data: {
          seq: seqIdSingletonInstance.incrementAndGetSeqId(),
          id: uuidv4(),
          agentId: getTaskRouterAgentId(),
          userEmail: getLoggedInUserEmail(),
          userSessionId: getSessionID(),
          timestamp: new Date().toISOString(),
        },
        ...(isLogAnObject ? args[0] : { message: logMessage }),
      }),
    };
    postLogToNewRelic(newRelicMessagePayload);

    return console[level].apply(null, args);
  };

const logger = {
  debug: loggerFn(LogLevel.debug),
  info: loggerFn(LogLevel.info),
  warn: loggerFn(LogLevel.warn),
  error: loggerFn(LogLevel.error),
  log: loggerFn(LogLevel.log),
  getAllLogs: () => logs,
  clearLogs: () => {
    logs = [];
  },
};

export default logger;
