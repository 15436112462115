import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { FC, default as React, memo } from 'react';
import { useAppSelector } from '../../app/hooks';
import { AgentStatuses } from '../../redux/features/taskRouter/taskRouter.constants';
import { selectAgentStatus } from '../../redux/features/taskRouter/taskRouter.selector';
import TaskRouterAgentAssignment from '../TaskRouterAgentAssignment';
import TaskRouterAgentAvailable from '../TaskRouterAgentAvailable';
import TaskRouterAgentTraining from '../TaskRouterAgentTraining';
import TaskRouterAgentUnavailable from '../TaskRouterAgentUnavailable';

const useStyles = makeStyles<Theme>(({ spacing }) => ({
  taskRouterBodyClass: {
    height: `calc(100vh - ${spacing(8)})`,
    '& >div': {
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
    },
  },
}));

type TaskRouterBodyProps = {
  handleAgentAvailability: (agentStatus: AgentStatuses) => void;
  isTaskAssignmentMode: boolean;
  sendTaskMessage?: Function;
};

const TaskRouterBody: FC<TaskRouterBodyProps> = ({
  handleAgentAvailability,
  isTaskAssignmentMode,
  sendTaskMessage,
}) => {
  const { taskRouterBodyClass } = useStyles();
  const agentStatus = useAppSelector(selectAgentStatus);

  return (
    <div data-testid="task-router-body" className={taskRouterBodyClass}>
      {(() => {
        switch (agentStatus) {
          case AgentStatuses.available:
            if (isTaskAssignmentMode) return <TaskRouterAgentAssignment />;
            return (
              <TaskRouterAgentAvailable sendTaskMessage={sendTaskMessage} />
            );
          case AgentStatuses.unavailable:
            return (
              <TaskRouterAgentUnavailable
                handleAgentAvailability={handleAgentAvailability}
              />
            );
          case AgentStatuses.training:
            return (
              <TaskRouterAgentTraining
                handleAgentAvailability={handleAgentAvailability}
              />
            );
          default:
            return null;
        }
      })()}
    </div>
  );
};

export default memo(TaskRouterBody);
