import axios, { RawAxiosRequestHeaders } from 'axios';
import { RootStore } from '../app/store';
import { sendNetworkCallLogs } from '../reducers/messagingSlice';
import appContainer from './container';
import { PerfTimer } from './timer';

enum HTTPMethods {
  get = 'GET',
  post = 'POST',
  put = 'PUT',
  patch = 'PATCH',
  remove = 'DELETE',
}

interface IParams {
  url: string;
  data?: any;
  headers?: RawAxiosRequestHeaders;
  successCallback?: (data: any) => void;
  errorCallback?: (message: string, statusCode?: number) => void;
}

const DEFAULT_HEADERS = {
  Accept: 'application/json',
};

const apiCall = async (method: HTTPMethods, params: IParams) => {
  const { data, url, headers, successCallback, errorCallback } = params || {};
  const requestTimer = new PerfTimer();

  const store = appContainer.get<RootStore>('store')!;

  try {
    const response = await axios({
      headers: {
        ...DEFAULT_HEADERS,
        ...headers,
      },
      method,
      url,
      ...(method !== HTTPMethods.get && { data }),
    });

    store.dispatch(
      sendNetworkCallLogs({
        url,
        duration: requestTimer.stop(),
        message: 'api request succeeded',
        via: 'axios_api_wrapper',
      })
    );

    successCallback?.(response);

    return response;
  } catch (error) {
    const errorDetails = axios.isAxiosError(error)
      ? { message: error.message, status: error.status }
      : { message: 'An unexpected error occurred', status: 500 };

    store.dispatch(
      sendNetworkCallLogs({
        url,
        duration: requestTimer.stop(),
        message: `api request failed with error message: ${errorDetails.message}`,
        via: 'axios_api_wrapper',
      })
    );

    errorCallback?.(errorDetails.message, errorDetails.status);

    throw error;
  }
};

const apiCallWrapper = (method: HTTPMethods) => (params: IParams) =>
  apiCall(method, params);

const get = apiCallWrapper(HTTPMethods.get);
const post = apiCallWrapper(HTTPMethods.post);
const put = apiCallWrapper(HTTPMethods.put);
const patch = apiCallWrapper(HTTPMethods.patch);
const remove = apiCallWrapper(HTTPMethods.remove);

export { get, patch, post, put, remove };
