import {
  Alert,
  Button,
  LinearProgress,
  LinearProgressProps,
} from '@mui/material';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { messagingActions } from '../../reducers/messagingSlice';
import { CartItem } from '../../utils/cart';
import { getOrderErrorMessage } from '../../utils/errors';
import { OrderStatus } from '../../reducers/orderSlice.constants';
import { selectOrderError } from '../../selectors/order';

interface IOrderProgressBar {
  status?: OrderStatus;
  cartItem?: CartItem;
}

export default function OrderProgressBar({
  status,
  cartItem,
}: IOrderProgressBar) {
  const dispatch = useDispatch();
  const orderError = useSelector(selectOrderError);

  let progress = 0;
  let color: LinearProgressProps['color'] = undefined;
  switch (status) {
    case OrderStatus.sending:
      color = undefined;
      progress = 33;
      break;
    case OrderStatus.pending:
      color = undefined;
      progress = 66;
      break;
    case OrderStatus.completed:
      color = 'success';
      progress = 100;
      break;
    case OrderStatus.failed:
      color = 'error';
      progress = 100;
      break;
    default:
      color = undefined;
      progress = 0;
  }

  const sendMessage = (message: string) => {
    const payload = {
      data: { payload: message, status: 'final' },
    };
    dispatch(messagingActions.sendMessage(payload as any));
  };

  if (status === OrderStatus.failed && cartItem) {
    const errorMessage = orderError || 'An Unkown Error Has Occurred';
    const messageToCustomer = getOrderErrorMessage(errorMessage, cartItem);
    return (
      <Button
        onClick={() => sendMessage(messageToCustomer)}
        sx={{
          display: 'block',
          padding: '0px',
          width: '100%',
        }}
      >
        <Alert
          variant="filled"
          severity="error"
          sx={{
            '&:hover': {
              backgroundColor: '#FF3D74',
            },
          }}
        >
          {errorMessage}
        </Alert>
      </Button>
    );
  }

  return (
    <LinearProgress variant="determinate" color={color} value={progress} />
  );
}
