import {
  createAsyncThunk,
  createSlice,
  isAnyOf,
  PayloadAction,
} from '@reduxjs/toolkit';
import { v4 as uuidv4 } from 'uuid';
import { EventSources, EventTypes } from '../constants/event';
import { readEnvVariable } from '../utils';
import { CartItem, getCartItemProperties } from '../utils/cart';
import logger from '../utils/logger';
import { GenericMap } from '../utils/types';
import { sendOrderMetrics } from './cartSlice';
import { selectMenuVersion } from './menuSlice';
import {
  CheckTransmissionMessage,
  IMessageReceived,
  InfoTransmissionMessage,
  IStatusTransmissionMessage,
  messagingActions,
  sendOrder as sendOrderMessage,
} from './messagingSlice';
import { OrderStatus } from './orderSlice.constants';
import { IOrderTransmissionMessage, ISendOrderData } from './orderSlice.props';
import {
  extractOrderId,
  initialOrderState,
  processOrderStatusAndCheckResponses,
  setLastOrderItemsFailed,
} from './orderSlice.utils';
import { selectRestaurant, selectStage } from './restaurantSlice';

export const sendOrder = createAsyncThunk(
  'order/sendOrder',
  async (
    {
      validCartItems,
      isFinal,
    }: {
      validCartItems: GenericMap<CartItem>;
      isFinal?: boolean;
    },
    { dispatch, getState, rejectWithValue }
  ) => {
    const {
      config: { ITEM_BY_ITEM: isItemByItemEnabled },
      cart: { cartItemsQuantity: itemsQuantity, loyaltyCouponItem, coupons },
      restaurant,
      messages: { startFrame },
      ai: { isAIAutoMode },
    } = getState();

    if (isAIAutoMode) return; // Do not send order if in AI auto mode

    const validCartItemIds = Object.values(validCartItems).reduce(
      (acc: number[], { cartItemId }) => {
        if (cartItemId) acc.push(cartItemId);
        return acc;
      },
      []
    );

    dispatch(orderActions.startSendOrder(validCartItemIds));

    const orderId = extractOrderId(getState());
    dispatch(orderActions.setCurrentSession(orderId)); // The currentSessionId should in sync with sessionId which is sent in order event

    let restaurantCode = restaurant.selectedRestaurantCode || undefined;

    if (!restaurantCode) {
      restaurantCode = startFrame?.data.restaurant_code;
      if (!restaurantCode) {
        throw rejectWithValue('No Current restaurantCode selected!');
      }
    }

    const items = Object.values(validCartItems).map((item) => {
      let groupId: string[] = [];

      return getCartItemProperties(
        item,
        groupId,
        [],
        restaurantCode || '',
        itemsQuantity
      );
    });

    // This is tighter than it was, but this really should be atomic...
    dispatch(orderSlice.actions.incSeqId({}));
    const {
      order: { seqId: orderSeqId },
      sessionBoundary: { sessionId: sbSessionId },
      menu,
    } = getState();
    const seqId = orderSeqId;

    const orderData: ISendOrderData = {
      check_id: '-1', // TODO Do we send the same transaction_id here???
      store_id: restaurantCode,
      final: isFinal ? true : false,
      items,
      source: EventSources.prestoVoice,
      request_id: uuidv4(),
      session_id: orderId,
      seq_id: seqId,
      menu_version: menu?.selectedMenuVersion || '',
    };

    // Apply Loyalty Coupon Item
    if (loyaltyCouponItem && !loyaltyCouponItem.isApplied) {
      orderData.couponno = loyaltyCouponItem.couponno;
    }

    // Send coupon codes
    const couponIds = Object.keys(coupons);
    if (couponIds.length) {
      const couponObjList = Object.values(coupons);
      orderData.coupons = couponObjList;
      dispatch(startSendCoupon(couponIds));
      if (isFinal) {
        // Send coupon added metric in the final order
        const couponCodes = couponObjList.reduce((acc: string[], couponObj) => {
          acc.push(couponObj.code);
          return acc;
        }, []);
        const couponMetricPayload: Partial<InfoTransmissionMessage> = {
          data: {
            message: 'Coupon added',
            type: 'METRIC',
            code: 'COUPON_ADDED',
            metadata: { coupons: couponCodes },
          },
        };
        dispatch(messagingActions.sendInfo(couponMetricPayload as any));
      }
    }

    const payload: Partial<IOrderTransmissionMessage> = {
      data: {
        ...orderData,
        environment: readEnvVariable('DEPLOY_ENV'),
      },
    };

    if (
      Object.values(validCartItems).length &&
      (isFinal || isItemByItemEnabled)
    ) {
      logger.debug({
        restaurantCode,
        message: 'Sending order with this data',
        moreInfo: JSON.stringify(payload),
        sessionId: sbSessionId,
      });
      dispatch(sendOrderMessage(payload as any));
      dispatch(
        populateOrderRequests({
          requestId: orderData.request_id,
          cartItemIds: validCartItemIds,
          isFinal,
          couponIds,
        })
      );
      dispatch(sendOrderMetrics());
      if (isFinal) {
        dispatch(orderActions.finishOrderResetValues());
      }
    }
  }
);

const orderSlice = createSlice({
  name: 'order',
  initialState: initialOrderState,
  reducers: {
    startSendOrder: (state, action: PayloadAction<number[]>) => {
      for (let cartItemId of action.payload) {
        if (!state.currentTransactionItems[cartItemId]) {
          state.currentTransactionItems[cartItemId] = OrderStatus.sending;
        }
      }
    },
    populateOrderRequests: (
      state,
      { payload: { requestId, cartItemIds, isFinal, couponIds } }
    ) => {
      state.requestsById[requestId] = {
        id: requestId,
        cartItemIds,
        status: '',
        transactionId: '',
        orderStatusResponse: null,
        checkResponse: null,
        isFinal: isFinal ? true : false,
        couponIds,
      };
      state.requestsOrder.push(requestId);
    },
    resetSession: () => {
      return initialOrderState;
    },
    finishOrderResetValues: (state) => {
      state.currentSessionId = uuidv4(); //Whenever 'Cancel order' or 'Finish order' button is clicked, the next order which goes from HITL will have a new inner session id
      state.currentTransactionItems = {};
      state.seqId = 0;
      state.total = '';
      state.subtotal = '';
      state.tax = '';
      state.completeClickCount = {};
      state.isPosmonDisconnected = false;
      state.isPosmonReconnected = false;
      state.currentTransactionCoupons = {};
    },
    setCurrentSession: (state, action: PayloadAction<string>) => {
      state.currentSessionId = action.payload;
      logger.info('-currentSessionId in order state-', state.currentSessionId);
    },
    incSeqId: (state, action: any) => {
      state.seqId += 1;
    },
    setOrderError: (state, action: PayloadAction<string>) => {
      state.orderError = action.payload;
    },
    increaseCompleteClickCount: (state) => {
      if (
        state.currentTransactionId &&
        state.currentTransactionId in state.completeClickCount
      ) {
        state.completeClickCount[state.currentTransactionId] += 1;
      } else if (state.currentTransactionId) {
        // update count whever transaction id updates
        state.completeClickCount = {};
        state.completeClickCount[state.currentTransactionId] = 1;
      }
    },
    resetCurrentTransactionId: (state) => {
      state.currentTransactionId = null;
    },
    startSendCoupon: (state, action: PayloadAction<string[]>) => {
      action.payload.forEach((couponId) => {
        if (!state.currentTransactionCoupons[couponId]) {
          state.currentTransactionCoupons[couponId] = OrderStatus.sending;
        }
      });
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      messagingActions.messageReceived,
      (state, action: PayloadAction<IMessageReceived>) => {
        const { messages } = action.payload;
        messages.forEach((message) => {
          if (message.event === EventTypes.check) {
            const { data } = message as CheckTransmissionMessage;
            const { request_id: requestId } = data || {};

            if (
              state.requestsOrder.length &&
              state.requestsOrder[state.requestsOrder.length - 1] === requestId
            ) {
              // Always process the check event with the latest request id
              state.requestsById[requestId].checkResponse = message;
              processOrderStatusAndCheckResponses(state, requestId);
            }
          } else if (message.event === EventTypes.orderStatus) {
            const { data: { request_id: requestId = '' } = {} } =
              message as IStatusTransmissionMessage;

            if (state.requestsById[requestId]) {
              state.requestsById[requestId].orderStatusResponse = message;
              processOrderStatusAndCheckResponses(state, requestId);
            }
          } else if (message.event === EventTypes.connect) {
            // trigger send order
            state.isPosmonReconnected = true;

            state.isPosmonDisconnected = false;
          } else if (message.event === EventTypes.disconnect) {
            // display error message
            const posmonDisconnectMessage = 'POSMON is not connected';
            setLastOrderItemsFailed(state, posmonDisconnectMessage);

            // trigger health status check
            state.isPosmonDisconnected = true;

            state.isPosmonReconnected = false;
          } else if (message.event === EventTypes.order) {
            const { data } = message as IOrderTransmissionMessage;
            const { seq_id: innerSequenceId = 0 } = data || {};

            //Check event received for AI order: LLM or PNLU - next sequence to be greater than the AI order one
            state.seqId = innerSequenceId;
          }
        });
      }
    );
    builder.addMatcher(
      isAnyOf(
        selectRestaurant.fulfilled,
        selectStage.fulfilled,
        selectMenuVersion.fulfilled
      ),
      () => {
        return initialOrderState;
      }
    );
  },
});

export const orderActions = orderSlice.actions;
export const {
  startSendOrder,
  setCurrentSession,
  populateOrderRequests,
  setOrderError,
  finishOrderResetValues,
  resetCurrentTransactionId,
  startSendCoupon,
} = orderSlice.actions;

export default orderSlice.reducer;
