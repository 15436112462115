import * as React from 'react';
import { Box } from '@mui/system';
import Stack from '@mui/material/Stack';
import Slider from '@mui/material/Slider';
import VolumeDown from '@mui/icons-material/VolumeDown';
import VolumeUp from '@mui/icons-material/VolumeUp';
import { useAppSelector } from '../../app/hooks';
import { selectIsPlaying, selectAsrcPlayer } from '../../selectors/message';

export const VolumeSlider = () => {
  const [value, setValue] = React.useState(0);
  const isPlaying = useAppSelector(selectIsPlaying);
  const asrcPlayer = useAppSelector(selectAsrcPlayer);
  const handleVolumeChange = (
    event: Event,
    newValue: number | Array<number>
  ) => {
    if (asrcPlayer.audioContext) {
      asrcPlayer.gainNode?.gain.setValueAtTime(
        10 ** ((newValue as number) / 20),
        asrcPlayer.audioContext.currentTime
      );
      setValue(newValue as number);
    }
  };

  return (
    <>
      {isPlaying && (
        <Box>
          <Stack spacing={2} direction="row" sx={{ mb: 1 }} alignItems="center">
            <VolumeDown />
            <Slider
              aria-label="Volume"
              min={-30} // decibel
              max={30} // decibel
              value={value}
              onChange={handleVolumeChange}
            />
            <VolumeUp />
          </Stack>
        </Box>
      )}
    </>
  );
};
