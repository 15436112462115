import { FormControl, FormGroup, FormLabel, Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React, { memo } from 'react';
import { CartModifierGroup } from '../../../utils/cart';
import { checkItemInTree, isItem86edToday } from '../../../utils/menu';
import { ModGroupLabel } from '../components/ModGroupLabel';
import MultipleModChoice from '../components/MultipleModChoice';
import RecursiveModGroup from '../components/RecursiveModGroup';
import { sortAndFilterMenuItems } from '../util/helper';
import { IModGroup } from './modGroup.props';
import { hasModGroupChildren } from './modGroup.utils';

const useStyles = makeStyles(({ spacing }: Theme) => ({
  modGroup: {
    margin: `0 !important`,
    '& legend': {
      width: '100%',
    },
    width: '100%',
  },
  multipleSelectionModGroupClass: {
    marginBottom: (hasChildren) => (hasChildren ? spacing(1.25) : 0),
  },
}));

const MultipleSelectionModGroup = (props: IModGroup) => {
  const { modGroup, onChange, cartItem, pathToItem } = props;

  //sort and filter the modifiers before rendering
  const menuItems = sortAndFilterMenuItems(modGroup.menuItems);

  const currentItem = checkItemInTree({
    cartItem,
    pathToItem,
  }) as CartModifierGroup;
  let selectedModNumber = 0;
  if (currentItem) {
    const selectedMod = currentItem?.selectedItems || [];
    selectedModNumber = Object.values(selectedMod).length;
  }
  const key = [cartItem.id, modGroup.id].join('_');
  const classes = useStyles(hasModGroupChildren(props));

  return (
    <div key={key} className={classes.multipleSelectionModGroupClass}>
      <FormControl component="fieldset" className={classes.modGroup}>
        <FormLabel component="legend">
          <ModGroupLabel
            modGroup={modGroup}
            selectedModNumber={selectedModNumber}
          />
        </FormLabel>
        <FormGroup row>
          {menuItems.map((child) => {
            if (child.available || isItem86edToday(child)) {
              return (
                <MultipleModChoice
                  key={key + child.id}
                  menuItem={child}
                  onChange={onChange(modGroup)}
                  pathToItem={[pathToItem, child.id].join('__')}
                  cartItem={cartItem}
                />
              );
            }
            return null;
          })}
        </FormGroup>
      </FormControl>
      <RecursiveModGroup {...props} />
    </div>
  );
};

export default memo(MultipleSelectionModGroup);
