import { IAIState } from './ai.props';
import { AIStatusColors, DEFAULT_STATUS_MESSAGE } from './ai.constants';

const initialState: IAIState = {
  isAIAutoMode: true,
  isAIEscalation: false,
  aiStatus: {
    status: AIStatusColors.green,
    message: DEFAULT_STATUS_MESSAGE,
  },
};

export { initialState };
