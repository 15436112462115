import { Dispatch } from '@reduxjs/toolkit';
import { EventTypes } from '../constants/event';
import {
  InfoTransmissionMessage,
  messagingActions,
} from '../reducers/messagingSlice';
import { ParsedMenuItem } from './menu';

export const getModifierAndModGroupFromHypotheses = (
  topLevelMenuItem: ParsedMenuItem,
  modId: string,
  modGroupId: string
) => {
  const modGroup = Object.values(topLevelMenuItem.modifierGroups).find(
    (modGroup) => modGroup.id === modGroupId
  );
  const modifier = modGroup
    ? (Object.values(modGroup.menuItems).find(
        (item) => item.id === modId
      ) as ParsedMenuItem)
    : undefined;

  return { modGroup, modifier };
};

export const getModifierAndModGroupFromHypothesesByName = (
  topLevelMenuItem: ParsedMenuItem,
  modifierName: string,
  modGroupId: string
) => {
  const modGroup = Object.values(topLevelMenuItem.modifierGroups).find(
    (modGroup) => modGroup.id === modGroupId
  );
  const modifier = modGroup
    ? (Object.values(modGroup.menuItems).find(
        (item) => item.name === modifierName
      ) as ParsedMenuItem)
    : undefined;

  return { modGroup, modifier };
};

export const sendAIInfo = (metadata: any, dispatch: Dispatch) => {
  const payload: Partial<InfoTransmissionMessage> = {
    data: { metadata, type: EventTypes.hypothesis, message: '' },
  };
  dispatch(messagingActions.sendInfo(payload as InfoTransmissionMessage));
};
