import React, { MouseEvent, useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import ReportProblemRoundedIcon from '@mui/icons-material/ReportProblemRounded';
import { CircularProgress, Typography, Button } from '@mui/material';
import { Box } from '@mui/system';
import Popover from '@mui/material/Popover';
import VisualIndicator, { IVisualIndicator } from './VisualIndicator';
import Colors from '../../utils/color';
import { CheckStatus } from '../../redux/features/healthStatusCheck/healthStatusCheck.constant';
import { useAppSelector, useAppDispatch } from '../../app/hooks';
import { selectHealthStatusCheckState } from '../../redux/features/healthStatusCheck/healthStatusCheck.selector';
import {
  checkHealthStatus,
  reportLogsToPagerDuty,
  resetHealthStatusState,
} from '../../redux/features/healthStatusCheck/healthStatusCheck.slice';
import { selectActiveRestaurantCode } from '../../selectors/restaurant';
import theme from '../../utils/theme';
import { ISystemsStatus } from '../../redux/features/healthStatusCheck/healthStatusCheck.props';
import { RESET_HEALTH_STATUS_ONCLOSE_DELAY } from '../../constants';
import { selectIsPosmonDisconnected } from '../../selectors/order';

const useStyles = makeStyles({
  container: {
    padding: `${theme.spacing(3)}`,
  },
  bodyContainer: {
    margin: `${theme.spacing(2)} 0`,
  },
  lineItemsWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  btnContainer: {
    marginTop: `${theme.spacing(2)}`,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    flexDirection: 'column',
  },
  btn: {
    width: '100%',
    margin: `${theme.spacing(1)} 0 !important`,
  },
  boldFont: {
    fontWeight: 'bold !important',
  },
  italicFontStyle: {
    fontStyle: 'italic !important',
    fontSize: '0.625rem !important',
  },
});

enum SendReportStatus {
  notAplicable = 'notAplicable',
  sending = 'sending',
  sent = 'sent',
  failed = 'failed',
}

const HealthStatusCheck = () => {
  const {
    container,
    bodyContainer,
    lineItemsWrapper,
    btnContainer,
    btn,
    boldFont,
    italicFontStyle,
  } = useStyles();
  const dispatch = useAppDispatch();
  const { isHealthy, checkStatus, healthStatusCheckContent, generatedTime } =
    useAppSelector(selectHealthStatusCheckState);
  const restaurantCode = useAppSelector(selectActiveRestaurantCode);
  const {
    body: healthStatusCheckContentBody,
    heading: healthStatusCheckContentHeading,
  } = healthStatusCheckContent;
  const isPosmonDisconnected = useAppSelector(selectIsPosmonDisconnected);

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const [sendReportStatus, setSendReportStatus] = useState(
    SendReportStatus.notAplicable
  );

  useEffect(() => {
    if (isPosmonDisconnected) dispatch(checkHealthStatus());
  }, [isPosmonDisconnected]);

  const onHealthStatusCheckClick = (event: MouseEvent<HTMLButtonElement>) => {
    if (checkStatus === CheckStatus.completed && !isHealthy) {
      setAnchorEl(event.currentTarget);
    } else if (!checkStatus) {
      dispatch(checkHealthStatus());
    }
  };

  const visualIndicatorProps = ((): IVisualIndicator => {
    let visualIndicatorProps: IVisualIndicator = {
      onClick: onHealthStatusCheckClick,
      id: 'health-status-check',
      IconComponent: ErrorOutlineIcon,
    };
    if (!restaurantCode) {
      return { ...visualIndicatorProps };
    } else if (checkStatus === CheckStatus.completed && !isHealthy) {
      return {
        ...visualIndicatorProps,
        IconComponent: ReportProblemRoundedIcon,
        customizedColor: Colors.texasRose,
      };
    } else if (checkStatus === CheckStatus.completed && isHealthy) {
      return {
        ...visualIndicatorProps,
        IconComponent: CheckCircleRoundedIcon,
        customizedColor: Colors.brightGreen,
      };
    } else if (checkStatus === CheckStatus.pending) {
      return {
        ...visualIndicatorProps,
        IconComponent: CircularProgress,
        customizedColor: Colors.white,
      };
    }
    return { ...visualIndicatorProps, customizedColor: Colors.white };
  })();

  const open = Boolean(anchorEl);
  const id = open ? 'health-status-check-popover' : undefined;

  const reportStatusButtonLabel: { [key in SendReportStatus]: string } = {
    [SendReportStatus.notAplicable]: 'Report on Pager Duty',
    [SendReportStatus.sending]: 'Reporting...',
    [SendReportStatus.failed]: 'Failed, try again',
    [SendReportStatus.sent]: 'Report submitted',
  };

  const handleClose = () => {
    setAnchorEl(null);
    setTimeout(() => {
      dispatch(resetHealthStatusState());
      setSendReportStatus(SendReportStatus.notAplicable);
    }, RESET_HEALTH_STATUS_ONCLOSE_DELAY); // Reset the state with a delay for UI purpose
  };

  const onReportClick = async () => {
    try {
      setSendReportStatus(SendReportStatus.sending);
      await dispatch(
        reportLogsToPagerDuty({ restaurantCode: restaurantCode || '' })
      );
      setSendReportStatus(SendReportStatus.sent);
    } catch (error) {
      setSendReportStatus(SendReportStatus.failed);
    }
  };

  const onCopyClick = () => {
    const text = [
      healthStatusCheckContentHeading,
      ...(isHealthy
        ? [healthStatusCheckContentBody]
        : formSystemCheckContentBody(
            healthStatusCheckContentBody as ISystemsStatus[]
          )),
      generatedTime,
    ].join('\n');

    navigator.clipboard.writeText(text);
  };

  const formSystemCheckContentBody = (systemsStatus: ISystemsStatus[]) =>
    systemsStatus.map(({ name, status }) => `${name} ${status}`);

  return (
    <>
      <VisualIndicator {...visualIndicatorProps} />
      <Popover
        id={id}
        open={open}
        onClose={handleClose}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: -260,
        }}
      >
        <div className={container}>
          <Typography variant="body2" className={boldFont} color="primary">
            {healthStatusCheckContentHeading}
          </Typography>
          <div className={bodyContainer}>
            {isHealthy ? (
              <Typography variant="body2" gutterBottom>
                {healthStatusCheckContentBody}
              </Typography>
            ) : (
              <>
                {Array.isArray(healthStatusCheckContentBody) &&
                  (healthStatusCheckContentBody as ISystemsStatus[])?.map(
                    ({ name, status }) => (
                      <div key={name} className={lineItemsWrapper}>
                        <Typography variant="body2" gutterBottom>
                          {name}
                        </Typography>
                        <Typography
                          variant="body2"
                          color="error.dark"
                          className={boldFont}
                          gutterBottom
                        >
                          {status}
                        </Typography>
                      </div>
                    )
                  )}
              </>
            )}
          </div>
          <Typography variant="body2" className={italicFontStyle}>
            {generatedTime}
          </Typography>
          <Box className={btnContainer}>
            {!isHealthy && (
              <Button
                className={btn}
                disabled={[
                  SendReportStatus.sending,
                  SendReportStatus.sent,
                ].includes(sendReportStatus)}
                variant="contained"
                onClick={onReportClick}
                color="secondary"
              >
                {reportStatusButtonLabel[sendReportStatus]}
              </Button>
            )}
            <Button
              className={btn}
              variant="contained"
              onClick={onCopyClick}
              color="secondary"
            >
              Copy To Clipboard
            </Button>
            <Button
              className={btn}
              variant="outlined"
              onClick={handleClose}
              color="primary"
            >
              Close
            </Button>
          </Box>
        </div>
      </Popover>
    </>
  );
};

export default HealthStatusCheck;
