import { formatMenuResponse, ParsedCategory } from './menu';

export const READ_REP_MAP: Record<string, string> = {
  SML: 'Small',
  MED: 'Medium',
  LGE: 'Large',
  ADD: 'Add',
  NO: 'No',
  LITE: 'Lite',
  EASY: 'Easy',
  EXTRA: 'Extra',
  DOGGY: 'Doggy',
  DECLINED: '',
};

export type ModSymbolCodeNameMappingType = Record<
  string,
  {
    name: string;
    code: string;
    speak?: (name: string) => string;
  }
>;

export const ExpandName = (name: string) => {
  return name
    .replace(/[Bb]urr\b/g, 'Burrito')
    .replace(/\b[Cc]kn\b/g, 'Chicken')
    .replace(/SQT/g, 'Stuffed Quesadilla Taco')
    .replace(/[Ss]nk/g, 'Snack')
    .replace(/[Cc]hz/g, 'Cheese')
    .replace(/[Aa]sa\b/g, 'Asada')
    .replace(/[Bb]yd\b/g, 'Beyond')
    .replace(/[Gg]uac\b/g, 'Guacamole')
    .replace(/EggChz/g, 'Egg & Cheese')
    .replace(/Egg&Chz/g, 'Egg & Cheese')
    .replace(/Chz/g, 'Egg & Cheese')
    .replace(/\b[Bb]n\b/g, 'Bean')
    .replace(/\b[Bb]ac\b/g, 'Bacon')
    .replace(/\b[Qq]uesa\b/g, 'Quesadilla');
};

export const generateModSymbolMapping = (category: ParsedCategory, menuRes: ReturnType<typeof formatMenuResponse>): ModSymbolCodeNameMappingType[] => {
  const modSymbolMapping: ModSymbolCodeNameMappingType = {};
  const codeNameMapping: ModSymbolCodeNameMappingType = {};
  const { menuItems, posSettings } = menuRes;
  category.menuItems.forEach((menuItemId) => {
    const { posProperties, name } = menuItems[menuItemId];
    const { modsymbol, modcode }: Record<string, string> = posProperties.reduce((acc, id) => {
      acc[posSettings[id]?.key] = posSettings[id]?.value;
      return acc;
    }, {} as Record<string, string>);
    if (modsymbol) {
      modSymbolMapping[modsymbol] = {
        code: modcode,
        name,
      };
      codeNameMapping[modcode] = {
        code: modsymbol,
        name,
      };
      if (modsymbol === '>>') {
        codeNameMapping[modcode].speak = (name: string) => `${name} on the Side.`;
      }
    }
  });
  return [modSymbolMapping, codeNameMapping];
};
