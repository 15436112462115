import { Theme, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React, { FC, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useAppSelector } from '../../app/hooks';
import useTaskRouterMenuHandlers from '../../hooks/taskRouterHandlers/useTaskRouterMenuHandlers.hooks';
import {
  messagingActions,
  sendNetworkCallLogs,
} from '../../reducers/messagingSlice';
import {
  resetSelectedRestaurant,
  selectRestaurant,
} from '../../reducers/restaurantSlice';
import {
  forceLogOutUserSession,
  updateUserSession,
} from '../../reducers/userSlice';
import {
  selectConfig,
  selectFeatureFlagTaskRouter,
} from '../../redux/features/config/config.selector';
import {
  startLoading,
  stopLoading,
} from '../../redux/features/loading/loading.slice';
import {
  selectCurrentTask,
  selectCurrentTaskRestaurantCode,
  selectCurrentTaskRestaurantName,
} from '../../redux/features/taskRouter/taskRouter.selector';
import { selectRestaurantsById } from '../../selectors/restaurant';
import { SOURCE } from '../../utils/constants';
import { getAuthToken } from '../../utils/local-storage';
import logger from '../../utils/logger';
import { PerfTimer } from '../../utils/timer';
import ActiveSessionConfirmDialog from '../Restaurant/ActiveSessionConfirmDialog';
import RestaurantPicker from '../RestaurantPicker';

const useStyles = makeStyles<Theme>(({ spacing }) => ({
  name: {
    fontWeight: '500 !important',
    fontSize: '0.875rem !important',
    lineHeight: '1.75 !important',
    textTransform: 'uppercase',
    minWidth: spacing(8),
    padding: `${spacing(0.75)} ${spacing(2)}`,
  },
}));

const RestaurantsContainer: FC = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const isPlaying = useAppSelector((state) => state.messages.isPlaying);
  const [showConfirmModal, setShowConfirmModal] = useState<boolean>(false);
  const [dialogData, setDialogData] = useState<{
    restaurantCode: string;
    primaryRestaurantCode: string | null;
    data: [any];
  }>();
  const taskRouterFeatureFlag = useSelector(selectFeatureFlagTaskRouter);
  const currentTaskId = useAppSelector(selectCurrentTask);
  const {
    taskRouterMenuLoadFailureCallback,
    taskRouterMenuLoadSuccessCallback,
  } = useTaskRouterMenuHandlers();
  const currentTaskRestaurantCode = useAppSelector(
    selectCurrentTaskRestaurantCode
  );
  const currentTaskRestaurantName = useAppSelector(
    selectCurrentTaskRestaurantName
  );
  const restaurantsById = useAppSelector(selectRestaurantsById);
  const { FORCE_LOGOUT_API } = useAppSelector(selectConfig);

  const targetRestaurant = useMemo(() => {
    if (currentTaskRestaurantCode) {
      if (Object.keys(restaurantsById).length) {
        return Object.values(restaurantsById)?.filter(
          (restaurant) =>
            restaurant.restaurantCode === currentTaskRestaurantCode
        )?.[0];
      }
      return {
        restaurantCode: currentTaskRestaurantCode,
        restaurantName: currentTaskRestaurantName,
        primaryRestaurantCode: '',
      };
    }
  }, [restaurantsById, currentTaskRestaurantCode, currentTaskRestaurantName]);

  const selectUpdatedRestaurant = ({
    restaurantCode,
    primaryRestaurantCode,
    startLoadingIndicator,
    navigatedViaTaskRouter,
  }: {
    restaurantCode: string;
    primaryRestaurantCode: string;
    startLoadingIndicator: boolean;
    navigatedViaTaskRouter?: boolean;
  }) => {
    if (startLoadingIndicator) {
      dispatch(startLoading({}));
    }
    dispatch(updateUserSession(restaurantCode));
    logger.debug({
      restaurantCode,
      message: 'In selectUpdatedRestaurant: Before calling selectRestaurant',
    });
    dispatch(
      selectRestaurant({
        restaurantCode: restaurantCode,
        primaryRestaurantCode: primaryRestaurantCode,
        navigatedViaTaskRouter,
        taskRouterMenuLoadFailureCallback,
        taskRouterMenuLoadSuccessCallback,
      })
    );

    // Reset state of cars
    if (!taskRouterFeatureFlag) {
      dispatch(messagingActions.resetCarState());
    }

    if (!isPlaying) {
      dispatch(messagingActions.setIsPlaying(true));
    }
  };

  const onConfirm = (restaurantCode: string, primaryRestaurantCode: string) => {
    logger.debug({
      restaurantCode,
      message: 'In onConfirm: Before calling selectUpdatedRestaurant',
    });
    selectUpdatedRestaurant({
      restaurantCode,
      primaryRestaurantCode,
      startLoadingIndicator: true,
    });
    setShowConfirmModal(false);
  };

  const fetchActiveSession = (restaurantCode: string) => {
    const authToken = getAuthToken();
    if (!authToken) {
      return;
    }
    const requestTimer = new PerfTimer();
    try {
      const result = fetch(
        `${FORCE_LOGOUT_API}?source_module=${SOURCE}&restaurant_code=${restaurantCode}&active=1&force_reload=0`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: authToken,
          },
        }
      );

      dispatch(
        sendNetworkCallLogs({
          url: FORCE_LOGOUT_API,
          duration: requestTimer.stop(),
          message: 'api request succeeded',
          via: 'fetch',
        })
      );

      return result;
    } catch (error) {
      logger.error({
        restaurantCode,
        message: 'Failed while fetching active session',
        error,
      });

      dispatch(
        sendNetworkCallLogs({
          url: FORCE_LOGOUT_API,
          duration: requestTimer.stop(),
          message: 'api request failed',
          via: 'fetch',
        })
      );
    }
  };

  const handleRestaurantSelect = (
    restaurantCode: string,
    primaryRestaurantCode: any
  ) => {
    if (taskRouterFeatureFlag && currentTaskId) {
      logger.debug({
        restaurantCode,
        message:
          'In handleRestaurantSelect: Before calling selectUpdatedRestaurant',
        isTR: true,
      });
      selectUpdatedRestaurant({
        restaurantCode,
        primaryRestaurantCode,
        startLoadingIndicator: false,
        navigatedViaTaskRouter: true,
      });
      return;
    }
    dispatch(resetSelectedRestaurant());
    dispatch(startLoading({}));
    fetchActiveSession(restaurantCode)?.then(
      (response) => {
        if (response.status !== 200) {
          logger.debug({
            restaurantCode,
            message:
              'In handleRestaurantSelect: Before calling selectUpdatedRestaurant inside fetchActiveSession',
            isTR: false,
          });
          selectUpdatedRestaurant({
            restaurantCode,
            primaryRestaurantCode,
            startLoadingIndicator: false,
          });
        } else {
          dispatch(stopLoading());
          response.json().then((data) => {
            setDialogData({ restaurantCode, primaryRestaurantCode, data });
            setShowConfirmModal(true);
          });
        }
      },
      () => {
        dispatch(stopLoading());
      }
    );
  };

  useEffect(() => {
    if (targetRestaurant) {
      logger.debug({
        restaurantCode: targetRestaurant.restaurantCode,
        message:
          'In RestaurantsContainer: useEffect before calling handleRestaurantSelect',
      });
      handleRestaurantSelect(
        targetRestaurant.restaurantCode,
        targetRestaurant.primaryRestaurantCode
      );
    }
  }, [targetRestaurant]);

  return (
    <div id="restaurants-container">
      {dialogData && (
        <ActiveSessionConfirmDialog
          content={dialogData}
          open={showConfirmModal}
          onForceLogOut={(selectedSessions: any[]) => {
            if (dialogData.data) {
              dispatch(
                forceLogOutUserSession({
                  restaurantCode: dialogData.restaurantCode,
                  activeSessions: selectedSessions,
                })
              );
            }
            onConfirm(
              dialogData.restaurantCode,
              dialogData.primaryRestaurantCode as string
            );
          }}
          onConfirm={() => {
            onConfirm(
              dialogData.restaurantCode,
              dialogData.primaryRestaurantCode as string
            );
          }}
          onClose={() => setShowConfirmModal(false)}
        />
      )}
      {targetRestaurant ? (
        <Typography className={classes.name}>
          {targetRestaurant?.restaurantName}
        </Typography>
      ) : (
        <RestaurantPicker handleRestaurantSelect={handleRestaurantSelect} />
      )}
    </div>
  );
};

export default RestaurantsContainer;
