import { Scalars } from '../generated-interfaces/graphql';

export enum RolePermissions {
  PrestoAdmin = 'PRESTO_ADMIN',
  PrestoCustomerSupport = 'PRESTO_CUSTOMER_SUPPORT',
  RestaurantManager = 'RESTAURANT_MANAGER',
  RestaurantStaff = 'RESTAURANT_STAFF',
  Limited = 'LIMITED',
  Hitl = 'HITL',
}

export type UserRestaurantGroupRoleInput = {
  groupId: Scalars['Float'];
  permission: RolePermissions;
};
