interface IAlert {
  message: string;
  type: 'info' | 'success' | 'error';
}

/*
 * Ghost car use cases
 * (a) car_enter and car_exit happened quickly
 * (b) car_enter happened and no items were added to cart and car_exit happened
 * */
const GHOST_CAR: IAlert = {
  message:
    'Phew, the last order was invalid - Car exited too quickly/Ghost Car!',
  type: 'info',
};

/*
 * Successful order use cases
 * (a) Non AI restaurant: Agent added items and Finish order was clicked. Kill switch was clicked to exit HITL screen
 * (b) Non AI restaurant: Agent added items and “Finish order” was clicked. car_exit happened
 * (c) AI restaurant: AI processed order end-to-end and  car_exit happened
 * (d) AI restaurant: AI escalation happened & agent took over order and added/modified items and Finish order and car_exit (or) Kill switch was clicked
 * */
const SUCCESSFUL_ORDER: IAlert = {
  message: 'Hurray! You successfully processed the last order.',
  type: 'success',
};

/*
 * Unsuccessful order use cases
 * (a) Agent added items and Kill switch was clicked to exit HITL screen
 * (b) Agent added items and Kill switch was clicked to exit HITL screen
 * (c) Intervention (Automatic staff / Agent requesting staff) happened
 * */
const UNSUCCESSFUL_ORDER: IAlert = {
  message: 'The last order was NOT processed successfully.',
  type: 'error',
};

/*
 * Agent inactivity use cases
 * (a) Non AI restaurant: Agent did not move mouse (or) type in OmniBar (or) click on a button within 10 seconds of assigning a task.
 * (b) AI restaurant: After AI escalation, agent did not move mouse (or) type in OmniBar (or) click on a button within 10 seconds of assigning a task.
 * */
const AGENT_INACTIVITY: IAlert = {
  message: 'Oh no, logging you out for inactivity.',
  type: 'error',
};

const AUTO_NAVIGATION_ALERT = {
  GHOST_CAR,
  SUCCESSFUL_ORDER,
  UNSUCCESSFUL_ORDER,
  AGENT_INACTIVITY,
};

export default AUTO_NAVIGATION_ALERT;
