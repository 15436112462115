import { GROUP_ID_MAPPING_DEFAULT_RESTAURANT } from './constants';

const inMemoryGroupIdMapping = () => {
  let groupIdMappingList: Record<string, Record<string, any>> = {};

  const getGroupIdMapping = (restaurtantCode: string) =>
    groupIdMappingList[restaurtantCode] ||
    groupIdMappingList[GROUP_ID_MAPPING_DEFAULT_RESTAURANT] ||
    {};

  const setGroupIdMappingList = (
    restaurantCode: string,
    updatedGroupMappping: Record<string, any>
  ) => {
    groupIdMappingList[restaurantCode || GROUP_ID_MAPPING_DEFAULT_RESTAURANT] =
      updatedGroupMappping;
  };

  const getGroupIdMappingList = () => groupIdMappingList || {};

  return {
    getGroupIdMapping,
    setGroupIdMappingList,
    getGroupIdMappingList,
  };
};

export default inMemoryGroupIdMapping();
