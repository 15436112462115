import { Mail, Message } from '@mui/icons-material';
import { Grid, Theme, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { createRef, FC, RefObject, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { v4 as uuidv4, v5 as uuidv5 } from 'uuid';
import { useAppDispatch, useAppSelector } from '../app/hooks';
import { ErrorMessage } from '../components/ErrorMessage';
import { Image } from '../components/Layout/Image';
import { LoginForm, LoginFormData } from '../components/Login/LoginForm';
import prestoLogo from '../images/presto_logo_white.png';
import { login } from '../reducers/userSlice';
import { selectFeatureFlagTaskRouter } from '../redux/features/config/config.selector';
import { selectLoginFormErrors } from '../selectors/error';
import { selectUserProfile } from '../selectors/user';
import { saveTaskRouterInfoInLS } from '../utils/local-storage';

const useStyles = makeStyles<Theme>(({ spacing, palette }) => ({
  background: {
    width: '100vw',
    height: '100vh',
    backgroundColor: palette.primary.main,
    position: 'absolute',
    zIndex: -999,
  },
  prestoLogo: {
    marginBottom: spacing(4),
    '@media (max-height: 600px)': {
      marginBottom: 0,
    },
  },
  gridContainer: {
    height: '100vh',
    backgroundColor: palette.primary.main,
    minHeight: '480px',
    paddingBottom: '100px',
    '@media (max-height: 600px)': {
      paddingBottom: 0,
    },
  },
  mainContainer: {
    flex: 1,
  },
  loginForm: {
    margin: spacing(2),
    '& .MuiTextField-root': {
      marginBottom: spacing(2),
    },
  },
  loginFormInputs: {
    color: '#fff !important',
    borderBottom: '2px solid white',
    backgroundColor: '#18394e !important',
  },
  loginFormLabel: {
    color: '#fff !important',
    '&.Mui-focused': {
      color: '#fff !important',
    },
    '& .MuiInputLabel-asterisk': {
      display: 'none',
    },
  },
  contactContainer: {
    marginTop: spacing(2),
  },
  contact: {
    display: 'flex',
    alignItems: 'center',
    padding: spacing(1, 0),
    '& a': {
      textDecoration: 'none',
      color: '#2f9bf3',
    },
  },
}));

const LoginPage: FC = () => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const userProfile = useAppSelector(selectUserProfile);
  const formErrors = useAppSelector(selectLoginFormErrors);
  const isTaskRouterOn = useAppSelector(selectFeatureFlagTaskRouter);
  const location = useLocation();
  const navigate = useNavigate();
  const passwordRefInput: RefObject<HTMLInputElement> = createRef();

  const onSubmit = (formData: LoginFormData) => {
    dispatch(login(formData));
    passwordRefInput.current?.blur();
  };

  useEffect(() => {
    if (userProfile) {
      if (isTaskRouterOn) {
        saveTaskRouterInfoInLS({
          seqNumber: 0,
          agentId: uuidv5(
            userProfile.username,
            uuidv5('presto.com', uuidv5.URL)
          ),
          sessionId: uuidv4(),
          isLoginAck: false,
        });
      }
      navigate(location.state?.from || '/', { replace: true });
    }
  }, [userProfile]);

  return (
    <div data-testid="login-page">
      <div className={classes.background} />
      <Grid
        className={classes.gridContainer}
        container
        direction="column"
        alignItems="center"
      >
        <Grid
          container
          className={classes.mainContainer}
          direction="column"
          alignItems="center"
          justifyContent="space-around"
        >
          <Grid item>
            <Image
              className={classes.prestoLogo}
              url={prestoLogo}
              alt="Presto Logo"
            />
          </Grid>
          <Grid item>
            <div className={classes.loginForm}>
              {formErrors && formErrors.errorMessage ? (
                <ErrorMessage message={'Invalid Username or Password'} />
              ) : null}
              <LoginForm
                onSubmit={onSubmit}
                inputClassName={classes.loginFormInputs}
                inputLabelClassName={classes.loginFormLabel}
                formErrors={formErrors}
                passwordInputRef={passwordRefInput}
              />
            </div>
          </Grid>
          <Grid item />
        </Grid>
        <Grid item className={classes.contactContainer}>
          <Typography className={classes.contact}>
            <Mail color="secondary" style={{ marginRight: '5px' }} />
            <a href="mailto:contactless.support@presto.com">
              contactless.support@presto.com
            </a>
          </Typography>
          <Typography className={classes.contact}>
            <Message color="secondary" style={{ marginRight: '5px' }} />
            <a href="tel: +14155489347">+1 (415) 548 - 9347</a>
          </Typography>
        </Grid>
      </Grid>
    </div>
  );
};

export default LoginPage;
