import React, { FC, useEffect, useMemo, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { initialAuthCheck } from '../../reducers/userSlice';
import { selectDidAttemptAuthCheck } from '../../selectors/user';
import AuthContext from './';

const AuthProvider: FC = ({ children }) => {
  //Logic related to authentication needs to be moved here
  const [token, setToken] = useState(null);
  const dispatch = useAppDispatch();
  const didAttemptAuthCheck = useAppSelector(selectDidAttemptAuthCheck);

  const handleLogin = async () => {};
  const handleLogout = () => {};

  const value = useMemo(
    () => ({
      token,
      onLogin: handleLogin,
      onLogout: handleLogout,
    }),
    [token]
  );

  useEffect(() => {
    dispatch(initialAuthCheck(true));
  }, []);

  if (!didAttemptAuthCheck) {
    return <></>;
  }

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export default AuthProvider;
