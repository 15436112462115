import { useEffect, useRef } from 'react';
import { useAppDispatch } from '../../app/hooks';
import { setWSConnectionMessage } from '../../redux/features/taskRouter/taskRouter.slice';

const useTaskRouterWSActions = () => {
  const dispatch = useAppDispatch();
  const didUnmount = useRef(false);

  useEffect(() => {
    return () => {
      didUnmount.current = true;
    };
  }, []);

  const onError = () => {
    const errorMessage =
      'There was an error connecting to websocket - Retrying...';
    //@ts-ignore
    window.newrelic?.noticeError(errorMessage);
    dispatch(setWSConnectionMessage(errorMessage));
  };

  const shouldReconnect = (closeEvent: any) => {
    const isClosedManually = closeEvent.code === 1005;
    return isClosedManually ? false : didUnmount.current === false;
  };

  const onReconnectStop = (numAttempted: number) => {
    const errorMessage = `Unable to establish websocket connection. Maximum reconnect attempts(${numAttempted}) reached`;
    //@ts-ignore
    window.newrelic?.noticeError(errorMessage);
    dispatch(setWSConnectionMessage(errorMessage));
  };

  return {
    onError,
    shouldReconnect,
    onReconnectStop,
  };
};

export default useTaskRouterWSActions;
