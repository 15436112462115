import React from 'react';
import noMenuItemImage from '../../images/no_menu_image.jpg';

interface ImageProps {
  url?: string;
  alt?: string;
  width?: number;
  height?: number;
  className?: string;
  isMenuItemImage?: boolean;
}

export function Image(props: ImageProps) {
  let imageUrl = props.url;
  if (!imageUrl) {
    if (!props.isMenuItemImage) {
      return null;
    }
    imageUrl = noMenuItemImage;
  }
  return <img className={props.className} src={imageUrl} alt={props.alt} width={props.width} height={props.height} />;
}
