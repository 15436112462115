import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useAppSelector } from '../app/hooks';
import useLogout from '../components/Login/useLogout';
import { FORCE_RELOAD_CHECK_TIME } from '../constants';
import { updateHeartBeatSession } from '../reducers/userSlice';
import { selectActiveRestaurantCode } from '../selectors/restaurant';
import { SOURCE } from '../utils/constants';
import { getAuthToken, getSessionID } from '../utils/local-storage';
import logger from '../utils/logger';
import { sendNetworkCallLogs } from '../reducers/messagingSlice';
import { PerfTimer } from '../utils/timer';
import { selectConfig } from '../redux/features/config/config.selector';

const USER_LOGGED_OUT_MESSAGE =
  'Another user logged in to same restaurant, You are logged out by another user';

const useApp = () => {
  const { logout } = useLogout();
  const activeRestaurantCode = useAppSelector(selectActiveRestaurantCode);
  const dispatch = useDispatch();
  const { FORCE_LOGOUT_API } = useAppSelector(selectConfig);

  const checkActiveRestaurantSession = async () => {
    const requestTimer = new PerfTimer();
    try {
      const authToken = getAuthToken() + '';
      const sessionid = getSessionID();
      const result = await (
        await fetch(
          `${FORCE_LOGOUT_API}?source_module=${SOURCE}&restaurant_code=${activeRestaurantCode}&active=1&user_session_id=${sessionid}`,
          {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              Authorization: authToken,
            },
          }
        )
      ).json();

      dispatch(
        sendNetworkCallLogs({
          url: FORCE_LOGOUT_API,
          duration: requestTimer.stop(),
          message: 'api request succeeded',
          via: 'fetch',
        })
      );

      if (Array.isArray(result) && result[0]?.force_reload)
        logout(USER_LOGGED_OUT_MESSAGE);
      else if (activeRestaurantCode) {
        dispatch(updateHeartBeatSession(activeRestaurantCode));
      }
    } catch (error) {
      dispatch(
        sendNetworkCallLogs({
          url: FORCE_LOGOUT_API,
          duration: requestTimer.stop(),
          message: 'api request failed',
          via: 'fetch',
        })
      );

      logger.error({
        restaurantCode: activeRestaurantCode,
        message: 'Failed while fetching active restaurant session details',
        error,
      });
    }
  };

  //Poll if there is any other active restaurant session
  useEffect(() => {
    let activeRestaurantTimer = setInterval(() => {
      if (activeRestaurantCode) checkActiveRestaurantSession();
    }, FORCE_RELOAD_CHECK_TIME);
    return () => {
      clearInterval(activeRestaurantTimer);
    };
  }, [activeRestaurantCode]);
};

export default useApp;
