import React, { useEffect } from 'react';
import { useAppDispatch } from '../app/hooks';
import Body from '../components/Body';
import { setNavigationViaTaskRouter } from '../redux/features/taskRouter/taskRouter.slice';

const LandingPage = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(setNavigationViaTaskRouter(false));
  }, []);

  return (
    <div data-testid="landing-page">
      <Body />
    </div>
  );
};

export default LandingPage;
