import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../app/hooks';
import {
  IHITLSessionEndTransmissionMessage,
  IHITLSessionStartTransmissionMessage,
  resetHypothesisFrame,
  sendHITLSessionEnd,
  sendHITLSessionStart,
} from '../reducers/messagingSlice';
import { orderActions } from '../reducers/orderSlice';
import { getAIControlPanelStatus } from '../reducers/restaurantSlice';
import { resetAI } from '../redux/features/ai/ai.slice';
import {
  SessionStartReasons,
  SessionStatus,
} from '../redux/features/sessionBoundary/sessionBoundary.constants';
import {
  selectBufferedSessionIds,
  selectSessionEndReason,
  selectSessionStartReason,
  selectSessionStatus,
} from '../redux/features/sessionBoundary/sessionBoundary.selectors';
import {
  setSessionStart,
  updateBufferedSessionIds,
} from '../redux/features/sessionBoundary/sessionBoundary.slice';
import { selectCurrentTask } from '../redux/features/taskRouter/taskRouter.selector';
import { selectedRestaurantCodeSelector } from '../utils/restaurants';
import useTaskRouterHandlers from './taskRouterHandlers/taskRouterHandlers.hooks';

const useSessionStatus = () => {
  const dispatch = useAppDispatch();
  const sessionStatus = useAppSelector(selectSessionStatus);
  const sessionStartReason = useAppSelector(selectSessionStartReason);
  const sessionEndReason = useAppSelector(selectSessionEndReason);
  const bufferedSessionIds = useAppSelector(selectBufferedSessionIds);
  const currentTaskId = useAppSelector(selectCurrentTask);
  const { endTask } = useTaskRouterHandlers();
  const restaurantCode = useAppSelector(selectedRestaurantCodeSelector);

  useEffect(() => {
    if (sessionStatus === SessionStatus.end) {
      const payload: Partial<IHITLSessionEndTransmissionMessage> = {
        data: { reason: sessionEndReason || '' },
      };
      dispatch(sendHITLSessionEnd(payload as any));
      endTask(); // End task when a session ends
      if (bufferedSessionIds.length) {
        // A car enter before the current session ends so starting the new session right after the current session ends
        dispatch(
          setSessionStart({
            sessionId: bufferedSessionIds[0],
            sessionStartReason: SessionStartReasons.carEnter,
          })
        );
        dispatch(updateBufferedSessionIds(bufferedSessionIds.slice(1)));
      }
    } else if (sessionStatus === SessionStatus.start) {
      const payload: Partial<IHITLSessionStartTransmissionMessage> = {
        data: { reason: sessionStartReason || '' },
      };
      dispatch(sendHITLSessionStart(payload as any));
      dispatch(resetHypothesisFrame());
      dispatch(resetAI());
      if (restaurantCode && !currentTaskId) {
        // Get updated AI on/off status when a new HITL session starts, but don't execute when the task is received via task-router
        dispatch(getAIControlPanelStatus({ restaurantCode }));
      }
      dispatch(orderActions.setCurrentSession('')); //Resetting the hitlSessionID in orderSlice whenever new session starts
    }
  }, [sessionStatus]);
};

export default useSessionStatus;
