// Audio Stuff

const HITL_SAMPLE_RATE = 16000;

type NodeChain = Array<
  | AudioWorkletNode
  | GainNode
  | DynamicsCompressorNode
  | BiquadFilterNode
  | AudioDestinationNode
>;

export class AsrcPcmPlayer {
  audioContext?: AudioContext;
  asrcNode?: AudioWorkletNode;
  gainNode?: GainNode;
  postGainNode?: GainNode;
  compressorNode?: DynamicsCompressorNode;
  gainParam?: AudioParam;
  iirNode?: BiquadFilterNode;
  iirLpf?: BiquadFilterNode;
  activeChain: NodeChain = [];
  chainStart = this.chainDefault; // function pointer for init

  async startAudio() {
    if (!this.audioContext) {
      var audioContext = new AudioContext({ sampleRate: HITL_SAMPLE_RATE });

      console.log(audioContext.sampleRate);

      // register the noise generator AudioWorklet module
      await audioContext.audioWorklet.addModule(
        'audio_worklet/asrc-processor.js'
      );

      // create a new AudioWorkletNode
      this.asrcNode = new AudioWorkletNode(audioContext, 'asrc-processor');
      this.asrcNode.port.onmessage = (data) => {
        console.log(data);
      };
      this.gainParam = this.asrcNode.parameters.get('hissgain');

      // The `gainNode` is controlled from a slider in HITL
      this.gainNode = audioContext.createGain();

      // The `postGainNode` for now does nothing.
      // SpeechSynthesis TTS found in utils/alerts, with vol=0.2

      this.postGainNode = audioContext.createGain();
      this.postGainNode.gain.value = 10 ** (0 / 20); // 0dB - do nothing

      // Fast attack in case customer TTS comes through, but long
      // release to keep the level roughly constant.
      this.compressorNode = new DynamicsCompressorNode(audioContext, {
        threshold: -20, // dB
        knee: 10, // dB
        ratio: 5, // dB/dB
        attack: 0.04, // seconds
        release: 0.8, // seconds
      });

      // https://developer.mozilla.org/en-US/docs/Web/API/BiquadFilterNode
      this.iirNode = new BiquadFilterNode(audioContext, {
        type: 'highpass',
        frequency: 200,
      });

      this.iirLpf = new BiquadFilterNode(audioContext, {
        type: 'lowpass',
        frequency: 1000,
      });

      this.audioContext = audioContext;

      // connect the AudioWorkletNode to the audio context destination
      this.chainStart(); // may be set outside
    } // if
  }

  chainDefault() {
    const chain: NodeChain = [];
    [
      this.asrcNode,
      this.gainNode,
      this.compressorNode,
      this.postGainNode,
      this.audioContext?.destination,
    ].forEach((node) => {
      if (node) chain.push(node);
    });

    this.connectChain(chain);
  }

  chainHpf() {
    const chain: NodeChain = [];
    [
      this.asrcNode,
      this.iirNode,
      this.gainNode,
      this.compressorNode,
      this.postGainNode,
      this.audioContext?.destination,
    ].forEach((node) => {
      if (node) chain.push(node);
    });
    this.connectChain(chain);
  }

  chainLpf() {
    const chain: NodeChain = [];
    [
      this.asrcNode,
      this.iirLpf,
      this.gainNode,
      this.compressorNode,
      this.postGainNode,
      this.audioContext?.destination,
    ].forEach((node) => {
      if (node) chain.push(node);
    });
    this.connectChain(chain);
  }

  connectChain(chain: NodeChain) {
    if (!this.audioContext) {
      return;
    }

    // disconnect old chain
    const active = this.activeChain;
    let node = active[0];
    for (let i = 1; i < active.length; i++) {
      const nextNode = active[i];
      node.disconnect(nextNode);
      node = nextNode;
    }

    // connect the new chain
    node = chain[0];
    let next_node;

    for (let i = 1; i < chain.length; i++) {
      next_node = chain[i];
      node.connect(next_node);
      node = next_node;
    }

    this.activeChain = chain;
  }

  async aclose() {
    await this.audioContext?.close();
    this.audioContext = undefined;
  }

  pushBase64(payload: string) {
    // decode and dump into the player
    const decoded = atob(payload);
    var src_chars = Uint8Array.from(decoded, (c) => c.charCodeAt(0));

    // transfer into the array buffer
    var ab = new ArrayBuffer(decoded.length);
    var chars = new Uint8Array(ab);

    for (var i = 0; i < decoded.length; i += 1) {
      chars[i] = src_chars[i];
    }
    const audioData = new Int16Array(ab);

    if (this.audioContext) {
      this.asrcNode?.port.postMessage(audioData);
    }
  }

  pushBinary(audioData: any) {
    // payload is a binary from the websocket
    if (this.audioContext) {
      this.asrcNode?.port.postMessage(audioData);
    }
  }
}
