import { Button, Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React, { useEffect } from 'react';
import { useAlert } from 'react-alert';
import { useDispatch, useSelector } from 'react-redux';
import { useAppSelector } from '../../app/hooks';
import { RootState } from '../../app/store';
import useTaskRouterHandlers from '../../hooks/taskRouterHandlers/taskRouterHandlers.hooks';
import { cartActions } from '../../reducers/cartSlice';
import { resetDialogSelectedItem } from '../../reducers/dialogSlice';
import {
  EndSessionTransmissionMessage,
  RestaurantStaffInterventionStatus,
  messagingActions,
  resetHypothesisFrame,
} from '../../reducers/messagingSlice';
import { orderActions, sendOrder } from '../../reducers/orderSlice';
import { selectIsAIAutoMode } from '../../redux/features/ai/ai.selector';
import {
  selectFinalOrderSubmitted,
  selectSessionId,
} from '../../redux/features/sessionBoundary/sessionBoundary.selectors';
import {
  resetOrderMessageOrderId,
  setFinalOrderSubmitted,
} from '../../redux/features/sessionBoundary/sessionBoundary.slice';
import { TaskStatuses } from '../../redux/features/taskRouter/taskRouter.constants';
import { selectValidCartItems } from '../../selectors/cart';
import {
  selectIsStaffIntervention,
  selectRestaurantStaffIntervention,
} from '../../selectors/message';
import { OrderActionArgs } from '../../types';
import { activeCartSelector, isValidCartSelector } from '../../utils/cart';
import Colors from '../../utils/color';
import { END_SESSION_REASON } from '../../utils/constants';
import logger from '../../utils/logger';
import { selectConfig } from '../../redux/features/config/config.selector';

const useStyles = makeStyles((theme: Theme) => ({
  buttonWrapper: {
    height: '64px',
    padding: theme.spacing(1),
    display: 'flex',
    gap: theme.spacing(1),
  },
  actionButton: {
    flex: 1,
    fontWeight: 'bold !important',
  },
  addBlackBackgroundColor: {
    backgroundColor: Colors.black,
  },
  customizeDisabledButton: {
    backgroundColor: `${Colors.alto2} !important`,
  },
  progress: {
    position: 'absolute',
    bottom: 0,
  },
}));

export const OrderActions = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const cartActive = useSelector(activeCartSelector);
  const cartValid = useSelector(isValidCartSelector);
  const isAIAutoMode = useSelector(selectIsAIAutoMode);

  const validCartItems = useSelector(selectValidCartItems);
  const restaurantCode = useSelector(
    (state: RootState) => state.restaurant.selectedRestaurantCode
  );
  const orderSessionId = useSelector(
    (state: RootState) => state.order.currentSessionId
  );
  const messageSessionId = useSelector(
    (state: RootState) => state.messages.currentSessionId
  );
  const isStaffIntervention = useSelector(selectIsStaffIntervention);
  const sbSessionId = useAppSelector(selectSessionId);
  const restaurantStaffIntervention = useSelector(
    selectRestaurantStaffIntervention
  );
  const isRestaurantStaffInterventionClicked =
    restaurantStaffIntervention !== RestaurantStaffInterventionStatus.initial;
  const { sendTaskStatus, isCurrentTaskFromTaskRouter } =
    useTaskRouterHandlers();

  const isFinalOrderSubmitted = useSelector(selectFinalOrderSubmitted);

  const resetStatesOnOrderAction = () => {
    dispatch(resetDialogSelectedItem());
    dispatch(cartActions.clearCart());
    dispatch(resetHypothesisFrame());
    dispatch(resetOrderMessageOrderId()); // Resetting AI order ID, as currently AI supports only single order
  };

  useEffect(() => {
    if (isRestaurantStaffInterventionClicked) {
      //When the "Restaurant Staff" button is clicked, a modal to be shown to select the reason
      cancelOrder({});
    }
  }, [isRestaurantStaffInterventionClicked]);

  useEffect(() => {
    if (isFinalOrderSubmitted) {
      //The order is submitted by AI. Not call finishOrder() because some of the actions are not needed
      logger.debug({
        message: 'AI submitted final order - clearing values in redux state',
        sessionId: sbSessionId,
      });
      resetStatesOnOrderAction();
      sendTaskStatus(TaskStatuses.completed, { isFinishOrder: true });
      if (isAIAutoMode) dispatch(orderActions.finishOrderResetValues()); // Resetting order state after AI is active and Finish order button is clicked
    }
  }, [isFinalOrderSubmitted]);

  useEffect(() => {
    //When 'staff_intervention' event (or) info event with message 'StaffIntervention' is received via websocket
    if (isStaffIntervention) {
      if (isCurrentTaskFromTaskRouter) {
        //When "Restaurant Staff" button is clicked, StaffIntervention event is also being received.
        //To make sure that cancel order is not called twice
        if (!isRestaurantStaffInterventionClicked) {
          cancelOrder({
            isStaffIntervention: true,
          });
        }
      } else {
        cancelOrder({});
      }
    }
  }, [isStaffIntervention]);

  const sendEndSession = (reason: string) => {
    if (restaurantCode) {
      const payload: Partial<EndSessionTransmissionMessage> = {
        data: {
          restaurant_code: restaurantCode,
          session_id: orderSessionId || messageSessionId,
        }, // still end the session if there is no order session yet
        metadata: reason,
      };
      dispatch(messagingActions.sendEndSession(payload as any));
    }
  };

  const cancelOrder = (args: OrderActionArgs) => {
    logger.debug({
      message: 'Cancel order called with these args',
      sessionId: sbSessionId,
      moreInfo: JSON.stringify(args),
    });
    sendEndSession(END_SESSION_REASON.CANCEL_ORDER);
    resetStatesOnOrderAction();
    dispatch(orderActions.finishOrderResetValues());
    dispatch(messagingActions.clearMessages());
    dispatch(orderActions.resetCurrentTransactionId());
    sendTaskStatus(TaskStatuses.canceled, args);
    /*
     * @TODO The reason for comment out
     *   setFinalOrderSubmitted(true) in cancelOrder function is it reset task_canceled to task_completed
     *   as a result informatory alert triggered as successful order.
     * */
    // dispatch(setFinalOrderSubmitted(true));
    dispatch(resetOrderMessageOrderId());
  };

  const finishOrder = () => {
    logger.debug({
      message: 'Clicked on finish order',
      sessionId: sbSessionId,
    });
    sendEndSession(END_SESSION_REASON.FINISH_ORDER);
    dispatch(sendOrder({ validCartItems, isFinal: true }));
    resetStatesOnOrderAction();
    dispatch(messagingActions.clearMessages());
    sendTaskStatus(TaskStatuses.completed, { isFinishOrder: true });
    dispatch(setFinalOrderSubmitted(true));
    if (isAIAutoMode) dispatch(orderActions.finishOrderResetValues()); // Resetting order state after AI is active and Finish order button is clicked
  };

  const handleCancelOrderClick = () => {
    logger.debug({
      message: 'Clicked on cancel order',
      sessionId: sbSessionId,
    });
    cancelOrder({ isCancelOrder: true });
  };

  return (
    <div
      id="orderActions"
      className={`${classes.buttonWrapper} ${
        cartActive ? classes.addBlackBackgroundColor : ''
      }`}
    >
      <Button
        onClick={handleCancelOrderClick}
        variant="contained"
        disabled={!restaurantCode}
        color="error"
        className={classes.actionButton}
      >
        Cancel Order
      </Button>
      <Button
        onClick={finishOrder}
        variant="contained"
        color="success"
        disabled={!cartActive || !cartValid}
        className={`${classes.actionButton} ${
          !cartValid ? classes.customizeDisabledButton : ''
        }`}
      >
        Finish Order
      </Button>
    </div>
  );
};
