export function memoize<Arg extends Array<any>, Ret extends any>(
  fn: (...arg: Arg) => Ret,
  hashFn: (...a: Arg) => string
) {
  const cache = new Map<string, Ret>();

  return (...args: Arg) => {
    const key = hashFn(...args);
    if (cache.has(key)) return cache.get(key) as Ret;
    const value = fn(...args);
    cache.set(key, value);
    return value;
  };
}
