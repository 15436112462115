import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../../../app/store';

const selectLoading = (state: RootState) => state.loading;

const selectIsLoading = createSelector(
  selectLoading,
  (loading) => loading.isLoading
);
const selectIsFetchingTaskStatus = createSelector(
  selectLoading,
  (loading) => loading.isFetchingTaskStatus
);

export { selectIsFetchingTaskStatus, selectIsLoading };
