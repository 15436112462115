import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../app/store';
import { FORM_NAMES } from '../utils/form';

const selectError = (state: RootState) => state.error;
const selectFormErrors = createSelector(
  selectError,
  (error) => error.formErrors
);
const selectLoginFormErrors = createSelector(
  selectFormErrors,
  (formErrors) => formErrors[FORM_NAMES.loginForm]
);

export { selectError, selectFormErrors, selectLoginFormErrors };
