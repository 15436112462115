import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { MENU_CACHE_LIMIT } from './cachedMenu.constants';
import {
  IAddMenuData,
  ICachedMenuSlice,
  IUpdateMenuData,
} from './cachedMenu.props';

const initialState: ICachedMenuSlice = {
  menusById: {},
  menusOrder: [],
};

const cachedMenuSlice = createSlice({
  name: 'cachedMenu',
  initialState,
  reducers: {
    resetCachedMenuData: () => {
      return { ...initialState };
    },
    addMenuData: (state, { payload }: PayloadAction<IAddMenuData>) => {
      const { restaurantId, menuVersion, menuData } = payload;
      if (state.menusOrder.length === MENU_CACHE_LIMIT) {
        // Remove the oldest cached menu restaurant data
        const oldestDetails = state.menusOrder.shift();
        if (oldestDetails?.restaurantId)
          delete state.menusById[oldestDetails.restaurantId];
      }
      state.menusOrder.push({ restaurantId, menuVersion });
      state.menusById[restaurantId] = menuData;
    },
    updateMenuData: (state, { payload }: PayloadAction<IUpdateMenuData>) => {
      const { restaurantId, menuVersion, menuData, index } = payload;
      state.menusOrder[index] = { restaurantId, menuVersion };
      state.menusById[restaurantId] = menuData;
    },
  },
});

export const { resetCachedMenuData, addMenuData, updateMenuData } =
  cachedMenuSlice.actions;
export default cachedMenuSlice.reducer;
