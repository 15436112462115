import { useAppSelector } from '../../app/hooks';
import { selectIsAIAutoMode } from '../../redux/features/ai/ai.selector';
import { selectIsAIActive } from '../../selectors/message';

const useAIMessaging = () => {
  const isAIActive = useAppSelector(selectIsAIActive);
  const isAIAutoMode = useAppSelector(selectIsAIAutoMode);
  return {
    isAIActive,
    isAIAutoMode,
  };
};

export default useAIMessaging;
