import React, { useEffect, useState } from 'react';
import { useAppSelector } from '../../app/hooks';
import { makeStyles } from '@mui/styles';
import { SessionSource } from '../../reducers/messagingSlice';

const useStyles = makeStyles(() => ({
  container: {
    minWidth: '48px',
    margin: '4px !important',
    textAlign: 'center',
  },
}));

export const Timer = () => {
  const classes = useStyles();
  const sessionId = useAppSelector((state) => state.messages.currentSessionId);
  const sessionSource = useAppSelector((state) => state.messages.sessionSource);
  const [duration, setDuration] = useState(0);

  const getFormatedDuration = () => {
    const minutes = Math.floor(duration / 60);
    const seconds = duration - minutes * 60;
    const secondsToString =
      seconds > 9 ? String(seconds) : '0' + String(seconds);
    return String(minutes) + ':' + secondsToString;
  };

  useEffect(() => {
    // only reflect the duration of sessions from communicator
    if (sessionSource == SessionSource.communicator) {
      const startTime = new Date().getTime() / 1000; // Get start time in seconds
      const getDuration = () => {
        const currentTime = new Date().getTime() / 1000;
        const durationInSecond = Math.floor(currentTime - startTime);
        setDuration(durationInSecond);
      };
      const timer = setInterval(() => {
        getDuration();
      }, 1000);
      return () => {
        clearInterval(timer);
      };
    }
  }, [sessionId]);
  return <div className={classes.container}>{getFormatedDuration()}</div>;
};
