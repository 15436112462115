import { Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { KeyboardEvent, MouseEvent } from 'react';
import { getSessionID } from '../../utils/local-storage';
interface IContentData {
  restaurantCode: string;
  primaryRestaurantCode: string | null;
  data: any[] | null;
}

interface IProps {
  open: boolean;
  content: IContentData;
  onClose: Function;
  onConfirm: Function;
  onForceLogOut: Function;
}
const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});

export default function ActiveSessionConfirmDialog({ open, content, onClose, onConfirm, onForceLogOut }: IProps) {
  const classes = useStyles();

  const [selected, setSelected] = React.useState<readonly string[]>([]);
  const currentUserSession = getSessionID();
  const handleClose = (e: MouseEvent, reason: string) => {
    if (reason !== 'backdropClick') {
      onClose();
    }
  };

  const handleKeyDown = (e: KeyboardEvent) => {
    if (e.key === 'Enter' && onConfirm) onConfirm();
  };

  const handleClick = (event: React.MouseEvent<unknown>, name: string) => {
    if (name === currentUserSession) return;
    const selectedIndex = selected.indexOf(name);
    let newSelected: readonly string[] = [];
    // if not presnt add the selected item
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }

    setSelected(newSelected);
  };

  const isSelected = (name: string) => selected.indexOf(name) !== -1;

  const onSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelected = content.data?.filter((n) => n.user_session_id !== currentUserSession).map((n) => n.user_session_id) || [];
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const rowCount = content.data?.length || 0;

  const onClickforceLogOut = () => {
    const selectedSessions = content.data?.filter((n) => selected.indexOf(n.user_session_id) !== -1) || [];
    onForceLogOut(selectedSessions);
  };

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth onKeyDown={handleKeyDown}>
      {<DialogTitle> Active Users </DialogTitle>}
      <DialogContent>
        <TableContainer component={Paper}>
          <Table className={classes.table} size="small" aria-label="active user table">
            <TableHead>
              <TableRow>
                <TableCell align="right">
                  <Checkbox
                    color="primary"
                    indeterminate={selected.length > 0 && selected.length < rowCount}
                    checked={rowCount > 0 && selected.length === rowCount}
                    onChange={onSelectAllClick}
                    inputProps={{
                      'aria-label': 'select all',
                    }}
                  />
                </TableCell>
                {['User id', 'First Name', 'Last Name', 'Source Module'].map((heading) => (
                  <TableCell key={heading} align="right">
                    {heading}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {content.data?.map(({ unique_user_id, user_session_id, first_name, last_name, source_module }, index) => {
                const isItemSelected = isSelected(user_session_id);
                const labelId = `enhanced-table-checkbox-${index}`;
                return (
                  <TableRow key={user_session_id} onClick={(event) => handleClick(event, user_session_id)} aria-checked={isItemSelected} tabIndex={-1} selected={isItemSelected}>
                    <TableCell align="right">
                      <Checkbox
                        color="primary"
                        checked={isItemSelected}
                        inputProps={{
                          'aria-labelledby': labelId,
                        }}
                        disabled={user_session_id === currentUserSession}
                      />
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {unique_user_id}
                    </TableCell>
                    <TableCell align="right">{first_name}</TableCell>
                    <TableCell align="right">{last_name}</TableCell>
                    <TableCell align="right">{source_module}</TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </DialogContent>
      <DialogActions>
        {[
          { label: 'Force logout', onClick: onClickforceLogOut },
          { label: 'Continue', onClick: onConfirm },
          { label: 'Cancel', onClick: onClose },
        ].map(({ label, onClick }) => (
          <Button key={label} onClick={() => onClick()} variant="outlined" data-cy={`active-user-${label}`}>
            {label}
          </Button>
        ))}
      </DialogActions>
    </Dialog>
  );
}
